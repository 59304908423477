import React from "react";
import { Typography } from "../../components/ui/Typography";
import { Separator } from "../../components/ui/Separator";
import { Button } from "../../components/ui/Button";
import { Switch } from "../../components/ui/Switch";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../components/ui/Popover";
import { Check, ChevronsUpDown } from "lucide-react";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "../../components/ui/Command";
import { cn } from "../../utils/cn";
const frameworks = [
  {
    value: "next.js",
    label: "Next.js",
  },
  {
    value: "sveltekit",
    label: "SvelteKit",
  },
  {
    value: "nuxt.js",
    label: "Nuxt.js",
  },
  {
    value: "remix",
    label: "Remix",
  },
  {
    value: "astro",
    label: "Astro",
  },
];

interface MySettingsProps {
  popOverOpen: boolean;
  setPopOverOpen: (state: boolean) => void;
}
export default function MySettings({
  popOverOpen,
  setPopOverOpen,
}: MySettingsProps) {
  // const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState("");

  // const [timeZone, setTimeZone] = useState("");
  // const [timeZoneAbbr, setTimeZoneAbbr] = useState("");

  // useEffect(() => {
  //   const date = new Date();
  //   const timeZoneString = date
  //     .toLocaleTimeString("en-us", { timeZoneName: "short" })
  //     .split(" ")[2];
  //   setTimeZoneAbbr(timeZoneString);
  //   const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  //   setTimeZone(tz);
  // }, []);

  return (
    <div className="flex flex-col flex-grow w-full h-full">
      <div className="flex flex-col gap-2">
        <div className="flex flex-col space-y-4">
          <div className="flex items-center justify-between gap-5 mt-4">
            <div>
              <Typography component="p" variant="p2">
                Set timezone automatically using your location
              </Typography>
            </div>
            <Switch></Switch>
          </div>
          <div className="flex items-center justify-between gap-5">
            <div>
              <Typography component="p" variant="p2">
                Time Zone
              </Typography>
              <Typography
                component="p"
                className="text-[12px] font-normal text-contiyo-secondary-text"
              >
                Current time zone setting
              </Typography>
            </div>
            <Popover
              open={popOverOpen}
              onOpenChange={setPopOverOpen}
              modal={true}
            >
              <PopoverTrigger asChild>
                <Button
                  variant="outline"
                  role="combobox"
                  aria-expanded={popOverOpen}
                  className="w-[200px] justify-between h-8"
                >
                  {value
                    ? frameworks.find((framework) => framework.value === value)
                        ?.label
                    : "Select timezone..."}
                  <ChevronsUpDown className="w-4 h-4 ml-2 opacity-50 shrink-0" />
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-[200px] p-0">
                <Command>
                  <CommandInput
                    placeholder="Search timezone..."
                    className="h-8"
                  />
                  <CommandEmpty>No framework found.</CommandEmpty>
                  <CommandList>
                    <CommandGroup>
                      {frameworks.map((framework: any) => (
                        <CommandItem
                          key={framework.value}
                          value={framework.value}
                          onSelect={(currentValue: any) => {
                            setValue(
                              currentValue === value ? "" : currentValue
                            );
                            setPopOverOpen(false);
                          }}
                        >
                          <Check
                            className={cn(
                              "mr-2 h-4 w-4",
                              value === framework.value
                                ? "opacity-100"
                                : "opacity-0"
                            )}
                          />
                          {framework.label}
                        </CommandItem>
                      ))}
                    </CommandGroup>
                  </CommandList>
                </Command>
              </PopoverContent>
            </Popover>
          </div>
          <Separator />
          <div className="flex items-center justify-between gap-5">
            <div>
              <Typography component="p" variant="p2">
                Start week on Monday
              </Typography>
            </div>
            <Switch></Switch>
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-2"></div>
    </div>
  );
}
