import { VariantProps } from "class-variance-authority";
import { HTMLAttributes, useState } from "react";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../../components/ui/AlertDialog";
import { buttonVariants } from "../../components/ui/Button";
import { Loader2 } from "lucide-react";

interface AlertDialogProps extends HTMLAttributes<HTMLDivElement> {
  title?: string;
  description: React.ReactNode;
  contentArea?: React.ReactNode;
  actionString?: string;
  actionButtonVariant?: VariantProps<typeof buttonVariants>["variant"];
  onSubmit: any;
  onCancel?: () => void;
  icon?: React.ReactNode;
}

export default function AlertDialogComponent({
  title,
  description,
  contentArea,
  actionString,
  actionButtonVariant,
  onSubmit,
  onCancel,
  icon,
  children,
}: AlertDialogProps) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogTrigger asChild>{children}</AlertDialogTrigger>
      <AlertDialogContent className="max-w-sm overflow-auto" aria-modal>
        <AlertDialogHeader>
          {icon && (
            <div className="flex justify-center w-full">
              <div className="flex items-center justify-center rounded-full size-[72px] bg-destructive/10">
                <div className="flex items-center justify-center rounded-full size-14 bg-destructive/15 text-destructive">
                  {icon}
                </div>
              </div>
            </div>
          )}
          <AlertDialogTitle className="text-center">{title}</AlertDialogTitle>
          <AlertDialogDescription className="text-center">
            {description}
          </AlertDialogDescription>
        </AlertDialogHeader>
        {contentArea}
        <AlertDialogFooter>
          <AlertDialogCancel disabled={loading} onClick={onCancel}>
            Cancel
          </AlertDialogCancel>
          <AlertDialogAction
            onClick={async (event: any) => {
              setLoading(true);
              try {
                await onSubmit(event);
              } finally {
                setLoading(false);
              }
            }}
            variant={actionButtonVariant}
            disabled={loading}
            className=""
          >
            {loading ? (
              <Loader2 className="animate-spin size-4" />
            ) : (
              actionString || "Continue"
            )}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
