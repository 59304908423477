import React, { useState } from "react";
import {
  confirmPassword,
  oldPassword,
  passwordRequirementsField,
} from "../../auth/formFields";
import { z } from "zod";
import { useChangePassword } from "../api/accountService";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "sonner";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../components/ui/Form";
import { PasswordInput } from "../../../components/ui/PasswordInput";
import { PasswordRequirementsTooltip } from "../../auth/PasswordRequirementsTooltip";
import { Button } from "../../../components/ui/Button";

const changePasswordSchema = z
  .object({
    oldPassword: oldPassword,
    password: passwordRequirementsField,
    confirmPassword: confirmPassword,
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords do not match.",
    path: ["confirmPassword"],
  });

export default function ChangePasswordForm({ setSettingPreview }: any) {
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);
  const [changePassword] = useChangePassword();
  const formChangePassword = useForm<z.infer<typeof changePasswordSchema>>({
    resolver: zodResolver(changePasswordSchema),
    reValidateMode: "onChange",
    mode: "onSubmit",
    defaultValues: {
      oldPassword: "",
      password: "",
      confirmPassword: "",
    },
    criteriaMode: "all",
  });
  const {
    trigger,
    formState: { errors, submitCount },
  } = formChangePassword;
  const onChangePasswordSubmit = async (
    values: z.infer<typeof changePasswordSchema>
  ) => {
    try {
      await changePassword({
        variables: {
          input: {
            newPassword: values.password,
            previousPassword: values.oldPassword,
          },
        },
      });
      toast.success("Password changed successfully");
      setSettingPreview("my-account");
    } catch (error: any) {
      if (error.graphQLErrors) {
        error.graphQLErrors.forEach(async (err: any) => {
          switch (err.extensions?.code) {
            case "UNAUTHORIZED":
              formChangePassword.setError("oldPassword", {
                type: "manual",
                message: "Incorrect password.",
              });
              break;
            default:
              toast.error("Uh oh! Something went wrong", {
                description:
                  err.message || "There was a problem with your request",
              });
          }
        });
      }
    }
  };
  return (
    <>
      <Form {...formChangePassword}>
        <form
          onSubmit={formChangePassword.handleSubmit(onChangePasswordSubmit)}
          className="space-y-2"
        >
          {" "}
          <FormField
            control={formChangePassword.control}
            name="oldPassword"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-[12px] font-medium leading-tight">
                  Old password
                </FormLabel>
                <FormControl>
                  <PasswordInput
                    placeholder="∗∗∗∗∗∗∗∗"
                    {...field}
                    className={`h-[30px] text-[14px] placeholder:text-[14px] ${
                      errors.password ? "border-red-500" : ""
                    }`}
                  ></PasswordInput>
                </FormControl>{" "}
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={formChangePassword.control}
            name="password"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-[12px] font-medium leading-tight">
                  New password
                </FormLabel>
                <FormControl>
                  <PasswordInput
                    placeholder="∗∗∗∗∗∗∗∗"
                    {...field}
                    className={`h-[30px] text-[14px] placeholder:text-[14px] ${
                      errors.password ? "border-red-500" : ""
                    }`}
                    onFocus={() => setIsPasswordFocused(true)}
                    onBlur={() => setIsPasswordFocused(false)}
                    onChange={(e) => {
                      field.onChange(e);
                      //Fix for issue where changing password wouldn't trigger password dont match onChange
                      if (submitCount) {
                        trigger("confirmPassword");
                      }
                    }}
                  >
                    <PasswordRequirementsTooltip
                      isPasswordFocused={isPasswordFocused}
                      errors={errors}
                      submitCount={submitCount}
                      className="tablet:top-full tablet:py-4 tablet:mt-3 tablet:-translate-y-0 tablet:ml-0 tablet:left-0 password-triangle-down"
                    />
                  </PasswordInput>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={formChangePassword.control}
            name="confirmPassword"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-[12px] font-medium leading-tight">
                  Repeat new password
                </FormLabel>
                <FormControl>
                  <PasswordInput
                    placeholder="∗∗∗∗∗∗∗∗"
                    {...field}
                    className={`h-[30px] text-[14px] placeholder:text-[14px] ${
                      errors.confirmPassword ? "border-red-500" : ""
                    }`}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <div className="flex justify-center">
            <Button
              type="submit"
              variant="primary"
              className="inline-flex items-center w-full mt-2"
            >
              Change Password
            </Button>
          </div>
        </form>
      </Form>
    </>
  );
}
