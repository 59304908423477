import React from "react";
import { cva, VariantProps } from "class-variance-authority";
import { cn } from "../../utils/cn";

const typographyStyles = cva("text-contiyo-text", {
  variants: {
    variant: {
      h1: "text-[2em] sm:text-[2.25em] tablet:text-[2.5em] font-bold leading-tight",
      h2: "text-[1.5em] font-medium leading-tight",
      h3: "text-[1.125em] font-medium leading-tight",
      h4: "text-[0.9375em] font-medium leading-tight",
      p1: "text-[1em] font-medium leading-tight text-inherit",
      p2: "text-[0.875em] font-medium leading-tight text-inherit",
    },
  },
});

interface TypographyProps
  extends React.HTMLAttributes<HTMLElement>,
    VariantProps<typeof typographyStyles> {
  component?: "h1" | "h2" | "h3" | "h4" | "p";
}

export function Typography({
  component = "p",
  variant,
  className,
  children,
  ...props
}: TypographyProps) {
  const appliedVariant = variant || (component === "p" ? "p2" : component);
  const Component = component;
  return (
    <Component
      className={cn(typographyStyles({ variant: appliedVariant }), className)}
      {...props}
    >
      {children}
    </Component>
  );
}
