import React, { useCallback, useMemo, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Button } from "../../../components/ui/Button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../components/ui/Form";
import { Input } from "../../../components/ui/Input";
import { CircleCheck, CircleX, Loader2 } from "lucide-react";
import { emailField } from "../../auth/formFields";
import { useSendChangeEmailCode } from "../api/accountService";
import { useGetUserLazy } from "../../auth/api/authService";
import { debounce } from "lodash";
import { toast } from "sonner";

const changeEmailSchema = z.object({
  email: emailField,
});

export default function ChangeEmailForm({ setEmailCodeSent }: any) {
  const [isLoading, setIsLoading] = useState(false);
  const [sendChangeEmailCode] = useSendChangeEmailCode();
  const [isEmailFieldFocused, setIsEmailFieldFocused] = useState(false);
  const [emailChecking, setEmailChecking] = useState(false);
  const [getUser] = useGetUserLazy();
  const formChangeEmail = useForm<z.infer<typeof changeEmailSchema>>({
    resolver: zodResolver(changeEmailSchema),
    reValidateMode: "onChange",
    mode: "onSubmit",
    defaultValues: {
      email: "",
    },
    criteriaMode: "all",
  });
  const {
    trigger: emailTrigger,
    setError: setEmailError,
    clearErrors: clearEmailErrors,
    formState: { errors: emailErrors },
  } = formChangeEmail;
  const onChangeEmailSubmit = async (
    values: z.infer<typeof changeEmailSchema>
  ) => {
    setIsLoading(true);
    const isEmailValid = await checkEmail(values.email);
    if (!isEmailValid) {
      setIsLoading(false);
      return;
    }
    try {
      sendChangeEmailCode({ variables: { email: values.email } });
      setEmailCodeSent(true);
    } catch (error: any) {
      toast.error("Uh oh! Something went wrong", {
        description: error.message || "There was a problem with your request",
      });
    } finally {
      setIsLoading(false);
    }
  };
  const checkEmail = useCallback(
    async (email: string) => {
      try {
        const { data } = await getUser({ variables: { userId: email } });
        if (data) {
          setEmailError("email", {
            type: "manual",
            message: "Email is already taken",
          });
          return false;
        } else {
          clearEmailErrors("email");
          emailTrigger("email");
          return true;
        }
      } catch (error: any) {
        setEmailError("email", {
          type: "manual",
          message: "Error checking email",
        });
        return false;
      } finally {
        setEmailChecking(false);
      }
    },
    [getUser, clearEmailErrors, setEmailError, emailTrigger]
  );

  const debouncedEmailCheck = useMemo(
    () =>
      debounce(async (email: string) => {
        await checkEmail(email);
      }, 500),
    [checkEmail]
  );

  const handleEmailChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      debouncedEmailCheck(e.target.value);
    },
    [debouncedEmailCheck]
  );
  return (
    <Form {...formChangeEmail}>
      <form
        onSubmit={formChangeEmail.handleSubmit(onChangeEmailSubmit)}
        className="space-y-2"
      >
        <FormField
          control={formChangeEmail.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="text-[12px] font-medium leading-tight">
                New email address
              </FormLabel>
              <FormControl>
                <div className="relative">
                  <Input
                    placeholder="example@example.com"
                    className={`pr-10 h-[30px] text-[14px] placeholder:text-[14px] ${
                      emailErrors.email && "border-red-500"
                    }`}
                    {...field}
                    onChange={(e) => {
                      field.onChange(e);
                      handleEmailChange(e);
                      setEmailChecking(true);
                    }}
                    onFocus={() => setIsEmailFieldFocused(true)}
                    onBlur={() => setIsEmailFieldFocused(false)}
                  />
                  <Button
                    variant="ghost"
                    asChild
                    className={
                      "absolute top-0 right-0 h-full px-3 py-2 hover:bg-transparent border border-transparent focus-visible:border-contiyo-primary-purple focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-indigo-100"
                    }
                  >
                    <div>
                      {emailChecking ? (
                        <Loader2 className="w-[16px] h-[16px] animate-spin" />
                      ) : emailErrors.email ? (
                        <CircleX className="w-[16px] h-[16px] stroke-red-500" />
                      ) : (
                        formChangeEmail.getValues("email") !== "" && (
                          <CircleCheck
                            className={`w-[16px] h-[16px] ${
                              isEmailFieldFocused &&
                              "stroke-contiyo-primary-purple"
                            }`}
                          />
                        )
                      )}
                    </div>
                  </Button>
                </div>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="flex justify-center">
          <Button
            type="submit"
            variant="primary"
            className="inline-flex items-center w-full mt-2"
            onClick={() => {
              if (!formChangeEmail.formState.isValid) {
                checkEmail(formChangeEmail.getValues("email"));
              }
            }}
          >
            {!isLoading ? (
              "Send code"
            ) : (
              <Loader2 className="w-4 h-4 mr-2 animate-spin" />
            )}
          </Button>
        </div>
      </form>
    </Form>
  );
}
