import { useEffect } from "react";
import { TabTitle } from "../../utils/tabTitle";
import Layout, { LayoutBody } from "../layout/Layout";
import { useCalendarContext } from "../calendar/CalendarProvider";
import { getHours } from "date-fns";
import { Typography } from "../../components/ui/Typography";
import { useAuth } from "../auth/AuthProvider";
import { useWorkspace } from "../workspace/WorkspaceProvider";
import { Skeleton } from "../../components/ui/Skeleton";
// import { Button } from "../../components/ui/Button";

export default function Home() {
  const title = "Home";
  TabTitle(title);
  const { loggedUser, loggedUserDataLoading } = useAuth();
  const { currentDate, setCurrentDate } = useCalendarContext();
  const { activeWorkspaceLoading } = useWorkspace();
  useEffect(() => {
    setCurrentDate(new Date());
    // eslint-disable-next-line
  }, []);

  const greeting = () => {
    const hours = getHours(currentDate);
    if (hours < 12) {
      return "Good morning";
    } else if (hours < 18) {
      return "Good afternoon";
    } else {
      return "Good evening";
    }
  };

  return (
    <Layout tabTitle={title}>
      {!activeWorkspaceLoading && (
        <LayoutBody className="overflow-auto">
          <div className="flex flex-col gap-1">
            {loggedUserDataLoading ? (
              <>
                <div className="w-2/5 py-1 h-9">
                  <Skeleton className="w-full h-full"></Skeleton>
                </div>
                <Skeleton className="w-2/6 h-5"></Skeleton>
              </>
            ) : (
              <>
                <Typography component="h2" className="text-[24px]">
                  {greeting()}
                  {loggedUser && ", " + loggedUser?.name.split(" ")[0] + " 👋🏼"}
                </Typography>
                <Typography
                  component="p"
                  variant="p1"
                  className="text-contiyo-secondary-text"
                >
                  What are you working on today?
                </Typography>
              </>
            )}
          </div>
        </LayoutBody>
      )}
    </Layout>
  );
}
