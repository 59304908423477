import React, { SetStateAction, useEffect, useRef, useState } from "react";
import { Typography } from "../../components/ui/Typography";
import { Separator } from "../../components/ui/Separator";
import { Button } from "../../components/ui/Button";
import { Copy, Send } from "lucide-react";
import { cn } from "../../utils/cn";
import { Textarea } from "../../components/ui/TextArea";
import chatgptLogo from "../../assets/openai-logomark.svg";

interface ChatProps {
  activeAI: any;
  setActiveAI: React.Dispatch<SetStateAction<any>>;
}

export default function AIChat({ activeAI, setActiveAI }: ChatProps) {
  const [inputChatValue, setInputChatValue] = useState("");
  const textareaRefAI = useRef<HTMLTextAreaElement>(null);
  const handleInput = (e: any) => {
    setInputChatValue(e.target.value);
  };

  const adjustTextareaHeight = () => {
    if (textareaRefAI.current) {
      textareaRefAI.current.style.height = "auto";
      textareaRefAI.current.style.height = `${textareaRefAI.current.scrollHeight}px`;
    }
  };

  useEffect(() => {
    adjustTextareaHeight();
  }, [activeAI.placeholder, inputChatValue]);

  // Typewriter effect
  const [displayedText, setDisplayedText] = useState("");
  const [showCopy, setShowCopy] = useState(false);
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const typewriterEffect = (text: string, speed: number) => {
    let index = 0;
    setDisplayedText("");
    setShowCopy(false);

    const interval = setInterval(() => {
      if (index < text.length) {
        setDisplayedText((prev) => prev + text[index]);
        index++;

        if (scrollContainerRef.current) {
          scrollContainerRef.current.scrollTo({
            top: scrollContainerRef.current.scrollHeight,
            behavior: "smooth",
          });
        }
      } else {
        setShowCopy(true);
        clearInterval(interval);
      }
    }, speed);
  };

  return (
    <div className="flex flex-col border border-accent rounded-radius w-[350px] bg-white shrink-0">
      <div className="flex items-center gap-1 p-4">
        <span className="size-4 text-contiyo-primary-purple">
          {activeAI.icon}
        </span>
        <Typography className="font-normal">{activeAI.name}</Typography>
      </div>
      <Separator></Separator>
      <div className="flex flex-col flex-grow overflow-hidden">
        <div
          className="flex flex-col flex-grow gap-4 p-4 pr-8 overflow-auto"
          ref={scrollContainerRef}
        >
          {displayedText && (
            <div className="inline-flex gap-2 h-fit w-fit">
              <div className="p-1.5 border rounded-full h-fit border-accent shrink-0">
                <img
                  src={chatgptLogo}
                  alt="OpenAI logo"
                  className="size-5 shrink-0"
                ></img>
              </div>
              <div className="flex flex-col gap-2">
                <Typography className="pt-1 font-normal">
                  {displayedText}
                </Typography>
                {showCopy && (
                  <Button
                    className="flex h-auto gap-1 p-2 py-1.5 w-fit"
                    variant={"creatorNotActiveTab"}
                  >
                    <Copy className="size-4"></Copy>
                    <Typography>Copy</Typography>
                  </Button>
                )}
              </div>
            </div>
          )}
        </div>
        <div
          className={cn("relative flex flex-col gap-2 p-4 pt-0", {
            "pt-2": activeAI.id === "change-tone",
          })}
        >
          <div className="flex items-center gap-2 p-1.5 pl-4 rounded-2xl bg-accent">
            <div className="flex flex-grow">
              <Textarea
                id="input"
                placeholder={activeAI.placeholder}
                onChange={handleInput}
                value={inputChatValue}
                rows={1}
                ref={textareaRefAI}
                className="box-border w-full max-h-[80px] h-auto p-0 break-words border-none shadow-none resize-none chatTextArea placeholder:text-contiyo-secondary-text focus-visible:ring-0 focus-visible:border-none min-h-fit focus:ring-0 focus:ring-offset-0"
              ></Textarea>
            </div>

            <Button
              disabled={inputChatValue === ""}
              onClick={() => {
                typewriterEffect(inputChatValue, 20);
                setInputChatValue("");
              }}
              className="flex items-center self-end justify-center h-auto p-2 bg-white border-none rounded-full text-contiyo-text hover:text-contiyo-primary-purple group hover:bg-white"
            >
              <Send className="size-4"></Send>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
