import { zodResolver } from "@hookform/resolvers/zod";
import { Loader2 } from "lucide-react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Button } from "../../components/ui/Button";
import { Checkbox } from "../../components/ui/Checkbox";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
} from "../../components/ui/Form";
import { Typography } from "../../components/ui/Typography";
import {
  InstagramMediaType,
  LinkedInContentType,
  PostStatus,
  SocialMediaType,
} from "../../gql/graphql";
import { cn } from "../../utils/cn";
import { useCreateBlankPostForMedia } from "./createBlankPostForMedia";
import { MediaTypeIcon } from "./MediaTypeIcon";

type MediaFormProps = {
  activeTab: any;
  activePostData: any;
  post: any;
  type: SocialMediaType.Instagram | SocialMediaType.Linkedin;
  dataLoading: boolean;
  setIsDataLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

const formSchemaMap = {
  [SocialMediaType.Instagram]: z.object({
    [InstagramMediaType.Image]: z.boolean().optional(),
    [InstagramMediaType.Carousel]: z.boolean().optional(),
    [InstagramMediaType.Stories]: z.boolean().optional(),
    [InstagramMediaType.Reels]: z.boolean().optional(),
  }),
  [SocialMediaType.Linkedin]: z.object({
    [LinkedInContentType.Text]: z.boolean().optional(),
    [LinkedInContentType.Image]: z.boolean().optional(),
    [LinkedInContentType.Video]: z.boolean().optional(),
    [LinkedInContentType.Document]: z.boolean().optional(),
  }),
};

export default function MediaForm({
  activeTab,
  activePostData,
  post,
  type,
  dataLoading,
  setIsDataLoading,
}: MediaFormProps) {
  const { createBlankPostForMedia } =
    useCreateBlankPostForMedia(setIsDataLoading);
  const FormSchema = formSchemaMap[type];
  type FormFields = keyof z.infer<typeof FormSchema>;

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: Object.fromEntries(
      Object.keys(FormSchema.shape).map((key) => [key, false])
    ) as z.infer<typeof FormSchema>,
  });

  const onSubmit = async (data: z.infer<typeof FormSchema>) => {
    await createBlankPostForMedia({
      PK: post?.PK,
      title: post?.title,
      slug: post?.slug,
      workspace_id: post?.workspace_id,
      scheduled_at: 0,
      social_media_type: type,
      status: PostStatus.Draft,
      account_id: activeTab.SK.split("#")[1],
      media_type: data,
    });
  };

  const watchAllFields = form.watch();
  const isSubmitDisabled = !Object.values(watchAllFields).some(Boolean);

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="grid grid-cols-2 gap-3 mt-3"
      >
        {(Object.keys(FormSchema.shape) as FormFields[]).map((field) => (
          <FormField
            key={field}
            control={form.control}
            name={field}
            disabled={dataLoading}
            render={({ field: formField }) => (
              <FormItem
                className={cn(
                  "flex flex-row items-center justify-between p-4 space-x-3 space-y-0 border cursor-pointer rounded-radius min-h-20",
                  dataLoading &&
                    "opacity-50 pointer-events-none cursor-not-allowed"
                )}
                onClick={(e) => {
                  if (!dataLoading) {
                    const target = e.target as HTMLElement;
                    if (target.tagName !== "INPUT") {
                      formField.onChange(!formField.value);
                    }
                  }
                }}
              >
                <div className="flex items-center justify-center gap-2">
                  <MediaTypeIcon mediaType={field}></MediaTypeIcon>
                  <Typography variant="h4" className="font-bold capitalize">
                    {String(field).replace("_", " ").toLowerCase()}
                  </Typography>
                </div>
                <FormControl>
                  <Checkbox
                    checked={formField.value}
                    onCheckedChange={formField.onChange}
                    className="data-[state=checked]:text-contiyo-primary-purple data-[state=checked]:bg-contiyo-background-accent-purple data-[state=checked]:border-white"
                    onClick={(e) => e.stopPropagation()}
                  />
                </FormControl>
              </FormItem>
            )}
          />
        ))}
        <Button
          type="submit"
          variant="primary"
          className="col-span-2"
          disabled={isSubmitDisabled || dataLoading}
        >
          {dataLoading ? <Loader2 className="animate-spin size-4" /> : "Next"}
        </Button>
      </form>
    </Form>
  );
}
