import bgImage from "../../assets/auth-bg.png";
import leftArrow from "../../assets/icons/left-arrow-icon.svg";
import { Typography } from "../../components/ui/Typography";
import OpacityWrapper from "../../components/ui/OpacityWrapper";
import BrandLogo from "../../components/BrandLogo";
import { ReactNode } from "react";

interface FormLayoutProps extends React.PropsWithChildren {
  heading: string;
  subHeading: ReactNode;
  onBackClick?: () => void;
}

export default function FormLayout({
  heading,
  subHeading,
  onBackClick,
  children,
}: FormLayoutProps) {
  return (
    <div className="grid tablet:grid-cols-[488px_1fr] xl:grid-cols-[600px_1fr] 2xl:grid-cols-[750px_1fr]">
      <OpacityWrapper className="w-full min-h-screen p-[20px] md:p-[40px] flex justify-center">
        <div className="flex flex-col gap-[40px] 2xl:gap-[100px] items-center max-w-[488px] w-full">
          <div className="w-full">
            <BrandLogo />
          </div>
          <div className="flex flex-col gap-[24px] mobile:gap-[40px] w-full">
            {onBackClick && (
              <button className="w-[32px] h-[32px]" onClick={onBackClick}>
                <img src={leftArrow} alt="left arrow icon" />
              </button>
            )}
            <div className="flex flex-col gap-[16px]">
              <Typography component="h1">{heading}</Typography>
              <Typography component="p" className="text-[#555555]">
                {subHeading}
              </Typography>
            </div>
            {children}
          </div>
        </div>
      </OpacityWrapper>
      <div
        className="sticky top-0 hidden h-screen bg-center bg-no-repeat bg-cover tablet:block"
        style={{ backgroundImage: `url(${bgImage})` }}
      ></div>
    </div>
  );
}
