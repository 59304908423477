import { BetweenHorizonalEnd, LayoutTemplate } from "lucide-react";
import React, { SetStateAction, useState } from "react";
import { Button } from "../../components/ui/Button";
import { Typography } from "../../components/ui/Typography";
import { aiActions } from "./aiActions";

interface Sidemenu {
  activeAI: any;
  setActiveAI: React.Dispatch<SetStateAction<any>>;
}

export default function CreatorSidemenu({ activeAI, setActiveAI }: Sidemenu) {
  const [activeTab, setActiveTab] = useState(1);
  return (
    <>
      <div className="flex items-center justify-around bg-white">
        <Button
          className={`flex-1  text-center text-sm font-bold cursor-pointer border-b-[2px] border-b-accent rounded-none hover:bg-transparent py-4 h-auto ${
            activeTab === 1
              ? "text-contiyo-primary-purple border-b-contiyo-primary-purple hover:text-contiyo-primary-purple"
              : "text-contiyo-secondary-text hover:text-contiyo-secondary-text"
          }`}
          variant="ghost"
          onClick={() => setActiveTab(1)}
        >
          Actions
        </Button>
        <Button
          className={`flex-1  text-center text-sm font-bold cursor-pointer border-b-[2px] border-b-accent rounded-none hover:bg-transparent py-4 h-auto ${
            activeTab === 2
              ? "text-contiyo-primary-purple  border-b-contiyo-primary-purple hover:text-contiyo-primary-purple"
              : "text-contiyo-secondary-text hover:text-contiyo-secondary-text"
          }`}
          variant="ghost"
          onClick={() => setActiveTab(2)}
        >
          Preview
        </Button>
        <Button
          className={`flex-1 relative text-center text-sm font-bold disabled:opacity-100 cursor-pointer border-b-[2px] border-accent rounded-none hover:bg-transparent py-4 h-auto ${
            activeTab === 3
              ? "text-contiyo-primary-purple  border-b-contiyo-primary-purple hover:text-contiyo-primary-purple"
              : "text-contiyo-secondary-text hover:text-contiyo-secondary-text"
          }`}
          variant="ghost"
          onClick={() => setActiveTab(3)}
          disabled
        >
          <Typography className="text-[10px] leading-none font-bold absolute bottom-0 p-1 px-2 bg-contiyo-background-accent-purple text-contiyo-primary-purple left-1/2 rounded-radius -translate-x-1/2 translate-y-1/2">
            Soon
          </Typography>
          <span className="opacity-50">Organize</span>
        </Button>
      </div>

      {activeTab === 1 && (
        <div className="flex flex-col gap-3 p-2 py-4 overflow-auto">
          <div>
            <div className="flex mb-2">
              <span className="text-[12px] px-2 font-semibold text-contiyo-secondary-text">
                AI Actions
              </span>
            </div>
            <div className="grid grid-cols-2 gap-2">
              {aiActions.map((item: any, index: number) => (
                <Button
                  key={index}
                  variant={
                    activeAI?.id === item.id
                      ? "creatorActiveTab"
                      : "creatorNotActiveTab"
                  }
                  className="flex flex-col items-center h-auto gap-1 p-2 py-4 font-medium transition-all rounded-radius"
                  onClick={() => setActiveAI(item)}
                >
                  <span className="size-4 text-contiyo-primary-purple">
                    {item.icon}
                  </span>
                  <Typography>{item.name}</Typography>
                </Button>
              ))}
            </div>
          </div>
          <div>
            <div className="flex mb-2">
              <span className="text-[12px] px-2 font-semibold text-contiyo-secondary-text">
                Test
              </span>
            </div>
            <div className="grid grid-cols-2 gap-2">
              <Button
                variant="outline"
                className="flex flex-col items-center h-auto gap-1 p-2 py-4 font-medium transition-all rounded-radius"
              >
                <BetweenHorizonalEnd className="size-4 text-contiyo-primary-purple"></BetweenHorizonalEnd>
                <Typography>Close Offs</Typography>
              </Button>
              <Button
                variant="outline"
                className="flex flex-col items-center h-auto gap-1 p-2 py-4 font-medium transition-all rounded-radius"
              >
                <LayoutTemplate className="size-4 text-contiyo-primary-purple"></LayoutTemplate>
                <Typography>Templates</Typography>
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
