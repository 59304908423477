const getInitial = (name: string) => {
  if (name) {
    return name.charAt(0).toUpperCase();
  }
};

const getInitials = (name: string): string => {
  const nameArray = name.split(" ");
  const initials = nameArray
    .map((part) => part.charAt(0).toUpperCase())
    .join("");
  return initials.slice(0, 2);
};

export { getInitial, getInitials };
