import { zodResolver } from "@hookform/resolvers/zod";
import { ArrowLeft, Wand2, Zap, Loader2 } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { z } from "zod";
import { Button } from "../../components/ui/Button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogOverlay,
  DialogTitle,
} from "../../components/ui/Dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../components/ui/Form";
import { Input } from "../../components/ui/Input";
import { Textarea } from "../../components/ui/TextArea";
import { Typography } from "../../components/ui/Typography";
import { useWorkspace } from "../workspace/WorkspaceProvider";
import { useCreator } from "./CreatorContext";
import { useCreatePost } from "./api/creatorService";
import { PostStatus, SocialMediaType } from "../../gql/graphql";

export default function CreatePostModal() {
  const { activeWorkspace } = useWorkspace();
  const navigate = useNavigate();
  const { isCreatorModalOpen, setIsCreatorModalOpen } = useCreator();
  const [createPost] = useCreatePost();
  const [optionSelect, setOptionSelect] = useState<boolean>(true);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const formSchema = z.object({
    title: z.string().min(2, {
      message: "Title must be at least 2 characters.",
    }),
    description: z.string(),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      title: "",
      description: "",
    },
  });

  const onSubmit = async (
    values: z.infer<typeof formSchema>,
    goToCreator: boolean = false
  ) => {
    setIsSubmitting(true);
    try {
      const response = await createPost({
        variables: {
          input: {
            title: values.title,
            workspace_id: activeWorkspace?.PK?.split("#")[1],
            scheduled_at: 0,
            social_media_type: SocialMediaType.Boilerplate,
            status: PostStatus.Draft,
            boilerplate_data: { content: values.description },
          },
        },
      });
      if (response) {
        if (goToCreator) {
          navigate(
            `${activeWorkspace.slug}/creator/${
              response.data?.savePost?.PK.split("#")[1]
            }`
          );
        }
        setIsCreatorModalOpen(false);
      }
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const openCreator = async () => {
    setIsSubmitting(true);
    try {
      const response = await createPost({
        variables: {
          input: {
            title: "Untitled",
            workspace_id: activeWorkspace?.PK?.split("#")[1],
            scheduled_at: 0,
            social_media_type: SocialMediaType.Boilerplate,
            status: PostStatus.Draft,
            boilerplate_data: { content: "" },
          },
        },
      });
      if (response) {
        navigate(
          `${activeWorkspace.slug}/creator/${
            response.data?.savePost?.PK.split("#")[1]
          }`
        );
        setIsCreatorModalOpen(false);
      }
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Dialog open={isCreatorModalOpen} onOpenChange={setIsCreatorModalOpen}>
      <DialogOverlay></DialogOverlay>
      <DialogContent className="sm:max-w-[425px] p-5 gap-4">
        <DialogHeader className="flex flex-row items-center gap-2 space-y-0">
          {!optionSelect && (
            <Button
              variant="outline"
              className="items-center p-0 size-7"
              onClick={() => setOptionSelect(true)}
            >
              <ArrowLeft className="size-4" />
            </Button>
          )}
          <DialogTitle>{optionSelect ? "Select" : "Quick Post"}</DialogTitle>
          <DialogDescription className="hidden">
            Select type of post
          </DialogDescription>
        </DialogHeader>
        {optionSelect ? (
          <div className="flex flex-col flex-grow gap-2">
            <Button
              variant="outline"
              className="flex-col items-start justify-start w-full h-auto p-4"
              onClick={() => setOptionSelect(false)}
            >
              <div className="flex items-center gap-1.5">
                <Zap className="size-[18px]" />
                <Typography className="text-lg">Quick Post</Typography>
              </div>
              <Typography className="text-left text-wrap">
                This feature allows you to write a quick boilerplate post that
                you can later edit in the full editor.
              </Typography>
            </Button>
            <Button
              variant="primary2hover"
              className="flex-col items-start justify-start w-full h-auto p-4"
              onClick={openCreator}
              disabled={isSubmitting}
            >
              <div className="flex items-center gap-1.5">
                <Wand2 className="size-[18px]" />
                <Typography className="text-lg">Go to creator</Typography>
              </div>
              <Typography className="text-left text-wrap">
                Go and make real content by adding media, customizing your post,
                and more.
              </Typography>
              {isSubmitting && <Loader2 className="ml-2 animate-spin" />}
            </Button>
          </div>
        ) : (
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit((values) => onSubmit(values, true))}
              className="space-y-3"
            >
              <FormField
                control={form.control}
                name="title"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Title</FormLabel>
                    <FormControl>
                      <Input
                        placeholder="Your title..."
                        {...field}
                        className="p-2 text-[14px] border-accent shadow-sm placeholder:text-contiyo-secondary-text"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="description"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Boilerplate</FormLabel>
                    <FormControl>
                      <Textarea
                        placeholder="Start writing..."
                        {...field}
                        className="p-2 text-[14px] placeholder:text-contiyo-secondary-text"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className="flex justify-end gap-2">
                <Button
                  variant="outline"
                  type="button"
                  onClick={form.handleSubmit((values) =>
                    onSubmit(values, false)
                  )}
                  disabled={isSubmitting}
                >
                  Save
                  {isSubmitting && <Loader2 className="ml-2 animate-spin" />}
                </Button>
                <Button
                  variant="primary2hover"
                  type="submit"
                  className="font-medium"
                  disabled={isSubmitting}
                >
                  Go to creator
                  {isSubmitting && <Loader2 className="ml-2 animate-spin" />}
                </Button>
              </div>
            </form>
          </Form>
        )}
      </DialogContent>
    </Dialog>
  );
}
