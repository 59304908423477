const length_message = "At least 8 characters";
const number_message = "At least one number";
const special_char_message = "At least one special character";
const uppercase_message = "At least one uppercase letter";
const lowercase_message = "At least one lowercase letter";
const specialErrors = [
  length_message,
  number_message,
  special_char_message,
  uppercase_message,
  lowercase_message,
];
export {
  length_message,
  number_message,
  special_char_message,
  uppercase_message,
  lowercase_message,
  specialErrors,
};
