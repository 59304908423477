import { useQuery } from "@apollo/client";
import { createContext, ReactNode, useContext, useState } from "react";
import { useWorkspace } from "../workspace/WorkspaceProvider";
import { GET_SOCIAL_MEDIA_QUERY } from "./api/socialService";

interface SocialContextType {
  socialData: any;
  refetchSocialData: any;
  loadingSocial: any;
  showAddAccountDialog: any;
  setShowAccountDialog: any;
}

const SocialContext = createContext<SocialContextType | undefined>(undefined);

export const SocialProvider = ({ children }: { children: ReactNode }) => {
  const { activeWorkspace } = useWorkspace();
  const [showAddAccountDialog, setShowAccountDialog] = useState(false);
  const {
    data: socialData,
    refetch: refetchSocialData,
    loading: loadingSocial,
  } = useQuery(GET_SOCIAL_MEDIA_QUERY, {
    variables: { workspaceId: activeWorkspace?.PK?.split("#")[1] },
    skip: !activeWorkspace,
  });
  return (
    <SocialContext.Provider
      value={{
        socialData,
        refetchSocialData,
        loadingSocial,
        showAddAccountDialog,
        setShowAccountDialog,
      }}
    >
      {children}
    </SocialContext.Provider>
  );
};

export const useSocial = () => {
  const context = useContext(SocialContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within a AuthProvider");
  }
  return context;
};
