import { HtmlHTMLAttributes } from "react";
import { cn } from "../../utils/cn";
import {
  getProfileImageUrl,
  getSocialMediaImageUrl,
} from "../../utils/socialSwitcher";
import { WorkspaceSocialMedia } from "../../gql/graphql";

interface Avatar extends HtmlHTMLAttributes<HTMLElement> {
  profile: WorkspaceSocialMedia;
  avatarClassName?: string;
  socialClassName?: string;
}

export default function AvatarWithPicture({
  profile,
  avatarClassName,
  socialClassName,
  className,
}: Avatar) {
  return (
    <div className={cn("relative size-6 shrink-0 flex", className)}>
      <img
        src={getProfileImageUrl(profile)}
        alt={profile.username + " profile picture"}
        className={cn("rounded-full w-full h-full", avatarClassName)}
      ></img>
      <img
        src={getSocialMediaImageUrl(profile.social_media_type)}
        alt={profile.social_media_type + " logo"}
        className={cn(
          "absolute size-[14px] bottom-0 right-0 border border-white rounded-full translate-x-[20%] translate-y-[20%]",
          socialClassName
        )}
      ></img>
    </div>
  );
}
