import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useWorkspace } from "../workspace/WorkspaceProvider";
import logoRectangle from "../../assets/logo-rectangle.svg";
import { useGetActiveWorkspace } from "../workspace/api/workspaceService";

function RedirectToHome() {
  const navigate = useNavigate();
  const [getActiveWorkspace] = useGetActiveWorkspace();
  useEffect(() => {
    const getActiveWS = async () => {
      const response = await getActiveWorkspace();
      navigate(`/${response.data?.user?.activeWorkspace?.slug}`);
    };
    getActiveWS();
    // eslint-disable-next-line
  }, []);
  return (
    <main>
      <div className="flex items-center justify-center w-screen h-screen">
        <img
          src={logoRectangle}
          alt="Contiyo logo"
          className="w-full animate-pulse-zoom max-w-24"
        ></img>
      </div>
    </main>
  );
}

function RedirectFromNotFound() {
  const navigate = useNavigate();
  const { activeWorkspace } = useWorkspace();
  useEffect(() => {
    if (activeWorkspace) {
      navigate(`/${activeWorkspace.slug}`);
    } else {
      navigate("/");
    }
    // eslint-disable-next-line
  }, [activeWorkspace]);
  return (
    <main>
      <div className="flex items-center justify-center w-screen h-screen">
        <img
          src={logoRectangle}
          alt="Contiyo logo"
          className="w-full animate-pulse-zoom max-w-24"
        ></img>
      </div>
    </main>
  );
}

export { RedirectToHome, RedirectFromNotFound };
