import { NavLink } from "react-router-dom";
import logo from "../assets/logo.svg";

interface BrandLogoProps {
  className?: string;
}
export default function BrandLogo({ className }: BrandLogoProps) {
  return (
    <NavLink to="/" className={className}>
      <img src={logo} alt="Contiyo logo" className="h-full" />
    </NavLink>
  );
}
