import { TabTitle } from "../../utils/tabTitle";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { Button } from "../../components/ui/Button";
import { Typography } from "../../components/ui/Typography";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../components/ui/Form";
import { Input } from "../../components/ui/Input";
import { PasswordInput } from "../../components/ui/PasswordInput";
import { NavLink, useNavigate } from "react-router-dom";
import FormLayout from "./FormLayout";
import { useAuthDialog } from "./DialogProvider";
import { useState } from "react";
import { Loader2 } from "lucide-react";
import { useResendConfirmationCode, useSignIn } from "./api/authService";
import { useAuth } from "./AuthProvider";
import { toast } from "sonner";

type FormValues = z.infer<typeof FormSchema>;

const FormSchema = z.object({
  email: z.string().trim().min(1, { message: "Field is required." }),
  password: z.string().min(1, { message: "Field is required." }),
});

export default function Login() {
  TabTitle("Login");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { openDialog } = useAuthDialog();
  const { setIsAuthenticated } = useAuth();
  const form = useForm<FormValues>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      email: "",
      password: "",
    },
    mode: "onSubmit",
    reValidateMode: "onChange",
  });

  const {
    setError,
    setFocus,
    clearErrors,
    formState: { errors },
  } = form;

  const [signIn] = useSignIn();
  const [resendConfirmationCode] = useResendConfirmationCode();

  const onSubmit = async (data: FormValues) => {
    setIsLoading(true);
    try {
      await signIn({
        variables: {
          input: {
            email: data.email,
            password: data.password,
          },
        },
      });
      setIsAuthenticated(true);
      navigate("/");
    } catch (error: any) {
      if (error.graphQLErrors) {
        error.graphQLErrors.forEach(async (err: any) => {
          switch (err.extensions?.code) {
            case "UNAUTHORIZED":
              setError("email", {
                type: "manual",
                message: "",
              });
              setError("password", {
                type: "manual",
                message: error.message,
              });
              setFocus("password");
              break;
            case "FORBIDDEN":
              try {
                await resendConfirmationCode({
                  variables: {
                    input: {
                      email: data.email,
                    },
                  },
                });
                openDialog("confirmEmailDialog", data.email, data.password);
              } catch (error: any) {
                toast.error("Uh oh! Something went wrong", {
                  description:
                    error.message || "There was a problem with your request",
                });
              }
              break;

            default:
              toast.error("Uh oh! Something went wrong", {
                description: "There was a problem with your request",
              });
          }
        });
      }
      if (error.networkError) {
        toast.error("Uh oh! Something went wrong", {
          description: "There was a problem with your request",
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <main>
      <FormLayout
        heading="Hello,"
        subHeading="Log into access your account and explore a world of endless
                possibilities."
      >
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="space-y-[24px]"
          >
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email address</FormLabel>
                  <FormControl>
                    <Input
                      placeholder="example@example.com"
                      {...field}
                      className={`${errors.email ? "border-red-500" : ""}`}
                      onChange={(e) => {
                        field.onChange(e);
                        if (errors?.email?.type) {
                          clearErrors("password");
                        }
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Password</FormLabel>
                  <FormControl>
                    <PasswordInput
                      placeholder="∗∗∗∗∗∗∗∗"
                      {...field}
                      className={`${errors.email ? "border-red-500" : ""}`}
                      onChange={(e) => {
                        field.onChange(e);
                        if (errors?.password?.type) {
                          clearErrors("email");
                        }
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="inline-flex justify-end w-full !mt-3">
              <Button
                variant="link"
                className="text-contiyo-primary-purple text-[14px]"
                asChild
              >
                <NavLink
                  to="/forgot-password"
                  className="font-bold text-contiyo-primary-purple"
                >
                  Forgot password?
                </NavLink>
              </Button>
            </div>

            <div className="flex flex-col justify-center">
              <Button
                type="submit"
                variant="primary"
                className="inline-flex items-center w-full"
                disabled={isLoading}
              >
                {!isLoading ? (
                  "Login"
                ) : (
                  <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                )}
              </Button>
              <div className="flex flex-wrap items-center justify-center gap-1 mt-6">
                <Typography
                  component="p"
                  className="text-base leading-tight text-center"
                >
                  Don’t have an account?
                </Typography>
                <Button
                  variant="link"
                  className="text-base font-bold text-contiyo-primary-purple"
                  asChild
                >
                  <NavLink to="/register">Register</NavLink>
                </Button>
              </div>
            </div>
          </form>
        </Form>
      </FormLayout>
    </main>
  );
}
