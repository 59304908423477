import { createContext, useState, useContext, ReactNode } from "react";

interface SettingsContextType {
  settingsNavigation: any;
  setSettingsNavigation: any;
  openDialog: any;
  closeDialog: any;
  isSettingsOpen: any;
  setIsSettingsOpen: any;
}

const SettingsContext = createContext<SettingsContextType | undefined>(
  undefined
);

export const SettingsProvider = ({ children }: { children: ReactNode }) => {
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [settingsNavigation, setSettingsNavigation] =
    useState<string>("my-account");

  const openDialog = (settingsNav?: string) => {
    if (settingsNav) {
      setSettingsNavigation(settingsNav);
    }
    setIsSettingsOpen(true);
  };

  const closeDialog = () => {
    setIsSettingsOpen(false);
  };

  return (
    <SettingsContext.Provider
      value={{
        settingsNavigation,
        setSettingsNavigation,
        openDialog,
        closeDialog,
        isSettingsOpen,
        setIsSettingsOpen,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export const useSettingsProvider = (): SettingsContextType => {
  const context = useContext(SettingsContext);
  if (!context) {
    throw new Error("useDialog must be used within a DialogProvider");
  }
  return context;
};
