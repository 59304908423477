import { ChevronRight } from "lucide-react";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "../../../components/ui/Avatar";
import {
  DropdownMenu,
  DropdownMenuTrigger,
} from "../../../components/ui/DropdownMenu";
import { Skeleton } from "../../../components/ui/Skeleton";
import { getInitials } from "../../../utils/getInitials";
import { useAuth } from "../../auth/AuthProvider";
import ProfileDropdownMenuContent from "./ProfileDropdownMenuContent";
import { useSidebar } from "../SidebarContext";

export default function ProfileSwitcher() {
  const { sideBarOpen, showMobileProfileSwitcher } = useSidebar();
  const { loggedUser, loggedUserDataLoading } = useAuth();

  return (
    <DropdownMenu modal={false}>
      <div className="sm:p-[10px] sm:border-t sm:border-t-accent">
        {showMobileProfileSwitcher ? (
          <DropdownMenuTrigger asChild tabIndex={0}>
            <Avatar className="w-8 h-8 rounded hover:cursor-pointer">
              <AvatarImage
                src={loggedUser && loggedUser?.image_url}
                className={`object-cover object-center size-8 rounded-radius `}
              />
              <AvatarFallback className="w-8 h-8 rounded">
                {loggedUser && getInitials(loggedUser?.name)}
              </AvatarFallback>
            </Avatar>
          </DropdownMenuTrigger>
        ) : (
          <DropdownMenuTrigger asChild tabIndex={0}>
            <div className="flex items-center justify-between gap-2 p-[6px] hover:bg-accent focus:bg-accent rounded-lg  cursor-pointer data-[state=open]:bg-accent">
              <Avatar className="rounded w-9 h-9">
                <AvatarImage
                  src={loggedUser && loggedUser?.image_url}
                  className={`object-cover object-center size-9 rounded-radius `}
                />
                <AvatarFallback className="rounded w-9 h-9">
                  {loggedUser && getInitials(loggedUser?.name)}
                </AvatarFallback>
              </Avatar>

              {sideBarOpen && (
                <div className="flex flex-col justify-center w-full min-w-0 gap-1">
                  {loggedUserDataLoading ? (
                    <>
                      <Skeleton className="w-full h-[14px] rounded-full"></Skeleton>
                      <Skeleton className="w-3/4 h-[12px] rounded-full"></Skeleton>
                    </>
                  ) : (
                    <div className="flex flex-col w-full min-w-0">
                      <span className="text-[14px] font-bold leading-tight truncate">
                        {loggedUser?.name}
                      </span>
                      <span className="font-semibold text-[12px] leading-tight truncate text-contiyo-secondary-text">
                        {loggedUser?.email}
                      </span>
                    </div>
                  )}
                </div>
              )}
              {sideBarOpen && (
                <div className="w-4 h-4">
                  <ChevronRight className="w-4 h-4"></ChevronRight>
                </div>
              )}
            </div>
          </DropdownMenuTrigger>
        )}
      </div>
      <ProfileDropdownMenuContent
        side={showMobileProfileSwitcher ? "bottom" : "right"}
        sideOffset={14}
        align="end"
      ></ProfileDropdownMenuContent>
    </DropdownMenu>
  );
}
