import { gql, useMutation } from "@apollo/client";
import {
  ChangeEmailMutation,
  ChangeEmailMutationVariables,
  ChangePasswordMutation,
  ChangePasswordMutationVariables,
  ConfirmChangeEmailMutation,
  ConfirmChangeEmailMutationVariables,
  DeleteUserMutation,
  DeleteUserMutationVariables,
  UpdateUserMutation,
  UpdateUserMutationVariables,
} from "../../../gql/graphql";

const CHANGE_PASSWORD_MUTATION = gql`
  mutation ChangePassword($input: ChangePasswordInput!) {
    changePassword(input: $input) {
      message
    }
  }
`;

const UPDATE_USER_MUTATION = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      message
    }
  }
`;

const DELETE_USER_MUTATION = gql`
  mutation DeleteUser($password: String!) {
    deleteUser(password: $password) {
      message
    }
  }
`;

const SEND_CHANGE_EMAIL_CODE_MUTATION = gql`
  mutation ChangeEmail($email: String!) {
    changeEmail(email: $email) {
      message
    }
  }
`;

const CONFIRM_EMAIL_CHANGE_MUTATION = gql`
  mutation ConfirmChangeEmail($confirmationCode: String!) {
    confirmChangeEmail(confirmationCode: $confirmationCode) {
      message
    }
  }
`;

export const useChangePassword = () => {
  return useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(
    CHANGE_PASSWORD_MUTATION
  );
};

export const useUpdateUser = () => {
  return useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
    UPDATE_USER_MUTATION
  );
};

export const useDeleteUser = () => {
  return useMutation<DeleteUserMutation, DeleteUserMutationVariables>(
    DELETE_USER_MUTATION
  );
};
export const useSendChangeEmailCode = () => {
  return useMutation<ChangeEmailMutation, ChangeEmailMutationVariables>(
    SEND_CHANGE_EMAIL_CODE_MUTATION
  );
};

export const useConfirmEmailChange = () => {
  return useMutation<
    ConfirmChangeEmailMutation,
    ConfirmChangeEmailMutationVariables
  >(CONFIRM_EMAIL_CHANGE_MUTATION);
};
