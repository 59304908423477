import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "../../utils/cn";

const buttonVariants = cva(
  "h-9 inline-flex items-center justify-center whitespace-nowrap py-2 px-4 rounded-[6px] gap-1.5 text-[14px] leading-tight ring-offset-background transition ease-in-out focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        default:
          "border border-contiyo-primary-purple text-contiyo-primary-purple hover:bg-contiyo-primary-purple hover:text-white",
        primary:
          "bg-contiyo-primary-purple text-white hover:bg-contiyo-primary-purple-hover",
        destructive:
          "text-destructive border-destructive border hover:bg-destructive/10 disabled:opacity-100 disabled:bg-destructive/10",
        outline:
          "text-contiyo-text border border-accent bg-background hover:bg-accent hover:text-accent-foreground",
        secondary:
          "bg-contiyo-background-accent-purple text-contiyo-primary-purple hover:bg-contiyo-background-accent-purple-hover",
        ghost: "hover:bg-accent hover:text-accent-foreground",
        link: "text-primary h-fit p-0 leading-tight relative focus-visible:ring-0 focus-visible:ring-offset-2 ring-offset-inherit hover:text-contiyo-primary-purple-hover focus-visible:outline focus-visible:outline-1",
        creatorActiveTab:
          "bg-contiyo-background-accent-purple border-contiyo-primary-purple text-contiyo-primary-purple border border-transparent",
        creatorNotActiveTab:
          "border border-accent bg-white text-contiyo-text hover:bg-accent",
        primary2hover:
          "bg-contiyo-background-accent-purple border-contiyo-primary-purple text-contiyo-primary-purple border border-transparent hover:bg-contiyo-background-accent-purple-hover",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={cn(buttonVariants({ variant, className }))}
        ref={ref}
        {...props}
      />
    );
  }
);
Button.displayName = "Button";

export { Button, buttonVariants };
