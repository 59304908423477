import { toast } from "sonner";
import {
  FeedDistribution,
  GetGroupPostsQuery,
  InstagramDataInput,
  InstagramMediaType,
  LinkedInContentType,
  LinkedInDataInput,
  PostStatus,
  SocialMediaType,
  Visibility,
} from "../../gql/graphql";
import { GET_GROUP_POSTS, useCreatePost } from "./api/creatorService";

interface createBlankPostForMediaParams {
  PK: string;
  title: string;
  slug: string;
  workspace_id: string;
  scheduled_at: number;
  social_media_type: SocialMediaType;
  status: PostStatus;
  account_id: string;
  media_type: Record<string, boolean> | string;
}

export const useCreateBlankPostForMedia = (setIsDataLoading?: any) => {
  const [createPost] = useCreatePost({
    update(cache, { data }, { variables }) {
      // `data?.createPost` should contain the newly created post
      if (!data?.savePost || !variables?.input?.account_data?.account_id)
        return;

      const newPost = data.savePost;
      const { PK, workspace_id, account_data } = variables.input;
      const { account_id } = account_data;

      const existingData = cache.readQuery<GetGroupPostsQuery>({
        query: GET_GROUP_POSTS,
        variables: {
          groupId: PK?.split("#")[1],
          workspaceId: workspace_id,
        },
      });

      if (!existingData) return;

      // Extract current groupPosts
      const { groupPosts } = existingData.workspace;

      // Find the index of the account group that matches the new post’s account_id
      const accountId = newPost?.account_data?.account_id;
      const accountIndex = groupPosts.findIndex(
        (gp) => gp.account_id === accountId
      );

      let updatedGroupPosts = [...groupPosts];
      if (accountIndex === -1) {
        // If no entry for this account, create a new one
        updatedGroupPosts.push({
          __typename: "AccountPosts",
          account_id: account_id,
          posts: [newPost],
        });
      } else {
        // Append the new post to the existing account’s posts
        updatedGroupPosts[accountIndex] = {
          ...updatedGroupPosts[accountIndex],
          posts: [...updatedGroupPosts[accountIndex].posts, newPost],
        };
      }

      // Write the updated data back into the cache
      cache.writeQuery({
        query: GET_GROUP_POSTS,
        variables: {
          groupId: PK?.split("#")[1],
          workspaceId: workspace_id,
        },
        data: {
          workspace: {
            ...existingData.workspace,
            groupPosts: updatedGroupPosts,
          },
        },
      });
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  const createBlankPostForMedia = async ({
    PK,
    title,
    slug,
    workspace_id,
    scheduled_at,
    social_media_type,
    status,
    account_id,
    media_type,
  }: createBlankPostForMediaParams) => {
    setIsDataLoading(true);
    try {
      let selectedMediaTypes: string[];

      if (typeof media_type === "string") {
        selectedMediaTypes = [media_type.toUpperCase()];
      } else {
        selectedMediaTypes = Object.keys(media_type)
          .filter((key) => media_type[key] === true)
          .map((key) => key.toUpperCase());
      }

      if (selectedMediaTypes.length === 0) {
        toast.error("No media type selected.");
        return;
      }

      // Build an array of post payloads
      const postPayloads = selectedMediaTypes.map((media) => {
        let postPayload = {
          PK,
          title,
          slug,
          workspace_id,
          scheduled_at,
          social_media_type,
          status,
          account_data: { account_id },
        } as {
          PK: string;
          title: string;
          slug: string;
          workspace_id: string;
          scheduled_at: number;
          social_media_type: SocialMediaType;
          status: PostStatus;
          account_data: { account_id: string };
          instagram_data?: InstagramDataInput;
          linkedin_data?: LinkedInDataInput;
        };

        if (social_media_type === SocialMediaType.Instagram) {
          postPayload.instagram_data = {
            media_type: media as InstagramMediaType,
          };
        } else if (social_media_type === SocialMediaType.Linkedin) {
          postPayload.linkedin_data = {
            content_type: media as LinkedInContentType,
            visibility: Visibility.Public,
            commentary: "",
            distribution: {
              target_entities: [""],
              third_party_distribution_channels: [""],
              feed_distribution: FeedDistribution.MainFeed,
            },
            is_reshare_disabled: false,
          };
        } else {
          toast.error("Unsupported social media type.");
        }

        return postPayload;
      });

      const responses = await Promise.all(
        postPayloads.map((payload) =>
          createPost({ variables: { input: payload } })
        )
      );

      return responses;
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      setIsDataLoading(false);
    }
  };

  return { createBlankPostForMedia };
};
