import {
  AudioLines,
  Hash,
  Lightbulb,
  PencilLine,
  ScissorsLineDashed,
  Sparkles,
} from "lucide-react";

export const aiActions = [
  {
    id: "ai-helper",
    name: "AI Helper",
    icon: <Sparkles className="w-full h-full shrink-0" />,
    placeholder: "Write about any topic you need assistance with...",
  },
  {
    id: "ideate",
    name: "Ideate",
    icon: <Lightbulb className="w-full h-full shrink-0" />,
    placeholder: "Type a theme to generate 5 hooks...",
  },
  {
    id: "continue-writing",
    name: "Continue writing",
    icon: <PencilLine className="w-full h-full shrink-0" />,
    placeholder: "Write a sentence or paragraph to continue in your style...",
  },
  {
    id: "change-tone",
    name: "Change tone",
    icon: <AudioLines className="w-full h-full shrink-0" />,
    placeholder: "Write text to change its tone to {variable}...",
  },
  {
    id: "shorten-text",
    name: "Shorten text",
    icon: <ScissorsLineDashed className="w-full h-full shrink-0" />,
    placeholder: "Enter text to make it more concise...",
  },
  {
    id: "hashtag-generator",
    name: "Hashtag generator",
    icon: <Hash className="w-full h-full shrink-0" />,
    placeholder: "Write text to generate relevant hashtags...",
  },
];
