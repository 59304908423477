import {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
  Dispatch,
  SetStateAction,
} from "react";

interface SidebarContextType {
  sideBarOpen: boolean;
  setSideBarOpen: Dispatch<SetStateAction<boolean>>;
  toggleSideBar: () => void;
  hideSidebarToggle: boolean;
  setHideSidebarToggle: Dispatch<SetStateAction<boolean>>;
  hideDesktopSidebar: boolean;
  isResponsive: boolean;
  setIsResponsive: Dispatch<SetStateAction<boolean>>;
  showMobileProfileSwitcher: boolean;
  showMobileWorkspaceSwitcher: boolean;
  sideBarDrawerOpen: boolean;
  setSidebarDrawerOpen: Dispatch<SetStateAction<boolean>>;
}

const SidebarContext = createContext<SidebarContextType | undefined>(undefined);

export const SidebarProvider = ({ children }: { children: ReactNode }) => {
  const [sideBarOpen, setSideBarOpen] = useState(true);
  const [sideBarDrawerOpen, setSidebarDrawerOpen] = useState(false);
  const [hideSidebarToggle, setHideSidebarToggle] = useState(false);
  const [hideDesktopSidebar, setHideDesktopSidebar] = useState(false);
  const [isResponsive, setIsResponsive] = useState(true);
  const [showMobileProfileSwitcher, setShowMobileProfileSwitcher] =
    useState(false);
  const [showMobileWorkspaceSwitcher, setShowMobileWorkspaceSwitcher] =
    useState(false);

  const toggleSideBar = () => {
    setSideBarOpen((prev) => !prev);
  };

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;

      if (width < 640) {
        setSideBarOpen(false);
        setHideSidebarToggle(true);
        setHideDesktopSidebar(true);
        setShowMobileProfileSwitcher(true);
        setShowMobileWorkspaceSwitcher(true);
      } else {
        setHideDesktopSidebar(false);
        setShowMobileProfileSwitcher(false);
        setShowMobileWorkspaceSwitcher(false);
        setSidebarDrawerOpen(false);

        if (isResponsive) {
          if (width < 960) {
            setSideBarOpen(false);
            setHideSidebarToggle(true);
          } else {
            setSideBarOpen(false);
            setHideSidebarToggle(false);
          }
        }
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [isResponsive]);

  return (
    <SidebarContext.Provider
      value={{
        sideBarOpen,
        setSideBarOpen,
        toggleSideBar,
        hideSidebarToggle,
        setHideSidebarToggle,
        hideDesktopSidebar,
        isResponsive,
        setIsResponsive,
        showMobileProfileSwitcher,
        showMobileWorkspaceSwitcher,
        sideBarDrawerOpen,
        setSidebarDrawerOpen,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};
export const useSidebar = () => {
  const context = useContext(SidebarContext);
  if (context === undefined) {
    throw new Error("useSidebar must be used within a SidebarProvider");
  }
  return context;
};
