import { ChevronLeft, ChevronRight } from "lucide-react";
import { Typography } from "../../components/ui/Typography";
import { Tabs, TabsList, TabsTrigger } from "../../components/ui/Tabs";
import { Button } from "../../components/ui/Button";
import { useCalendarContext } from "./CalendarProvider";
import { isSameMonth, isSameWeek } from "date-fns";

export const CalendarHeader = () => {
  const {
    calendarView,
    setCalendarView,
    handleToday,
    handlePreviousWeek,
    handleNextWeek,
    handlePreviousMonth,
    handleNextMonth,
    getFormattedCurrentWeek,
    getFormattedCurrentMonth,
    currentDate,
    todaysDate,
  } = useCalendarContext();
  const changeCalendarView = (value: string) => {
    switch (value) {
      case "weekly":
        setCalendarView("weekly");
        break;
      case "monthly":
        setCalendarView("monthly");
        break;
      default:
        break;
    }
  };
  const onPreviousHandler = () => {
    switch (calendarView) {
      case "weekly":
        handlePreviousWeek();
        break;
      case "monthly":
        handlePreviousMonth();
        break;
      default:
        break;
    }
  };
  const onNextHandler = () => {
    switch (calendarView) {
      case "weekly":
        handleNextWeek();
        break;
      case "monthly":
        handleNextMonth();
        break;
      default:
        break;
    }
  };

  const handleTodayDisabledButton = () => {
    switch (calendarView) {
      case "weekly":
        return isSameWeek(currentDate, todaysDate);
      case "monthly":
        return isSameMonth(currentDate, todaysDate);
      default:
        break;
    }
  };

  return (
    <div className="z-10 flex items-center justify-between py-[16px]">
      <Tabs defaultValue={calendarView} onValueChange={changeCalendarView}>
        <TabsList>
          <TabsTrigger value="weekly">Weekly</TabsTrigger>
          <TabsTrigger value="monthly">Monthly</TabsTrigger>
        </TabsList>
      </Tabs>

      <Typography component="h3" className="text-[20px]">
        {calendarView === "weekly" && getFormattedCurrentWeek()}
        {calendarView === "monthly" && getFormattedCurrentMonth()}
      </Typography>
      <div className="flex gap-2">
        <Button
          className="w-8 h-8 p-1 bg-white border border-accent"
          onClick={onPreviousHandler}
          variant="ghost"
        >
          <ChevronLeft className="w-5 h-5" />
        </Button>
        <Button
          className="h-8 bg-white border border-accent disabled:cursor-not-allowed disabled:pointer-events-auto disabled:hover:bg-white"
          onClick={handleToday}
          variant="ghost"
          disabled={handleTodayDisabledButton()}
        >
          Today
        </Button>
        <Button
          className="w-8 h-8 p-1 bg-white border border-accent"
          onClick={onNextHandler}
          variant="ghost"
        >
          <ChevronRight className="w-5 h-5" />
        </Button>
      </div>
    </div>
  );
};
