import { TabTitle } from "../../utils/tabTitle";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { Button } from "../../components/ui/Button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../components/ui/Form";
import { Input } from "../../components/ui/Input";
import { PasswordInput } from "../../components/ui/PasswordInput";
import { Checkbox } from "../../components/ui/Checkbox";
import { NavLink, useNavigate } from "react-router-dom";
import FormLayout from "./FormLayout";
import { useCallback, useMemo, useState } from "react";
import { CircleCheck, CircleX, Loader2 } from "lucide-react";
import { useAuthDialog } from "./DialogProvider";
import { debounce } from "lodash";
import { confirmPassword, passwordRequirementsField } from "./formFields";
import { PasswordRequirementsTooltip } from "./PasswordRequirementsTooltip";
import { useGetUserLazy, useSignUp } from "./api/authService";
import { toast } from "sonner";

type FormValues = z.infer<typeof FormSchema>;

const FormSchema = z
  .object({
    name: z.string().trim().min(1, { message: "Field is required" }),
    email: z.string().trim().email("Please enter a valid email address"),
    password: passwordRequirementsField,
    confirmPassword: confirmPassword,
    terms: z.boolean().refine((value) => value, {
      message: "You must accept the terms and conditions",
    }),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords do not match",
    path: ["confirmPassword"],
  });

export default function Register() {
  TabTitle("Register");
  const navigate = useNavigate();

  const [isPasswordFocused, setIsPasswordFocused] = useState(false);
  const { openDialog } = useAuthDialog();
  const [isLoading, setIsLoading] = useState(false);
  const [isEmailFieldFocused, setIsEmailFieldFocused] = useState(false);
  const [emailChecking, setEmailChecking] = useState(false);

  const form = useForm<FormValues>({
    resolver: zodResolver(FormSchema),
    reValidateMode: "onChange",
    mode: "onSubmit",
    defaultValues: {
      name: "",
      email: "",
      password: "",
      confirmPassword: "",
      terms: false,
    },
    criteriaMode: "all",
  });
  const {
    trigger,
    reset,
    setError,
    clearErrors,
    formState: { errors, submitCount },
  } = form;

  const [signUp] = useSignUp();
  const [getUser] = useGetUserLazy();

  const onSubmit = async (data: FormValues) => {
    setIsLoading(true);
    const isEmailValid = await checkEmail(data.email);
    if (!isEmailValid) {
      setIsLoading(false);
      return;
    }
    try {
      await signUp({
        variables: {
          input: {
            email: data.email,
            password: data.password,
            name: data.name,
          },
        },
      });
      openDialog("confirmEmailDialog", data.email, data.password);
      reset();
    } catch (error: any) {
      toast.error("Uh oh! Something went wrong", {
        description: "An error occurred during sign up",
      });
    } finally {
      setIsLoading(false);
    }
  };
  const checkEmail = useCallback(
    async (email: string) => {
      try {
        const { data } = await getUser({ variables: { userId: email } });
        if (data) {
          setError("email", {
            type: "manual",
            message: "Email is already taken",
          });
          return false;
        } else {
          clearErrors("email");
          trigger("email");
          return true;
        }
      } catch (error: any) {
        setError("email", {
          type: "manual",
          message: "Error checking email",
        });
        return false;
      } finally {
        setEmailChecking(false);
      }
    },
    [getUser, clearErrors, setError, trigger]
  );

  const debouncedEmailCheck = useMemo(
    () =>
      debounce(async (email: string) => {
        await checkEmail(email);
      }, 500),
    [checkEmail]
  );

  const handleEmailChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      debouncedEmailCheck(e.target.value);
    },
    [debouncedEmailCheck]
  );

  return (
    <main>
      <FormLayout
        heading="Register"
        subHeading="Let's embark on this journey together."
        onBackClick={() => navigate("/login")}
      >
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="space-y-[24px]"
          >
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Full name</FormLabel>
                  <FormControl>
                    <Input
                      placeholder="Full name"
                      {...field}
                      className={`${errors.name && "border-red-500"}`}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email address</FormLabel>
                  <FormControl>
                    <div className="relative">
                      <Input
                        placeholder="example@example.com"
                        className={`pr-10 ${errors.email && "border-red-500"}`}
                        {...field}
                        onChange={(e) => {
                          field.onChange(e);
                          handleEmailChange(e);
                          setEmailChecking(true);
                        }}
                        onFocus={() => setIsEmailFieldFocused(true)}
                        onBlur={() => setIsEmailFieldFocused(false)}
                      />
                      <Button
                        variant="ghost"
                        asChild
                        className={
                          "absolute top-0 right-0 h-full px-3 py-2 hover:bg-transparent border border-transparent focus-visible:border-contiyo-primary-purple focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-indigo-100"
                        }
                      >
                        <div>
                          {emailChecking ? (
                            <Loader2 className="w-[16px] h-[16px] animate-spin" />
                          ) : errors.email ? (
                            <CircleX className="w-[16px] h-[16px] stroke-red-500" />
                          ) : (
                            form.getValues("email") !== "" && (
                              <CircleCheck
                                className={`w-[16px] h-[16px] ${
                                  isEmailFieldFocused &&
                                  "stroke-contiyo-primary-purple"
                                }`}
                              />
                            )
                          )}
                        </div>
                      </Button>
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Password</FormLabel>
                  <FormControl>
                    <PasswordInput
                      placeholder="∗∗∗∗∗∗∗∗"
                      {...field}
                      className={`${errors.password ? "border-red-500" : ""}`}
                      onFocus={() => setIsPasswordFocused(true)}
                      onBlur={() => setIsPasswordFocused(false)}
                      onChange={(e) => {
                        field.onChange(e);
                        //Fix for issue where changing password wouldn't trigger password dont match onChange
                        if (submitCount) {
                          trigger("confirmPassword");
                        }
                      }}
                    >
                      <PasswordRequirementsTooltip
                        isPasswordFocused={isPasswordFocused}
                        errors={errors}
                        submitCount={submitCount}
                      />
                    </PasswordInput>
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="confirmPassword"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Repeat password</FormLabel>
                  <FormControl>
                    <PasswordInput
                      placeholder="∗∗∗∗∗∗∗∗"
                      {...field}
                      className={`${
                        errors.confirmPassword ? "border-red-500" : ""
                      }`}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="terms"
              render={({ field }) => (
                <>
                  <div className="flex items-center space-x-4">
                    <FormControl>
                      <Checkbox
                        id="terms"
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                    <label
                      htmlFor="terms"
                      className="text-[18px] font-normal font-redhat leading-5 text-contiyo-text peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                    >
                      <span>
                        {"I agree to Contiyo's "}
                        <Button
                          variant="link"
                          className="inline p-0 whitespace-normal text-[18px]"
                          asChild
                        >
                          <NavLink to="/terms-of-service">
                            Terms of Service
                          </NavLink>
                        </Button>
                        {" and "}
                        <Button
                          variant="link"
                          className="inline p-0 whitespace-normal text-[18px]"
                          asChild
                        >
                          <NavLink to="/privacy-policy">Privacy Policy</NavLink>
                        </Button>
                        .
                      </span>
                    </label>
                  </div>
                  <FormMessage />
                </>
              )}
            />

            <div className="flex justify-center">
              <Button
                type="submit"
                variant="primary"
                className="inline-flex items-center w-full"
                disabled={isLoading}
                onClick={() => {
                  if (!form.formState.isValid) {
                    checkEmail(form.getValues("email"));
                  }
                }}
              >
                {!isLoading ? (
                  "Register"
                ) : (
                  <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                )}
              </Button>
            </div>
          </form>
        </Form>
      </FormLayout>
    </main>
  );
}
