import * as React from "react";

import { cn } from "../../utils/cn";
import { Input } from "../../components/ui/Input";
import { Label } from "../../components/ui/Label";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {}

export interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {}

const FloatingInput = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, ...props }, ref) => {
    return (
      <Input
        placeholder=" "
        className={cn("peer border-accent p-3", className)}
        ref={ref}
        {...props}
      />
    );
  }
);
FloatingInput.displayName = "FloatingInput";

const FloatingTextArea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, ...props }, ref) => {
    return (
      <textarea
        className={cn(
          "peer border-accent p-3 flex min-h-[100px] w-full rounded-md border bg-transparent px-3 py-2 text-sm  placeholder:text-muted-foreground focus-visible:outline-none  focus-visible:border-contiyo-primary-purple focus-visible:ring-2 focus-visible:ring-indigo-100  disabled:cursor-not-allowed disabled:opacity-50",
          className
        )}
        placeholder=" "
        ref={ref}
        {...props}
      />
    );
  }
);
FloatingTextArea.displayName = "FloatingTextArea";

const FloatingLabel = React.forwardRef<
  React.ElementRef<typeof Label>,
  React.ComponentPropsWithoutRef<typeof Label>
>(({ className, ...props }, ref) => {
  return (
    <Label
      className={cn(
        "font-medium peer-focus:secondary peer-focus:dark:secondary absolute start-2 top-2 z-10 origin-[0] -translate-y-4 scale-75 peer-focus:text-contiyo-text transform bg-background px-2 text-sm text-contiyo-text duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-contiyo-secondary-text peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2 rtl:peer-focus:left-auto rtl:peer-focus:translate-x-1/4 cursor-text",
        className
      )}
      ref={ref}
      {...props}
    />
  );
});
FloatingLabel.displayName = "FloatingLabel";

type FloatingLabelInputProps = InputProps & { label?: string };

const FloatingLabelInput = React.forwardRef<
  React.ElementRef<typeof FloatingInput>,
  React.PropsWithoutRef<FloatingLabelInputProps>
>(({ id, label, ...props }, ref) => {
  return (
    <div className="relative">
      <FloatingInput ref={ref} id={id} {...props} />
      <FloatingLabel htmlFor={id}>{label}</FloatingLabel>
    </div>
  );
});
FloatingLabelInput.displayName = "FloatingLabelInput";

export { FloatingInput, FloatingLabel, FloatingTextArea, FloatingLabelInput };
