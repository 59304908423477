import { SocialMediaType, WorkspaceSocialMedia } from "../../types-and-hooks";
import instagram from "../assets/icons/instagram-icon.svg";
import linkedin from "../assets/icons/linkedinIcon.svg";
import instagramDefaultPicture from "../assets/instagram-default-profile-picture.jpg";
import linkedinDefaultPicture from "../assets/linkedin-default-profile-picture.svg";

export const getSocialMediaImageUrl = (social: SocialMediaType) => {
  switch (social) {
    case "INSTAGRAM":
      return instagram;
    case "LINKEDIN":
      return linkedin;
  }
};

export const getProfileImageUrl = (socialMediaObject: WorkspaceSocialMedia) => {
  const defaultImages = {
    INSTAGRAM: instagramDefaultPicture,
    LINKEDIN: linkedinDefaultPicture,
    BOILERPLATE: "",
  };

  if (socialMediaObject.image_url) {
    return socialMediaObject.image_url;
  }

  const { social_media_type } = socialMediaObject;
  return defaultImages[social_media_type];
};

export const getPlatformColorClasses = (platform: SocialMediaType) => {
  switch (platform) {
    case "INSTAGRAM":
      return {
        lightSocialBG: "bg-[#FFEAEE]",
        darkSocialBG: "bg-[#F7A8B8]",
        lightBorder: "border-[#FFEAEE]",
        darkBorder: "border-[#F7A8B8]",
        lightStroke: "stroke-[#FFEAEE]",
        darkStroke: "stroke-[#F7A8B8]",
      };
    case "LINKEDIN":
      return {
        lightSocialBG: "bg-[#F5F9FF]",
        darkSocialBG: "bg-[#0B69C7]",
        lightBorder: "border-[#F5F9FF]",
        darkBorder: "border-[#0B69C7]",
        lightStroke: "stroke-[#F5F9FF]",
        darkStroke: "stroke-[#0B69C7]",
      };
    default:
      return {
        lightSocialBG: "bg-white",
        darkSocialBG: "bg-accent",
        lightBorder: "bg-white",
        darkBorder: "border-accent",
        lightStroke: "stroke-white",
        darkStroke: "stroke-accent",
      };
  }
};

export const socialPlatforms = {
  instagram,
  linkedin,
};
