import { z } from "zod";
import {
  length_message,
  number_message,
  special_char_message,
  uppercase_message,
  lowercase_message,
} from "./errorMessages";

const emailField = z
  .string()
  .trim()
  .min(1, { message: "Field is required." })
  .email("Please enter a valid email address.");

const passwordField = z
  .string()
  .min(8, { message: "Password must be at least 8 characters long." });

const passwordRequirementsField = z
  .string()
  .regex(/^.{8,}$/, { message: length_message })
  .regex(new RegExp(".*[A-Z].*"), {
    message: uppercase_message,
  })
  .regex(new RegExp(".*[a-z].*"), {
    message: lowercase_message,
  })
  .regex(new RegExp(".*[0-9].*"), {
    message: number_message,
  })
  .regex(
    new RegExp(
      ".*[\\^\\$\\*\\.\\[\\]\\{\\}\\(\\)\\?\\-\"!@#%&/\\\\,><':;|_~`+= ].*"
    ),
    {
      message: special_char_message,
    }
  );

const confirmPassword = z.string().min(1, { message: " " });
const oldPassword = z
  .string()
  .min(1, { message: "Old password must be provided" });
export {
  emailField,
  passwordField,
  passwordRequirementsField,
  confirmPassword,
  oldPassword,
};
