import { ChevronLeft, ChevronsUpDown } from "lucide-react";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "../../../components/ui/Avatar";
import {
  DropdownMenu,
  DropdownMenuTrigger,
} from "../../../components/ui/DropdownMenu";
import { Skeleton } from "../../../components/ui/Skeleton";
import { getInitial } from "../../../utils/getInitials";
import { useWorkspace } from "../../workspace/WorkspaceProvider";
import { useSidebar } from "../SidebarContext";
import WorkspaceDropdownMenuContent from "./WorkspaceDropdownMenuContent";

export default function WorkspaceSwitcher() {
  const {
    sideBarOpen,
    toggleSideBar,
    hideSidebarToggle,
    showMobileWorkspaceSwitcher,
  } = useSidebar();
  const { activeWorkspace, activeWorkspaceLoading } = useWorkspace();

  return (
    <DropdownMenu modal={false}>
      <div className="sm:p-[10px] workspaceSwitcher relative">
        {!hideSidebarToggle && (
          <div
            className="absolute right-0 z-20 flex items-center w-5 translate-x-full -translate-y-1/2 bg-white border border-l-0 top-1/2 border-l-accent rounded-e-radius h-9 hover:cursor-pointer closeSidebar"
            onClick={toggleSideBar}
          >
            <ChevronLeft
              className={`w-4 h-4 transition-all duration-300 ${
                !sideBarOpen ? "rotate-180" : ""
              }`}
            />
          </div>
        )}
        {showMobileWorkspaceSwitcher ? (
          <DropdownMenuTrigger asChild tabIndex={0}>
            <Avatar className="w-8 h-8 rounded hover:cursor-pointer">
              <AvatarImage
                src={activeWorkspace && activeWorkspace?.image_url}
                className={`object-cover object-center w-8 h-8 rounded-radius `}
              />
              <AvatarFallback className="w-8 h-8 rounded">
                {activeWorkspace && getInitial(activeWorkspace?.name)}
              </AvatarFallback>
            </Avatar>
          </DropdownMenuTrigger>
        ) : (
          <DropdownMenuTrigger asChild tabIndex={0}>
            <div className="flex items-center justify-between gap-2 p-[6px] hover:bg-accent focus:bg-accent rounded-lg  cursor-pointer data-[state=open]:bg-accent">
              <Avatar className="rounded w-9 h-9">
                <AvatarImage
                  src={activeWorkspace && activeWorkspace?.image_url}
                  className={`object-cover object-center w-9 h-9 rounded-radius `}
                />
                <AvatarFallback className="rounded w-9 h-9">
                  {activeWorkspace && getInitial(activeWorkspace?.name)}
                </AvatarFallback>
              </Avatar>
              {sideBarOpen && (
                <div className="flex flex-col justify-center w-full min-w-0 gap-1">
                  {activeWorkspaceLoading ? (
                    <>
                      <Skeleton className="w-full h-[14px] rounded-full"></Skeleton>
                      <Skeleton className="w-3/4 h-[12px] rounded-full"></Skeleton>
                    </>
                  ) : (
                    <div className="flex flex-col w-full min-w-0">
                      <span className="text-[14px] font-bold leading-tight truncate">
                        {activeWorkspace?.name}
                      </span>
                      <span className="font-semibold text-[12px] leading-tight truncate text-contiyo-secondary-text">
                        Free plan
                      </span>
                    </div>
                  )}
                </div>
              )}

              {sideBarOpen && (
                <div className="w-4 h-4">
                  <ChevronsUpDown className="w-4 h-4"></ChevronsUpDown>
                </div>
              )}
            </div>
          </DropdownMenuTrigger>
        )}
      </div>

      <WorkspaceDropdownMenuContent
        side={showMobileWorkspaceSwitcher ? "bottom" : "right"}
        sideOffset={14}
        align="start"
      />
    </DropdownMenu>
  );
}
