import { useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { TabTitle } from "../../utils/tabTitle";
import Layout, { LayoutBody } from "../layout/Layout";
import { useWorkspace } from "../workspace/WorkspaceProvider";
import { GET_ALL_POSTS } from "./api/postsService";

export default function Posts() {
  TabTitle("Posts");
  const navigate = useNavigate();
  const { activeWorkspace } = useWorkspace();
  const { data } = useQuery(GET_ALL_POSTS, {
    variables: {
      workspaceId: activeWorkspace?.PK?.split("#")[1],
      from: 0,
      to: 2147483647,
    },
    skip: !activeWorkspace,
  });
  return (
    <Layout tabTitle="Posts">
      <LayoutBody className="overflow-auto">
        <div className="flex flex-col gap-4">
          {data?.workspace?.posts?.map((item: any, index: any) => (
            <div
              key={index}
              className="h-auto gap-4 p-4 bg-white border text-wrap border-accent hover:cursor-pointer max-h-[200px] overflow-scroll"
              onClick={() => {
                const accountId =
                  item.account_data?.account_id || "boilerplate";
                const path = `/${activeWorkspace.slug}/creator/${
                  item.PK.split("#")[1]
                }`;
                const queryParams = new URLSearchParams({
                  account_id: "MEDIA#" + accountId,
                  post_id: item.SK,
                }).toString();
                const url = `${path}?${queryParams}`;
                navigate(url);
              }}
            >
              <pre>{JSON.stringify(item, null, 2)}</pre>
            </div>
          ))}
        </div>
      </LayoutBody>
    </Layout>
  );
}
