import { gql, useLazyQuery, useMutation } from "@apollo/client";
import {
  CreateWorkspaceMutation,
  CreateWorkspaceMutationVariables,
  SetActiveWorkspaceMutation,
  SetActiveWorkspaceMutationVariables,
} from "../../../gql/graphql";

const GET_WORKSPACE_QUERY = gql`
  query getWorkspace {
    user {
      workspaces {
        PK
        name
        image_url
        slug
      }
    }
  }
`;
const GET_SINGLE_WORKSPACE_QUERY = gql`
  query Workspace($workspaceId: ID!) {
    workspace(workspaceId: $workspaceId) {
      PK
      name
      slug
      image_url
    }
  }
`;

const GET_ACTIVE_WORKSPACE = gql`
  query ActiveWorkspace {
    user {
      activeWorkspace {
        PK
        name
        image_url
        slug
      }
    }
  }
`;

const SET_ACTIVE_WORKSPACE_MUTATION = gql`
  mutation SetActiveWorkspace($workspaceId: String!) {
    setActiveWorkspace(workspaceId: $workspaceId) {
      message
    }
  }
`;

const CREATE_WORKSPACE_MUTATION = gql`
  mutation CreateWorkspace($input: CreateWorkspaceInput!) {
    createWorkspace(input: $input) {
      PK
      name
      image_url
      slug
    }
  }
`;

export const useGetWorkspace = () => {
  return useLazyQuery(GET_WORKSPACE_QUERY, {
    fetchPolicy: "no-cache",
    nextFetchPolicy: "no-cache",
    pollInterval: 3000000,
  });
};
export const useGetSingleWorkspace = () => {
  return useLazyQuery(GET_SINGLE_WORKSPACE_QUERY, {
    fetchPolicy: "no-cache",
    nextFetchPolicy: "no-cache",
  });
};
export const useGetActiveWorkspace = () => {
  return useLazyQuery(GET_ACTIVE_WORKSPACE, {
    fetchPolicy: "no-cache",
    nextFetchPolicy: "no-cache",
  });
};
export const useSetActiveWorkspace = () => {
  return useMutation<
    SetActiveWorkspaceMutation,
    SetActiveWorkspaceMutationVariables
  >(SET_ACTIVE_WORKSPACE_MUTATION);
};
export const useCreateWorkspace = () => {
  return useMutation<CreateWorkspaceMutation, CreateWorkspaceMutationVariables>(
    CREATE_WORKSPACE_MUTATION
  );
};
