import {
  addDays,
  startOfWeek,
  format,
  isSameDay,
  parseISO,
  isAfter,
  isBefore,
} from "date-fns";
import { cn } from "../../utils/cn";
import { Typography } from "../../components/ui/Typography";
import { Button } from "../../components/ui/Button";
import { CalendarDays, CalendarIcon, Plus } from "lucide-react";
import { ScrollArea } from "../../components/ui/ScrollArea";
import { useState } from "react";
import { getSocialMediaImageUrl } from "../../utils/socialSwitcher";
import { SocialPlatformsState } from "./CalendarPage";
import { useCalendarContext } from "./CalendarProvider";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "../../components/ui/Sheet";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../components/ui/Form";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../components/ui/Popover";
import { Calendar } from "../../components/ui/Calendar";
import { TimePicker } from "../../components/ui/date-time-picker/time-picker";

const daysOfWeek = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

export const WeeklyCalendar = ({
  posts,
  sort,
  sortingBy,
  searchTerm,
  visiblePlatforms,
}: {
  posts: any;
  sort: string;
  sortingBy: string;
  searchTerm: string;
  visiblePlatforms: SocialPlatformsState;
}) => {
  const FormSchema = z.object({
    dob: z.date({
      required_error: "A date is required.",
    }),
  });

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
  });

  const { setValue } = form;

  function onSubmit(data: z.infer<typeof FormSchema>) {
    // console.log(data);
  }
  const { currentDate, todaysDate } = useCalendarContext();
  const startOfCurrentWeek = startOfWeek(currentDate, { weekStartsOn: 1 });

  const [hoveredColumn, setHoveredColumn] = useState<string | null>(null);
  const [hoveredButton, setHoveredButton] = useState<string | null>(null);

  const showButtonFunc = (date: Date) => {
    const dateString = format(date, "yyyy-MM-dd");
    if (isAfter(date, todaysDate) || isSameDay(date, todaysDate)) {
      setHoveredColumn(dateString);
    } else {
      setHoveredColumn(null);
    }
  };

  const hideButtonFunc = () => {
    setHoveredColumn(null);
  };

  const renderDaysHeader = () => {
    return daysOfWeek.map((day, index) => {
      const currentDay = addDays(startOfCurrentWeek, index);
      const isToday = isSameDay(currentDay, todaysDate);

      return (
        <div
          key={index}
          className={cn(
            "flex-1 bg-white p-2 tablet:p-4 border-r last:border-r-transparent border-b-2",
            {
              "text-contiyo-primary-purple  bg-white bg-contiyo-background-accent-purple border-b-contiyo-primary-purple":
                isToday,
            }
          )}
        >
          <div className="flex items-center justify-center">
            <div className="flex flex-col items-center justify-center gap-2 tablet:flex-row">
              <Typography
                className={`font-normal ${
                  isToday && "text-contiyo-primary-purple"
                }`}
              >
                {day.slice(0, 3)}
              </Typography>
              <Typography
                className={cn("font-semibold", {
                  "text-contiyo-primary-purple": isToday,
                })}
              >
                {format(currentDay, "d")}
              </Typography>
            </div>
          </div>
        </div>
      );
    });
  };

  const sortPosts = (posts: any, sortingBy: string, sort: string) => {
    return posts.sort((a: any, b: any) => {
      if (sortingBy === "name") {
        return sort === "asc"
          ? a.title.localeCompare(b.title)
          : b.title.localeCompare(a.title);
      }
      return 0;
    });
  };
  const filterPosts = (
    posts: any,
    searchTerm: string,
    visiblePlatforms: SocialPlatformsState
  ) => {
    return posts.filter(
      (post: any) =>
        (post.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
          post.name.toLowerCase().includes(searchTerm.toLowerCase())) &&
        visiblePlatforms[post.social]
    );
  };
  const renderColumns = () => {
    const filteredPosts = filterPosts(posts, searchTerm, visiblePlatforms);
    return daysOfWeek.map((_, dayIndex) => {
      const currentDay = addDays(startOfCurrentWeek, dayIndex);
      const isPast =
        isBefore(currentDay, todaysDate) && !isSameDay(currentDay, todaysDate);
      const postsFiltered = sortPosts(
        filteredPosts.filter((post: any) =>
          isSameDay(parseISO(post.date), currentDay)
        ),
        sortingBy,
        sort
      );

      return (
        <div
          key={dayIndex}
          className={cn(
            "relative flex flex-col flex-1 gap-2 p-2 bg-white border-r back border-accent last:border-r-transparent group",
            {
              "past-date-column": isPast,
            }
          )}
          onMouseEnter={() => showButtonFunc(currentDay)}
          onMouseLeave={hideButtonFunc}
        >
          {postsFiltered.map((post: any, postIndex: any) => (
            <div
              className="relative last-of-type:mb-14"
              key={`${post.date}-${post.title}-${postIndex}`}
            >
              <Sheet>
                <SheetTrigger
                  className={cn(
                    "w-full border p-2 rounded-[8px] break-all flex flex-col gap-2 z-[1]",
                    {
                      "bg-instagram border-instagram-border":
                        post.social === "instagram",
                    },
                    {
                      "bg-linkedin border-linkedin-border":
                        post.social === "linkedin",
                    }
                  )}
                >
                  <div className="flex items-center w-full gap-2">
                    <div className="relative">
                      <img
                        src={post.profilePicture}
                        alt={post.social}
                        className="relative object-cover object-center w-6 h-6 rounded-full min-w-6 min-h-6"
                      />
                      <div
                        className={cn(
                          "absolute bottom-0 right-0 w-3 h-3 border rounded-full",
                          {
                            "border-instagram": post.social === "instagram",
                          },
                          {
                            "border-linkedin": post.social === "linkedin",
                          }
                        )}
                      >
                        <img
                          src={getSocialMediaImageUrl(post.social)}
                          alt={post.name}
                          className="rounded-full"
                        />
                      </div>
                    </div>
                    {post.name && (
                      <Typography className="truncate">{post.name}</Typography>
                    )}
                  </div>
                  {post.title && (
                    <Typography className="text-left line-clamp-2">
                      {post.title}
                    </Typography>
                  )}
                  {post.postPictures.length > 0 && (
                    <ScrollArea>
                      <div className="flex gap-1 flex-nowrap">
                        {post.postPictures.map((picture: any, index: any) => (
                          <img
                            key={index}
                            src={picture}
                            alt={`Post ${index + 1}`}
                            className="object-cover rounded-md w-7 h-7"
                          />
                        ))}
                      </div>
                      {/* <ScrollBar orientation="horizontal" /> */}
                    </ScrollArea>
                  )}
                  {post.date && (
                    <div className="flex items-center gap-[4px] flex-wrap">
                      <CalendarDays
                        className={cn(
                          "w-[12px] h-[12px] text-instagram-border",
                          {
                            "text-instagram-border":
                              post.social === "instagram",
                          },
                          {
                            "text-linkedin-border": post.social === "linkedin",
                          }
                        )}
                      ></CalendarDays>

                      <Typography
                        className={cn(
                          "text-[12px] font-semibold text-instagram-border text-left break-words flex flex-wrap",
                          {
                            "text-instagram-border":
                              post.social === "instagram",
                          },
                          {
                            "text-linkedin-border": post.social === "linkedin",
                          }
                        )}
                      >
                        <span> {format(parseISO(post.date), "hh:mm")}</span>
                        <span>{format(parseISO(post.date), "a")}</span>
                      </Typography>
                    </div>
                  )}
                </SheetTrigger>
                <SheetContent>
                  <SheetHeader>
                    <SheetTitle>{post.title}</SheetTitle>
                    <SheetDescription></SheetDescription>
                  </SheetHeader>
                </SheetContent>
              </Sheet>
            </div>
          ))}
        </div>
      );
    });
  };

  const renderCreatePostButtons = () => {
    return daysOfWeek.map((day, index) => {
      const currentDay = addDays(startOfCurrentWeek, index);
      const currentDayString = format(currentDay, "yyyy-MM-dd");
      if (
        !isAfter(currentDay, todaysDate) &&
        !isSameDay(currentDay, todaysDate)
      ) {
        return <div key={index} className="w-full  h-0 !p-0 border-t-0" />;
      }
      return (
        <Sheet key={index}>
          <div
            className={`w-full p-2 bg-white border-t border-r border-accent last:border-r-transparent z-[2] ${
              hoveredColumn === currentDayString ||
              hoveredButton === currentDayString
                ? "block"
                : "h-0 !p-0 border-t-0"
            }`}
            onMouseEnter={() => setHoveredButton(currentDayString)}
            onMouseLeave={() => setHoveredButton(null)}
          >
            <SheetTrigger asChild>
              <Button
                variant="outline"
                className={`w-full px-2 laptop:px-4 ${
                  hoveredColumn === currentDayString ||
                  hoveredButton === currentDayString
                    ? "flex"
                    : "hidden"
                }`}
                onClick={() => setValue("dob", currentDay)}
              >
                <Plus className="w-4 h-4"></Plus>{" "}
                <span className="hidden laptop:block">Create post</span>
              </Button>
            </SheetTrigger>
            <SheetContent>
              <Form {...form}>
                <form
                  onSubmit={form.handleSubmit(onSubmit)}
                  className="space-y-8"
                >
                  <FormField
                    control={form.control}
                    name="dob"
                    render={({ field }) => (
                      <FormItem className="flex flex-col">
                        <FormLabel>Date</FormLabel>
                        <Popover modal>
                          <PopoverTrigger asChild>
                            <FormControl>
                              <Button
                                variant={"outline"}
                                className={cn(
                                  "w-full pl-3 text-left font-normal",
                                  !field.value && "text-muted-foreground"
                                )}
                              >
                                {field.value ? (
                                  <>{format(field.value, "PPP")}</>
                                ) : (
                                  <span>Pick a date</span>
                                )}
                                <CalendarIcon className="w-4 h-4 ml-auto opacity-50" />
                              </Button>
                            </FormControl>
                          </PopoverTrigger>
                          <PopoverContent className="w-auto p-0" align="start">
                            <Calendar
                              mode="single"
                              selected={field.value}
                              onSelect={field.onChange}
                              initialFocus
                              weekStartsOn={1}
                              disabled={(date: Date) => {
                                const today = new Date();
                                today.setHours(0, 0, 0, 0);
                                return date < today;
                              }}
                            />
                          </PopoverContent>
                        </Popover>
                        <FormMessage />
                      </FormItem>
                    )}
                  />{" "}
                  <FormField
                    control={form.control}
                    name="dob"
                    render={({ field }) => (
                      <FormItem className="flex flex-col">
                        <FormLabel>Time</FormLabel>
                        <FormControl>
                          <TimePicker label="Time" />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <div className="flex justify-end w-full">
                    <Button type="submit" variant="primary">
                      Submit
                    </Button>
                  </div>
                </form>
              </Form>
            </SheetContent>
          </div>
        </Sheet>
      );
    });
  };

  return (
    <div className="grid grid-rows-[auto_1fr_auto] overflow-hidden border border-accent rounded-md relative">
      <div className="grid grid-cols-7">{renderDaysHeader()}</div>
      <ScrollArea className="relative calendarWeekly">
        <div className="grid w-full h-full grid-cols-7">{renderColumns()}</div>
      </ScrollArea>
      <div className="absolute bottom-0 left-0 grid w-full grid-cols-7">
        {renderCreatePostButtons()}
      </div>
    </div>
  );
};
