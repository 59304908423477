import { Input } from "./Input";
import { cn } from "../../utils/cn";
import OtpInput, { OTPInputProps } from "react-otp-input";

type OtpOptions = Omit<OTPInputProps, "renderInput">;

type OtpStyledInputProps = {
  className?: string;
} & OtpOptions;

export const OtpStyledInput = ({
  className,
  ...props
}: OtpStyledInputProps) => {
  return (
    <OtpInput
      {...props}
      shouldAutoFocus={true}
      renderInput={(inputProps, index) => (
        <>
          {index === 3 && (
            <div className="justify-center hidden w-5 min-[325px]:flex">
              {" "}
              <div className="w-1 h-1 rounded-full bg-contiyo-text" />
            </div>
          )}
          <Input
            {...inputProps}
            className={cn(
              "h-auto flex-1 w-full aspect-square !appearance-none [-webkit-appearance: none] selection:bg-none bg-contiyo-background-color focus:bg-contiyo-primary-purple/5 border-transparent p-0 rounded-lg focus-visible:ring-0",
              className
            )}
            autoFocus={index === 1}
          />
        </>
      )}
      containerStyle={`flex justify-center items-center text-md mobile:text-xl font-normal gap-2 mobile:gap-3`}
    />
  );
};
