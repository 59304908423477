import { Plus, Trash2Icon, TriangleAlert } from "lucide-react";
import { toast } from "sonner";
import { Button } from "../../components/ui/Button";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "../../components/ui/DropdownMenu";
import { ScrollArea, ScrollBar } from "../../components/ui/ScrollArea";
import { Typography } from "../../components/ui/Typography";
import {
  GetGroupPostsQuery,
  PostStatus,
  SocialMediaType,
} from "../../gql/graphql";
import { cn } from "../../utils/cn";
import AlertDialogComponent from "../global/AlertDialogComponent";
import { GET_GROUP_POSTS, useDeletePost } from "./api/creatorService";
import { useCreateBlankPostForMedia } from "./createBlankPostForMedia";
import { MediaTypeIcon } from "./MediaTypeIcon";
import {
  getMediaType,
  instagramMediaTypesOrder,
  linkedInMediaTypesOrder,
} from "./mediaUtils";
import SocialMediaTabSinglePost from "./SocialMediaTabSinglePost";

export default function SocialMediaTab({
  groupId,
  activeWorkspace,
  activePostData,
  activeMediaTab,
  activeTab,
  handleSelectMediaTab,
  platform,
  dataLoading,
  setIsDataLoading,
}: any) {
  const [deletePost] = useDeletePost({
    update(cache, { data: mutationData }) {
      if (!mutationData?.deletePost) return;

      // Read the current cached data
      const existingData = cache.readQuery<GetGroupPostsQuery>({
        query: GET_GROUP_POSTS,
        variables: {
          groupId: groupId,
          workspaceId: activeWorkspace?.PK?.split("#")[1],
        },
      });

      if (!existingData) return;

      const accountId = activeMediaTab.account_data.account_id;
      const postSK = activeMediaTab.SK; // The SK of the post being deleted

      // Map over groupPosts to find the correct account group
      let updatedGroupPosts = existingData.workspace.groupPosts.map((group) => {
        if (group.account_id === accountId) {
          return {
            ...group,
            posts: group.posts.filter((post) => post.SK !== postSK),
          };
        }
        return group;
      });

      // Write the updated data back to the cache
      cache.writeQuery({
        query: GET_GROUP_POSTS,
        variables: {
          groupId: groupId,
          workspaceId: activeWorkspace?.PK?.split("#")[1],
        },
        data: {
          workspace: {
            ...existingData.workspace,
            groupPosts: updatedGroupPosts,
          },
        },
      });

      // Read the updated data from the cache immediately
      const updatedData = cache.readQuery<GetGroupPostsQuery>({
        query: GET_GROUP_POSTS,
        variables: {
          groupId: groupId,
          workspaceId: activeWorkspace?.PK?.split("#")[1],
        },
      });

      // Get the updated posts for this particular account
      const updatedAccountPosts =
        updatedData?.workspace.groupPosts.find(
          (group) => group.account_id === accountId
        )?.posts || [];

      if (updatedAccountPosts.length > 0) {
        // There are still posts left in this group
        handleSelectMediaTab(updatedAccountPosts[0]);
      } else {
        // No posts left for this account_id. Remove the group entirely.
        updatedGroupPosts = updatedData!.workspace.groupPosts.filter(
          (group) => group.account_id !== accountId
        );

        // Write the final updated data without the empty group
        cache.writeQuery({
          query: GET_GROUP_POSTS,
          variables: {
            groupId: groupId,
            workspaceId: activeWorkspace?.PK?.split("#")[1],
          },
          data: {
            workspace: {
              ...updatedData!.workspace,
              groupPosts: updatedGroupPosts,
            },
          },
        });

        handleSelectMediaTab(null);
      }
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });
  const { createBlankPostForMedia } =
    useCreateBlankPostForMedia(setIsDataLoading);
  const onSelectMediaTab = (post: any) => {
    handleSelectMediaTab(post);
  };

  const usedMediaTypes = new Set(
    activePostData.map((post: any) => getMediaType(post))
  );

  const availableMediaTypes =
    platform === SocialMediaType.Instagram
      ? instagramMediaTypesOrder.filter((type) => !usedMediaTypes.has(type))
      : linkedInMediaTypesOrder.filter((type) => !usedMediaTypes.has(type));

  return (
    <div className="flex flex-col flex-1">
      {platform !== SocialMediaType.Boilerplate ? (
        <div className="relative flex items-center gap-2">
          <ScrollArea className="flex">
            <div className="flex gap-2 w-max">
              {availableMediaTypes.length > 0 && (
                <DropdownMenu modal={false}>
                  <DropdownMenuTrigger asChild disabled={dataLoading}>
                    <Button
                      className="relative flex items-center justify-center w-8 h-8 px-2 border rounded-radius"
                      variant={"outline"}
                    >
                      <Plus className="size-4" />
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent
                    align="start"
                    avoidCollisions
                    className="h-auto"
                  >
                    <span className="uppercase text-[10px] font-semibold text-contiyo-secondary-text pl-[8px]">
                      Media type
                    </span>
                    <ScrollArea>
                      {(platform === SocialMediaType.Instagram
                        ? instagramMediaTypesOrder
                        : linkedInMediaTypesOrder
                      ).map((mediaType, index) => {
                        const isChecked = activePostData.some(
                          (post: any) => getMediaType(post) === mediaType
                        );

                        return (
                          <DropdownMenuCheckboxItem
                            key={index}
                            className="flex items-center w-full gap-1.5 text-sm data-[disabled]:cursor-not-allowed data-[disabled]:pointer-events-auto"
                            checked={isChecked}
                            onCheckedChange={async () => {
                              setIsDataLoading(true);
                              try {
                                if (isChecked) {
                                  const correspondingPost = activePostData.find(
                                    (post: any) =>
                                      getMediaType(post) === mediaType
                                  );
                                  if (correspondingPost) {
                                    onSelectMediaTab(correspondingPost);
                                  }
                                } else {
                                  const responses =
                                    await createBlankPostForMedia({
                                      PK: activeMediaTab?.PK,
                                      title: activeMediaTab?.title,
                                      slug: activeMediaTab?.slug,
                                      workspace_id:
                                        activeMediaTab?.workspace_id,
                                      scheduled_at: 0,
                                      social_media_type:
                                        platform === SocialMediaType.Instagram
                                          ? SocialMediaType.Instagram
                                          : SocialMediaType.Linkedin,
                                      status: PostStatus.Draft,
                                      account_id: activeTab.SK.split("#")[1],
                                      media_type: mediaType,
                                    });
                                  // await refetch();
                                  if (responses) {
                                    handleSelectMediaTab(
                                      responses[0].data?.savePost
                                    );
                                  }
                                }
                              } catch (error: any) {
                                toast.error(error.message);
                              } finally {
                                setIsDataLoading(false);
                              }
                            }}
                          >
                            <MediaTypeIcon mediaType={mediaType} />
                            <Typography className="leading-tight capitalize">
                              {mediaType.replace(/_/g, " ").toLowerCase()}
                            </Typography>
                          </DropdownMenuCheckboxItem>
                        );
                      })}
                    </ScrollArea>
                  </DropdownMenuContent>
                </DropdownMenu>
              )}

              {activePostData?.map((post: any, index: number) => (
                <div key={index} className="flex">
                  <Button
                    className={cn(
                      `flex items-center h-auto gap-1 p-2 rounded-radius  w-fit group`,
                      activeMediaTab?.SK === post?.SK &&
                        "rounded-r-none border-r-0"
                    )}
                    onClick={() => handleSelectMediaTab(post)}
                    variant={
                      activeMediaTab?.SK === post?.SK
                        ? "creatorActiveTab"
                        : "creatorNotActiveTab"
                    }
                    disabled={dataLoading}
                  >
                    <MediaTypeIcon mediaType={getMediaType(post)} />

                    <Typography className="font-semibold leading-tight capitalize">
                      {getMediaType(post).replace(/_/g, " ").toLowerCase()}
                    </Typography>
                  </Button>
                  {activeMediaTab?.SK === post?.SK && (
                    <AlertDialogComponent
                      title="Delete progress?"
                      icon={<TriangleAlert />}
                      description="Any unsaved changes will be lost, and you will need to start over."
                      onSubmit={async (event: any) => {
                        event.preventDefault();
                        try {
                          await deletePost({
                            variables: {
                              input: {
                                groupId: groupId,
                                postType: activeMediaTab?.social_media_type,
                                postId: activeMediaTab?.SK.split("#")[1],
                              },
                            },
                          });
                          // console.log("response", response);
                          // if (activePostData && activePostData.length > 0) {
                          //   handleSelectMediaTab(activePostData[0]);
                          // } else {
                          //   handleSelectMediaTab(null);
                          // }
                        } catch (error: any) {
                          toast.error(error.message);
                        }
                      }}
                      actionButtonVariant="destructive"
                      actionString="Delete"
                    >
                      <Button
                        className="relative w-8 h-full p-0 border-l-0 rounded-l-none group"
                        variant={"creatorActiveTab"}
                        disabled={dataLoading}
                      >
                        <div className="absolute h-1/2 w-[1px] bg-contiyo-primary-purple left-0"></div>
                        <Trash2Icon className="size-3.5"></Trash2Icon>
                      </Button>
                    </AlertDialogComponent>
                  )}
                </div>
              ))}
            </div>
            <ScrollBar orientation="horizontal" />
          </ScrollArea>
        </div>
      ) : (
        <>
          <Typography component="h3">Boilerplate</Typography>
          <Typography className="mt-1 text-contiyo-secondary-text">
            This is your ideation space. You can draft posts here, but it won't
            go live.
          </Typography>
        </>
      )}
      {/* <div className="flex flex-col gap-5">
        {activeMediaTab && (
          <div className="flex flex-col gap-2">
            <span>Active media tab:</span>
            <pre className="p-4 border border-accent rounded-radius">
              PK: {activeMediaTab.PK}
              SK: {activeMediaTab.SK}
              social_media_type: {activeMediaTab.social_media_type}
            </pre>
          </div>
        )}
        {activePostData && (
          <div className="flex flex-col gap-2">
            <span>Active post data:</span>
            <pre className="p-4 border border-accent rounded-radius">
              {JSON.stringify(activePostData, null, 2)}
            </pre>
          </div>
        )}
        {activeTab && (
          <div className="flex flex-col gap-2">
            <span>Active tab:</span>
            <pre className="p-4 border border-accent rounded-radius">
              {JSON.stringify(activeTab, null, 2)}
            </pre>
          </div>
        )}
      </div> */}
      {/* <div className="flex flex-col gap-5"> */}
      <SocialMediaTabSinglePost
        key={activeMediaTab?.SK}
        platform={platform}
        mediaType={getMediaType(activeMediaTab)}
        enableMediaUpload={
          activeMediaTab?.social_media_type !== "BOILERPLATE" &&
          activeMediaTab?.linkedin_data?.content_type !== "TEXT"
        }
        activeMediaTab={activeMediaTab}
      ></SocialMediaTabSinglePost>
      {/* </div> */}
    </div>
  );
}
