import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Button } from "../../../components/ui/Button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../components/ui/Form";
import { PasswordInput } from "../../../components/ui/PasswordInput";
import { useDeleteUser } from "../api/accountService";
import { toast } from "sonner";
import {
  DialogClose,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../components/ui/Dialog";
const deleteAccountSchema = z.object({
  password: z.string().min(2, { message: "Password is required" }),
});
export default function DeleteAccountForm() {
  const [deleteUser] = useDeleteUser();
  const formDeleteAccount = useForm<z.infer<typeof deleteAccountSchema>>({
    resolver: zodResolver(deleteAccountSchema),
    reValidateMode: "onChange",
    mode: "onSubmit",
    defaultValues: {
      password: "",
    },
    criteriaMode: "all",
  });
  const onAccountDeletionSubmit = async (
    values: z.infer<typeof deleteAccountSchema>
  ) => {
    try {
      await deleteUser({
        variables: {
          password: values.password,
        },
      });
      window.location.reload();
    } catch (error: any) {
      if (error.graphQLErrors) {
        error.graphQLErrors.forEach(async (err: any) => {
          switch (err.extensions?.code) {
            case "UNAUTHORIZED":
              formDeleteAccount.setError("password", {
                type: "manual",
                message: "Incorrect password",
              });
              break;
            default:
              formDeleteAccount.setError("password", {
                type: "manual",
                message: error.message,
              });
          }
        });
      } else {
        toast.error("Uh oh! Something went wrong", {
          description: "There was a problem with your request",
        });
      }
    }
  };
  return (
    <Form {...formDeleteAccount}>
      <form
        onSubmit={formDeleteAccount.handleSubmit(onAccountDeletionSubmit)}
        className="flex flex-col gap-6"
      >
        <DialogHeader>
          <DialogTitle className="text-xl">
            Delete your entire account permanently?
          </DialogTitle>
          <DialogDescription className="text-contiyo-secondary-text">
            This action cannot be undone. This will permanently delete your
            entire account.
          </DialogDescription>

          <FormField
            control={formDeleteAccount.control}
            name="password"
            render={({ field, formState: { errors } }) => (
              <FormItem>
                <FormLabel className="text-[12px] font-medium leading-tight">
                  Password
                </FormLabel>
                <FormControl>
                  <PasswordInput
                    placeholder="∗∗∗∗∗∗∗∗"
                    {...field}
                    className={`h-[30px] text-[14px] placeholder:text-[14px] px-2 py-1 ${
                      errors.password ? "border-red-500" : ""
                    }`}
                  />
                </FormControl>
                <FormMessage />
                <FormDescription className="!mt-1 text-[12px] text-contiyo-secondary-text">
                  Type your password to confirm deletion
                </FormDescription>
              </FormItem>
            )}
          />
        </DialogHeader>
        <DialogFooter>
          <DialogClose asChild>
            <Button type="button" variant="outline" className="h-8">
              Cancel
            </Button>
          </DialogClose>
          <Button
            type="submit"
            variant="destructive"
            className="h-8 transition-colors text-destructive bg-destructive/10 hover:bg-destructive hover:text-white"
          >
            Delete Account
          </Button>
        </DialogFooter>
      </form>
    </Form>
  );
}
