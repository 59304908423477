import { useEffect, useState } from "react";
import { Button } from "../../components/ui/Button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogDescription,
  DialogOverlay,
  DialogTitle,
} from "../../components/ui/Dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "../../components/ui/Form";
import { useAuthDialog } from "./DialogProvider";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useNavigate } from "react-router-dom";
import { OtpStyledInput } from "../../components/ui/OTPInput";
import { Typography } from "../../components/ui/Typography";
import { Loader2 } from "lucide-react";
import { PasswordInput } from "../../components/ui/PasswordInput";
import BrandLogo from "../../components/BrandLogo";
import {
  useConfirmSignUp,
  useForgotPassword,
  useResendConfirmationCode,
  useSignIn,
} from "./api/authService";
import { useAuth } from "./AuthProvider";
import * as VisuallyHidden from "@radix-ui/react-visually-hidden";
import { toast } from "sonner";

type OtpFormValues = z.infer<typeof OtpFormSchema>;
type RetypePasswordValues = z.infer<typeof RetypePasswordSchema>;

const OtpFormSchema = z.object({
  otp: z.string().min(6, {
    message: "Your one-time password must be 6 characters long",
  }),
});
const RetypePasswordSchema = z.object({
  password: z.string().min(1, {
    message: "Field is required",
  }),
});

const resetTimerCount = 90;

export default function ConfirmEmailDialog() {
  const { isOpen, email, password, closeDialog, unconfirmedUser, openDialog } =
    useAuthDialog();
  const [timer, setTimer] = useState(resetTimerCount);
  const [showResendLink, setShowResendLink] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [shouldRetypePassword, setShouldRetypePassword] = useState(false);
  const [signIn] = useSignIn();
  const [resendConfirmationCode] = useResendConfirmationCode();
  const [forgotPassword] = useForgotPassword();
  const [confirmSignUp] = useConfirmSignUp();
  const { setIsAuthenticated } = useAuth();

  const navigate = useNavigate();

  const otpForm = useForm<OtpFormValues>({
    resolver: zodResolver(OtpFormSchema),
    defaultValues: {
      otp: "",
    },
    reValidateMode: "onSubmit",
  });
  const retypePasswordForm = useForm<RetypePasswordValues>({
    resolver: zodResolver(RetypePasswordSchema),
    defaultValues: {
      password: "",
    },
    reValidateMode: "onSubmit",
  });

  const onRetypePasswordSubmit = async (data: RetypePasswordValues) => {
    setIsLoading(true);
    try {
      await signIn({
        variables: {
          input: {
            email: email,
            password: data.password,
          },
        },
      });
      setShouldRetypePassword(false);
      closeDialog();
      setIsAuthenticated(true);
      navigate("/");
    } catch (error: any) {
      if (error.graphQLErrors) {
        error.graphQLErrors.forEach(async (err: any) => {
          switch (err.extensions?.code) {
            case "UNAUTHORIZED":
              retypePasswordForm.setError("password", {
                type: "manual",
                message: "Incorrect password",
              });
              break;
            default:
              retypePasswordForm.setError("password", {
                type: "manual",
                message: error.message,
              });
          }
        });
      }
      if (error.networkError) {
        toast.error("Uh oh! Something went wrong", {
          description: "There was a problem with your request",
        });
      }
    } finally {
      setIsLoading(false);
    }
  };
  const onOtpSubmit = async (data: OtpFormValues) => {
    setIsLoading(true);
    try {
      await confirmSignUp({
        variables: {
          input: {
            email: email,
            confirmationCode: data.otp,
          },
        },
      });
      if (password) {
        await signIn({
          variables: {
            input: {
              email: email,
              password: password,
            },
          },
        });
      }
      closeDialog();
      if (!unconfirmedUser) {
        setIsAuthenticated(true);
        navigate("/");
      } else if (unconfirmedUser) {
        await forgotPassword({
          variables: {
            input: {
              email: email,
            },
          },
        });
        openDialog("forgotPasswordDialog", email);
      }
    } catch (error: any) {
      if (error.graphQLErrors) {
        error.graphQLErrors.forEach(async (err: any) => {
          switch (err.extensions?.code) {
            case "NOT_FOUND":
              otpForm.setError("otp", {
                type: "manual",
                message: error.message,
              });
              break;
            case "UNAUTHORIZED":
              navigate("/");
              setShouldRetypePassword(true);
              break;
            default:
              otpForm.setError("otp", {
                type: "manual",
                message: error.message,
              });
          }
        });
      }
    } finally {
      setIsLoading(false);
    }
  };
  const restartTimer = async () => {
    try {
      await resendConfirmationCode({
        variables: {
          input: {
            email: email,
          },
        },
      });
      setShowResendLink(false);
      setTimer(resetTimerCount);
      const intervalId = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer <= 1) {
            clearInterval(intervalId);
            setShowResendLink(true);
            return 0;
          }
          return prevTimer - 1;
        });
      }, 1000);
    } catch (error: any) {
      toast.error("Uh oh! Something went wrong", {
        description: error?.message || "There was a problem with your request",
      });
    }
  };
  useEffect(() => {
    setShowResendLink(true);
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = ""; // Required for older browsers
      return "";
    };
    const handlePopstate = (event: PopStateEvent) => {
      event.preventDefault();
      closeDialog();
      return "";
    };
    if (isOpen) {
      window.addEventListener("beforeunload", handleBeforeUnload);
      window.addEventListener("popstate", handlePopstate);
    } else {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("popstate", handlePopstate);
    }
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("popstate", handlePopstate);
    };
  }, [closeDialog, isOpen]);

  return (
    <Dialog open={isOpen}>
      <DialogOverlay className="bg-white mobile:bg-contiyo-background-color" />
      <div
        className={`hidden absolute top-0 left-0 z-[51] ${
          isOpen && "mobile:flex"
        } items-center justify-center w-full h-[100px] py-3`}
      >
        <BrandLogo />
      </div>
      <DialogContent
        className="top-0 mobile:top-[100px] border-transparent mobile:border-contiyo-light-border translate-x-[-50%] mobile:left-2/4 translate-y-0 max-w-[448px] gap-10 shadow-none py-12 px-6 mobile:px-8 mobile:py-10"
        disableClose
      >
        <DialogTitle className="hidden ">
          <VisuallyHidden.Root>Confirm email</VisuallyHidden.Root>
        </DialogTitle>
        <DialogHeader className="text-left">
          <Typography
            component="h2"
            className="!leading-[36px] font-semibold text-[28px]"
          >
            {shouldRetypePassword
              ? "Retype your password"
              : "Enter the 6-digit code we emailed you"}
          </Typography>

          <DialogDescription className="text-base text-contiyo-text">
            {!shouldRetypePassword ? (
              <>
                Please check your email
                <span className="font-bold"> {email}</span>.
                {unconfirmedUser
                  ? " Before changing password you must confirm email."
                  : " This helps us keep your account secure by verifying that it's really you."}
              </>
            ) : (
              <>
                The password you entered for
                <span className="font-bold"> {email}</span> is incorrect. Please
                retype your password to continue.
              </>
            )}
          </DialogDescription>
        </DialogHeader>
        {!shouldRetypePassword && (
          <Form {...otpForm}>
            <form
              onSubmit={otpForm.handleSubmit(onOtpSubmit)}
              className="space-y-[24px]"
            >
              <FormField
                control={otpForm.control}
                name="otp"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <OtpStyledInput
                        numInputs={6}
                        inputType="number"
                        value={field.value}
                        onChange={field.onChange}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className="flex justify-center">
                <Button
                  type="submit"
                  variant="primary"
                  className="inline-flex items-center w-full"
                  disabled={isLoading}
                >
                  {!isLoading ? (
                    "Confirm"
                  ) : (
                    <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                  )}
                </Button>
              </div>

              <div className="flex flex-wrap items-center justify-center gap-1 !mt-3 text-contiyo-text">
                Didn't receive code?{" "}
                {showResendLink ? (
                  <Button
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      restartTimer();
                    }}
                    className="font-medium text-contiyo-primary-purple"
                    variant="link"
                  >
                    Resend
                  </Button>
                ) : (
                  <span>Resend available in {timer}s</span>
                )}
              </div>
            </form>
          </Form>
        )}
        {shouldRetypePassword && (
          <Form {...retypePasswordForm}>
            <form
              onSubmit={retypePasswordForm.handleSubmit(onRetypePasswordSubmit)}
              className="space-y-[24px]"
            >
              <FormField
                control={retypePasswordForm.control}
                name="password"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <PasswordInput
                        placeholder="∗∗∗∗∗∗∗∗"
                        {...field}
                        className={`${
                          retypePasswordForm.formState.errors.password
                            ? "border-red-500"
                            : ""
                        }`}
                        onChange={(e) => {
                          field.onChange(e);
                          retypePasswordForm.clearErrors("password");
                        }}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className="flex justify-center">
                <Button
                  type="submit"
                  variant="primary"
                  className="inline-flex items-center w-full"
                  disabled={isLoading}
                >
                  {!isLoading ? (
                    "Confirm"
                  ) : (
                    <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                  )}
                </Button>
              </div>
            </form>
          </Form>
        )}
      </DialogContent>
    </Dialog>
  );
}
