import {
  InstagramMediaType,
  LinkedInContentType,
  Post,
  SocialMediaType,
} from "../../gql/graphql";

export const maxFileSizeMB = 100;

export const mediaRules = {
  BOILERPLATE: {
    BOILERPLATE: {
      accept: {},
      maxCaptionLength: 0,
      maxFiles: 0,
      multiple: false,
    },
  },
  INSTAGRAM: {
    CAROUSEL: {
      accept: {
        "image/jpeg": [],
        "image/png": [],
        "video/mp4": [],
        "video/quicktime": [], // For .mov files
      },
      maxCaptionLength: 2200,
      maxFiles: 10,
      multiple: true,
    },
    IMAGE: {
      accept: {
        "image/jpeg": [],
        "image/png": [],
      },
      maxCaptionLength: 2200,
      maxFiles: 1,
      multiple: false,
    },
    REELS: {
      accept: {
        "video/mp4": [],
        "video/quicktime": [], // For .mov files
      },
      maxCaptionLength: 2200,
      maxFiles: 1,
      multiple: false,
    },
    STORIES: {
      accept: {
        "image/jpeg": [],
        "image/png": [],
        "video/mp4": [],
      },
      maxCaptionLength: 0,
      maxFiles: 1,
      multiple: false,
    },
  },
  LINKEDIN: {
    DOCUMENT: {
      accept: {
        "application/pdf": [],
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
          [], // .docx
        "application/vnd.openxmlformats-officedocument.presentationml.presentation":
          [], // .pptx
      },
      maxCaptionLength: 3000,
      maxFiles: 1,
      multiple: false,
    },
    IMAGE: {
      accept: {
        "image/jpeg": [],
        "image/png": [],
      },
      maxCaptionLength: 3000,
      maxFiles: 9,
      multiple: true,
    },
    VIDEO: {
      accept: {
        "video/mp4": [],
        "video/quicktime": [], // For .mov files
      },
      maxCaptionLength: 3000,
      maxFiles: 1,
      multiple: false,
    },
    TEXT: {
      accept: {},
      maxCaptionLength: 3000,
      maxFiles: 0,
      multiple: false,
    },
  },
};

export type MediaType =
  | InstagramMediaType
  | LinkedInContentType
  | "BOILERPLATE";

export const instagramMediaTypesOrder: MediaType[] = [
  InstagramMediaType.Image,
  InstagramMediaType.Carousel,
  InstagramMediaType.Stories,
  InstagramMediaType.Reels,
];

export const linkedInMediaTypesOrder: MediaType[] = [
  LinkedInContentType.Text,
  LinkedInContentType.Image,
  LinkedInContentType.Video,
  LinkedInContentType.Document,
];

export const getMediaTypesOrder = (
  socialMediaType: SocialMediaType
): MediaType[] => {
  switch (socialMediaType) {
    case SocialMediaType.Instagram:
      return instagramMediaTypesOrder;
    case SocialMediaType.Linkedin:
      return linkedInMediaTypesOrder;
    default:
      return [];
  }
};

export const getMediaType = (post: Post): MediaType => {
  if (
    post?.social_media_type === SocialMediaType.Instagram &&
    post?.instagram_data
  ) {
    return post.instagram_data.media_type as MediaType;
  } else if (
    post?.social_media_type === SocialMediaType.Linkedin &&
    post?.linkedin_data
  ) {
    return post.linkedin_data.content_type as MediaType;
  }
  return "BOILERPLATE";
};

export const sortPostsByMediaTypeOrder = (
  posts: Post[],
  socialMediaType: SocialMediaType
): any[] => {
  const mediaTypesOrder = getMediaTypesOrder(socialMediaType);
  return [...posts].sort((a, b) => {
    const typeA = getMediaType(a);
    const typeB = getMediaType(b);
    const indexA = mediaTypesOrder.indexOf(typeA);
    const indexB = mediaTypesOrder.indexOf(typeB);
    return indexA - indexB;
  });
};
