import { createContext, useState, useContext, ReactNode } from "react";

interface DialogContextType {
  isOpen: boolean;
  openDialog: (
    type: string,
    email: string,
    password?: string,
    unconfirmedUser?: boolean
  ) => void;
  closeDialog: () => void;
  email: string;
  password?: string;
  dialogType?: string;
  unconfirmedUser?: boolean;
}

const DialogContext = createContext<DialogContextType | undefined>(undefined);

export const AuthDialogProvider = ({ children }: { children: ReactNode }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [dialogType, setDialogType] = useState<string | undefined>(undefined);
  const [unconfirmedUser, setUnconfirmedUser] = useState(false);

  const openDialog = (
    type: string,
    email: string,
    password?: string,
    unconfirmedUser?: boolean
  ) => {
    setDialogType(type);
    setEmail(email);
    if (password) setPassword(password);
    if (unconfirmedUser) setUnconfirmedUser(unconfirmedUser);
    setIsOpen(true);
  };

  const closeDialog = () => {
    setIsOpen(false);
    setDialogType(undefined);
    setEmail("");
    setPassword("");
    setUnconfirmedUser(false);
  };

  return (
    <DialogContext.Provider
      value={{
        isOpen,
        email,
        password,
        dialogType,
        openDialog,
        closeDialog,
        unconfirmedUser,
      }}
    >
      {children}
    </DialogContext.Provider>
  );
};

export const useAuthDialog = (): DialogContextType => {
  const context = useContext(DialogContext);
  if (!context) {
    throw new Error("useDialog must be used within a DialogProvider");
  }
  return context;
};
