import { useAuth } from "../../auth/AuthProvider";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Button } from "../../../components/ui/Button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../components/ui/Form";
import { Input } from "../../../components/ui/Input";
import { Loader2 } from "lucide-react";
import { useConfirmEmailChange } from "../api/accountService";
import { useGetUserLazy } from "../../auth/api/authService";
import { toast } from "sonner";

const OtpFormSchema = z.object({
  otp: z.string().min(6, {
    message: "Your one-time password must be 6 characters long",
  }),
});
export default function ConfirmEmailChangeForm({
  setEmailCodeSent,
  setSettingPreview,
}: any) {
  const { setLoggedUser } = useAuth();
  const [getUser] = useGetUserLazy();
  const otpForm = useForm<z.infer<typeof OtpFormSchema>>({
    resolver: zodResolver(OtpFormSchema),
    defaultValues: {
      otp: "",
    },
    reValidateMode: "onSubmit",
  });
  const [confirmChangeEmail, { loading }] = useConfirmEmailChange();

  const onOtpSubmit = async (values: z.infer<typeof OtpFormSchema>) => {
    try {
      await confirmChangeEmail({ variables: { confirmationCode: values.otp } });
      const res = await getUser();
      if (res.data && res.data.user) {
        setLoggedUser(res.data.user);
        toast.success("Email changed successfully");
        setEmailCodeSent(false);
        setSettingPreview("my-account");
      }
    } catch (err: any) {
      toast.error("Uh oh! Something went wrong", {
        description: err.message || "There was a problem with your request",
      });
    }
  };
  return (
    <Form {...otpForm}>
      <form
        onSubmit={otpForm.handleSubmit(onOtpSubmit)}
        className="space-y-[24px]"
      >
        <FormField
          control={otpForm.control}
          name="otp"
          render={({ field, formState: { errors } }) => (
            <FormItem>
              <FormLabel className="text-[12px] font-medium leading-tight">
                One time password
              </FormLabel>
              <FormControl>
                <Input
                  placeholder="∗∗∗∗∗∗"
                  {...field}
                  className={`h-[30px] text-[14px] placeholder:text-[14px] px-2 py-1 ${
                    errors.otp ? "border-red-500" : ""
                  }`}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="flex justify-center">
          <Button
            type="submit"
            variant="primary"
            className="inline-flex items-center w-full"
            disabled={loading}
          >
            {!loading ? (
              "Confirm"
            ) : (
              <Loader2 className="w-4 h-4 mr-2 animate-spin" />
            )}
          </Button>
        </div>
      </form>
    </Form>
  );
}
