import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./modules/home/Home";
import Login from "./modules/auth/Login";
import Register from "./modules/auth/Register";
import ForgotPassword from "./modules/auth/ForgotPassword";
import ConfirmEmailDialog from "./modules/auth/ConfirmEmailDialog";
import { useAuthDialog } from "./modules/auth/DialogProvider";
import ForgotPasswordDialog from "./modules/auth/ForgotPasswordDialog";
import { PrivateRoutes, PublicRoutes } from "./modules/auth/Routes";
import { useWorkspace } from "./modules/workspace/WorkspaceProvider";
import {
  RedirectToHome,
  RedirectFromNotFound,
} from "./modules/home/RedirectToHome";
import Onboarding from "./modules/workspace/Onboarding";
import SettingsDialog from "./modules/settings/SettingsDialog";
import { useSettingsProvider } from "./modules/settings/SettingsProvider";
import CalendarPage from "./modules/calendar/CalendarPage";
import SocialsPage from "./modules/social/SocialsPage";
import InstagramVerifyCode from "./modules/social/InstagramVerifyCode";
import LinkedinVerifyCode from "./modules/social/LinkedinVerifyCode";
import { useCreator } from "./modules/creator/CreatorContext";
import CreatePostModal from "./modules/creator/CreatePostModal";
import Posts from "./modules/posts/Posts";
import CreatorPage from "./modules/creator/CreatorPage";
import CreatorPage2 from "./modules/creator/CreatorPage2";
import AddAccountDialog from "./modules/social/AddAccountDialog";
import { useSocial } from "./modules/social/SocialProvider";

function App() {
  const { isOpen, dialogType } = useAuthDialog();
  const { isSettingsOpen, closeDialog } = useSettingsProvider();
  const { showAddAccountDialog } = useSocial();
  const { isOnboarding } = useWorkspace();
  const { isCreatorModalOpen } = useCreator();

  return (
    <Router>
      <Routes>
        <Route path="/home" element={<Home />} />
        <Route element={<PrivateRoutes />}>
          <Route path="/" element={<RedirectToHome />} />
          <Route path="*" element={<RedirectFromNotFound />} />
          <Route index path="/:workspaceSlug" element={<Home />} />
          <Route path="/:workspaceSlug/posts" element={<Posts />} />
          <Route
            path="/:workspaceSlug/creator/:groupId"
            element={<CreatorPage />}
          />
          <Route
            path="/:workspaceSlug/creator2/:postSlug"
            element={<CreatorPage2 />}
          />
          <Route path="/:workspaceSlug/calendar" element={<CalendarPage />} />
          <Route path="/:workspaceSlug/socials" element={<SocialsPage />} />
          <Route path="/api/auth/instagram" element={<InstagramVerifyCode />} />
          <Route path="/api/auth/linkedin" element={<LinkedinVerifyCode />} />
        </Route>
        <Route element={<PublicRoutes />}>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
        </Route>
      </Routes>
      {isOpen && dialogType === "confirmEmailDialog" && <ConfirmEmailDialog />}
      {isOpen && dialogType === "forgotPasswordDialog" && (
        <ForgotPasswordDialog />
      )}
      {isOnboarding && <Onboarding />}
      {isSettingsOpen && (
        <SettingsDialog open={isSettingsOpen} handleClose={closeDialog} />
      )}
      {isCreatorModalOpen && <CreatePostModal />}
      {showAddAccountDialog && <AddAccountDialog />}
    </Router>
  );
}

export default App;
