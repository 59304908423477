import { TabTitle } from "../../utils/tabTitle";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { Button } from "../../components/ui/Button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../components/ui/Form";
import { Input } from "../../components/ui/Input";

import { emailField } from "./formFields";
import FormLayout from "./FormLayout";
import { useNavigate } from "react-router-dom";
import { useAuthDialog } from "./DialogProvider";
import {
  useForgotPassword,
  useResendConfirmationCode,
} from "./api/authService";
import { toast } from "sonner";

type EmailFormValues = z.infer<typeof EmailFormSchema>;

const EmailFormSchema = z.object({
  email: emailField,
});

export default function ForgotPassword() {
  TabTitle("Forgot Password");

  const { openDialog } = useAuthDialog();
  const navigate = useNavigate();

  const emailForm = useForm<EmailFormValues>({
    resolver: zodResolver(EmailFormSchema),
    defaultValues: {
      email: "",
    },
  });

  const { setError } = emailForm;

  const [resendConfirmationCode] = useResendConfirmationCode();
  const [forgotPassword] = useForgotPassword();

  const onEmailSubmit = async (data: EmailFormValues) => {
    try {
      await forgotPassword({
        variables: {
          input: {
            email: data.email,
          },
        },
      });
      openDialog("forgotPasswordDialog", data.email);
    } catch (error: any) {
      if (error.graphQLErrors) {
        error.graphQLErrors.forEach(async (err: any) => {
          switch (err.extensions?.code) {
            case "BAD_REQUEST":
              await resendConfirmationCode({
                variables: {
                  input: {
                    email: data.email,
                  },
                },
              });
              openDialog("confirmEmailDialog", data.email, undefined, true);
              break;
            case "NOT_FOUND":
              openDialog("forgotPasswordDialog", data.email);
              break;
            default:
              setError("email", {
                type: "manual",
                message: error.message,
              });
          }
        });
      }
      if (error.networkError) {
        toast.error("Uh oh! Something went wrong", {
          description: "There was a problem with your request",
        });
      }
    }
  };

  return (
    <main>
      <FormLayout
        heading="Forgot password"
        subHeading="Log into access your account and explore a world of endless
            possibilities."
        onBackClick={() => navigate("/login")}
      >
        <Form {...emailForm}>
          <form
            onSubmit={emailForm.handleSubmit(onEmailSubmit)}
            className="space-y-[24px]"
          >
            <FormField
              control={emailForm.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email address</FormLabel>
                  <FormControl>
                    <Input placeholder="example@example.com" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="flex justify-center">
              <Button
                type="submit"
                variant="primary"
                className="inline-flex items-center w-full"
              >
                Send
              </Button>
            </div>
          </form>
        </Form>
      </FormLayout>
    </main>
  );
}
