import * as React from "react";

import { LucideIcon } from "lucide-react";
import { cn } from "../../utils/cn";
import { Button } from "./Button";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  startIcon?: LucideIcon;
  endIcon?: LucideIcon;
  clearValue: () => void;
}

const SearchInput = React.forwardRef<HTMLInputElement, InputProps>(
  (
    { className, type, startIcon, endIcon, value, clearValue, ...props },
    ref
  ) => {
    const StartIcon = startIcon;
    const EndIcon = endIcon;

    return (
      <div className="relative w-fit">
        {StartIcon && (
          <div className="absolute left-1.5 top-1/2 transform -translate-y-1/2 pointer-events-none ">
            <StartIcon className="text-muted-foreground size-4" />
          </div>
        )}
        <input
          type={type}
          className={cn(
            "flex h-8 w-full py-2 px-4 text-sm placeholder:text-contiyo-secondary-text disabled:cursor-not-allowed disabled:opacity-50 focus-visible:outline-none",
            startIcon ? "pl-7" : "",
            endIcon ? "pr-[22px]" : "",
            className
          )}
          ref={ref}
          value={value}
          {...props}
        />
        {EndIcon && value && (
          <>
            <Button
              variant="ghost"
              className="absolute right-0 flex items-center justify-center w-auto h-auto p-1 transform -translate-y-1/2 rounded-full shrink-0 top-1/2"
              onClick={clearValue}
            >
              <EndIcon className="flex-1 size-3"></EndIcon>
            </Button>
          </>
        )}
      </div>
    );
  }
);
SearchInput.displayName = "SearchInput";

export { SearchInput };
