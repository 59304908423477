import {
  addDays,
  startOfWeek,
  subDays,
  format,
  startOfMonth,
  subMonths,
  addMonths,
} from "date-fns";
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";

interface SocialPlatformsState {
  [key: string]: boolean;
}

interface CalendarContextProps {
  sort: string;
  setSort: React.Dispatch<React.SetStateAction<string>>;
  sortingBy: string;
  setSortingBy: React.Dispatch<React.SetStateAction<string>>;
  calendarView: string;
  setCalendarView: React.Dispatch<React.SetStateAction<string>>;
  visiblePlatforms: SocialPlatformsState;
  setVisiblePlatforms: React.Dispatch<
    React.SetStateAction<SocialPlatformsState>
  >;
  todaysDate: Date;
  handlePlatformChange: (platform: string | "all") => void;
  currentDate: Date;
  setCurrentDate: React.Dispatch<React.SetStateAction<Date>>;
  handleToday: () => void;
  handlePreviousWeek: () => void;
  handleNextWeek: () => void;
  handlePreviousMonth: () => void;
  handleNextMonth: () => void;
  getFormattedCurrentWeek: () => string;
  getFormattedCurrentMonth: () => string;
}
const CalendarContext = createContext<CalendarContextProps | undefined>(
  undefined
);

export const CalendarProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  //SORTING AND FILTERS
  const [sort, setSort] = useState<string>("asc");
  const [sortingBy, setSortingBy] = useState<string>("default");
  const [calendarView, setCalendarView] = useState<string>("weekly");
  const [visiblePlatforms, setVisiblePlatforms] =
    useState<SocialPlatformsState>({
      instagram: true,
      linkedin: true,
    });
  useEffect(() => {
    if (Object.values(visiblePlatforms).every((v) => !v)) {
      setVisiblePlatforms(
        Object.keys(visiblePlatforms).reduce((acc, key) => {
          acc[key] = true;
          return acc;
        }, {} as SocialPlatformsState)
      );
    }
  }, [visiblePlatforms]);

  useEffect(() => {
    if (sortingBy === "default") {
      setSort("asc");
    }
  }, [sortingBy]);

  const handlePlatformChange = (platform: string | "all") => {
    if (platform === "all") {
      const allChecked = !Object.values(visiblePlatforms).every((v) => v);
      const newState = Object.keys(visiblePlatforms).reduce((acc, key) => {
        acc[key] = allChecked;
        return acc;
      }, {} as SocialPlatformsState);
      setVisiblePlatforms(newState);
    } else {
      setVisiblePlatforms((prev) => {
        const newState = { ...prev };

        if (Object.values(prev).every((v) => v)) {
          Object.keys(newState).forEach((key) => {
            newState[key] = false;
          });
          newState[platform] = true;
        } else {
          newState[platform] = !prev[platform];

          if (Object.values(newState).every((v) => v)) {
            Object.keys(newState).forEach((key) => {
              newState[key] = true;
            });
          }
        }

        return newState;
      });
    }
  };

  //CALENDAR
  const todaysDate = new Date();
  const [currentDate, setCurrentDate] = useState(todaysDate);
  const handleToday = () => {
    setCurrentDate(todaysDate);
  };
  const handlePreviousWeek = () => {
    setCurrentDate(subDays(currentDate, 7));
  };

  const handleNextWeek = () => {
    setCurrentDate(addDays(currentDate, 7));
  };
  const handlePreviousMonth = () => {
    setCurrentDate(subMonths(currentDate, 1));
  };

  const handleNextMonth = () => {
    setCurrentDate(addMonths(currentDate, 1));
  };
  const getFormattedCurrentWeek = () => {
    const startOfCurrentWeek = startOfWeek(currentDate, { weekStartsOn: 1 });
    const endOfCurrentWeek = addDays(startOfCurrentWeek, 6);
    const formattedStart = format(startOfCurrentWeek, "dd MMM");
    const formattedEnd = format(endOfCurrentWeek, "dd MMM yyyy");
    return `${formattedStart} - ${formattedEnd}`;
  };
  const getFormattedCurrentMonth = () => {
    const startOfCurrentMonth = startOfMonth(currentDate);
    const formattedMonthYear = format(startOfCurrentMonth, "MMMM yyyy");
    return formattedMonthYear;
  };

  return (
    <CalendarContext.Provider
      value={{
        sort,
        setSort,
        sortingBy,
        setSortingBy,
        calendarView,
        setCalendarView,
        visiblePlatforms,
        setVisiblePlatforms,
        handlePlatformChange,
        currentDate,
        setCurrentDate,
        todaysDate,
        handleToday,
        handlePreviousWeek,
        handleNextWeek,
        handlePreviousMonth,
        handleNextMonth,
        getFormattedCurrentWeek,
        getFormattedCurrentMonth,
      }}
    >
      {children}
    </CalendarContext.Provider>
  );
};

export const useCalendarContext = (): CalendarContextProps => {
  const context = useContext(CalendarContext);
  if (!context) {
    throw new Error(
      "useCalendarContext must be used within a CalendarProvider"
    );
  }
  return context;
};
