import { InfoIcon, Unlink } from "lucide-react";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "../../components/ui/Avatar";
import { Typography } from "../../components/ui/Typography";
import { getInitials } from "../../utils/getInitials";
import AvatarWithPicture from "./AvatarWithPicture";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  // AlertDialogOverlay,
  AlertDialogTitle,
} from "../../components/ui/AlertDialog";
import { toast } from "sonner";
import { useRemoveSocialMedia } from "./api/socialService";

interface ConfirmDeleteProps {
  account: any;
  setAccount: any;
  workspace: any;
  refetchData: () => void;
  open: any;
  onOpenChange: any;
}

export default function ConfirmDelete({
  account,
  setAccount,
  workspace,
  refetchData,
  open,
  onOpenChange,
}: ConfirmDeleteProps) {
  const [removeSocialMedia] = useRemoveSocialMedia();

  const removeSocialMediaAccount = async () => {
    try {
      await removeSocialMedia({
        variables: {
          input: {
            socialMediaUserId: account.SK.split("#")[1],
            workspaceId: workspace.PK.split("#")[1],
          },
        },
      });

      refetchData();
      toast.success("Successfully deleted account from workspace");
    } catch (error: any) {
      toast.error(error.message);
    }
  };
  return (
    <AlertDialog open={open} onOpenChange={onOpenChange}>
      <AlertDialogContent
        className="max-w-sm overflow-auto max-h-[calc(100vh_-_20px)]"
        aria-modal
      >
        <AlertDialogHeader>
          <AlertDialogTitle className="text-center">
            Are you sure you want to unlink?
          </AlertDialogTitle>
          <AlertDialogDescription className="text-center">
            Any scheduled post will be lost, and you will need to start over
          </AlertDialogDescription>
        </AlertDialogHeader>
        <div className="mb-4 space-y-4">
          <div className="flex items-center justify-center">
            <div className="flex justify-center">
              <div className="flex flex-col items-center flex-1 gap-2">
                <AvatarWithPicture
                  className="size-16"
                  profile={account}
                  socialClassName="size-8 border-2"
                ></AvatarWithPicture>
                <Typography className="text-center">
                  {account.username}
                </Typography>
              </div>
              <div className="flex items-center justify-center w-8 h-16">
                <div className="h-[1px] w-full -mb-[1px] border-b-2 border-dashed border-accent"></div>
              </div>

              <div className="flex items-center justify-center size-16 ">
                <div className="flex items-center justify-center rounded-full size-10 bg-destructive/10 ">
                  <Unlink className="text-destructive size-4"></Unlink>
                </div>
              </div>

              <div className="flex items-center justify-center w-8 h-16">
                <div className="h-[1px] w-full -mb-[1px] border-b-2 border-dashed border-accent"></div>
              </div>
              <div className="flex flex-col items-center flex-1 gap-2">
                <Avatar className="rounded size-16">
                  <AvatarImage
                    src={workspace.img_url}
                    className={`object-cover object-center rounded-radius `}
                  />
                  <AvatarFallback className="break-words rounded-radius">
                    {workspace.name && getInitials(workspace.name)}
                  </AvatarFallback>
                </Avatar>
                <Typography className="text-center">
                  {workspace.name}
                </Typography>
              </div>
            </div>
          </div>
          <div className="p-3 border border-accent rounded-radius">
            <div className="text-[12px] text-contiyo-secondary-text leading-4 text-left">
              <div className="flex items-center gap-1 mb-2">
                <InfoIcon className="size-3"></InfoIcon>
                Note
              </div>
              This action is only deleting social media account from this
              workspace. If you want to unauthorize <b>Contiyo</b> you need to
              do it{" "}
              <a
                href="https://www.facebook.com/settings/?tab=business_tools"
                target="_blank"
                rel="noopener noreferrer"
                className="text-contiyo-primary-purple"
              >
                here
              </a>
              . Keep in mind that unauthorizing app in settings will delete your
              account from every workspace.
            </div>
          </div>
        </div>
        <AlertDialogFooter>
          <AlertDialogCancel onClick={() => setAccount(null)}>
            Cancel
          </AlertDialogCancel>
          <AlertDialogAction
            onClick={() => {
              removeSocialMediaAccount();
              setAccount(null);
            }}
            variant="destructive"
          >
            Remove
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
