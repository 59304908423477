import { Check } from "lucide-react";
import { specialErrors } from "./errorMessages";
import { HTMLAttributes } from "react";
import { cn } from "../../utils/cn";

interface PasswordRequirementsTooltipProps
  extends HTMLAttributes<HTMLDivElement> {
  isPasswordFocused: boolean;
  errors: any;
  submitCount: number;
}

export function PasswordRequirementsTooltip({
  isPasswordFocused,
  errors,
  submitCount,
  className,
}: PasswordRequirementsTooltipProps) {
  const isError = (message: any) => {
    const errorTypes = errors?.password?.types?.invalid_string;
    return Array.isArray(errorTypes)
      ? errorTypes.includes(message)
      : errorTypes === message;
  };

  return (
    <>
      {isPasswordFocused && errors.password && submitCount && (
        <div
          className={cn(
            "absolute left-0 z-50 px-4 py-4 mt-3 bg-white rounded-lg tablet:py-5 tablet:mt-0 tablet:ml-4 tablet:-translate-y-1/2 password-shadow tablet:left-full tablet:top-1/2 w-fit animate fade-in-0 zoom-in-95 whitespace-nowrap password-triangle",
            className
          )}
        >
          <p
            className={`mb-2 text-[13px] mobile:text-sm font-medium leading-4 text-contiyo-text peer-disabled:cursor-not-allowed peer-disabled:opacity-70`}
          >
            Password requirements:
          </p>
          <ul className="text-[13px] mobile:text-sm !mt-1">
            {specialErrors.map((message) => (
              <li key={message} className="flex items-center gap-2 mb-[1px]">
                <div>
                  {isError(message) ? (
                    <div className="w-[16px] h-[16px] flex items-center justify-center">
                      <div className="w-[5px] h-[5px] bg-contiyo-primary-purple rounded-full"></div>
                    </div>
                  ) : (
                    <div className="p-[2px] rounded-full bg-contiyo-primary-purple/10">
                      <Check
                        size={12}
                        className="stroke-contiyo-primary-purple"
                      />
                    </div>
                  )}
                </div>
                <span
                  className={`${
                    !isError(message) && "text-contiyo-text/50 line-through"
                  }`}
                >
                  {message}
                </span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
}
