import { gql, MutationHookOptions, useMutation } from "@apollo/client";
import {
  DeleteGroupPostsMutation,
  DeleteGroupPostsMutationVariables,
  DeletePostMutationMutation,
  DeletePostMutationMutationVariables,
  SavePostMutation,
  SavePostMutationVariables,
  UpdateGroupPostTitleMutation,
  UpdateGroupPostTitleMutationVariables,
  UploadPostMediaMutation,
  UploadPostMediaMutationVariables,
} from "../../../gql/graphql";

// const AI_CHANGE_TONE = gql`
//   mutation AiChangeTone($input: AiChangeToneInput!) {
//     aiChangeTone(input: $input)
//   }
// `;

// const AI_CONTINUE_WRITING = gql`
//   mutation AiContinueWriting($input: AiActionsInput!) {
//     aiContinueWriting(input: $input)
//   }
// `;

// const AI_HASHTAG_GENERATOR = gql`
//   mutation AiHashtagGenerator($input: AiActionsInput!) {
//     aiHashtagGenerator(input: $input)
//   }
// `;

// const AI_HELPER = gql`
//   mutation AiHelper($input: AiActionsInput!) {
//     aiHelper(input: $input)
//   }
// `;

const UPLOAD_MEDIA_MUTATION = gql`
  mutation UploadPostMedia($input: UploadPostMediaInput!) {
    uploadPostMedia(input: $input) {
      PK
    }
  }
`;

const CREATE_BLANK_POST = gql`
  mutation SavePost($input: SavePostInput!) {
    savePost(input: $input) {
      PK
      SK
      title
      media_id
      permalink
      workspace_id
      user_id
      created_at
      updated_at
      scheduled_at
      status
      social_media_type
      slug
      column_id
      account_data {
        account_id
        image_url
        username
      }
      boilerplate_data {
        content
      }
      instagram_data {
        media_type
        caption
        container_id
        image_data {
          url
        }
        reels_data {
          url
          thumb_offset
          cover_url
          share_to_feed
        }
        stories_data {
          stories_type
          url
        }
        carousel_data {
          carousel_type
          url
          user_tags {
            username
            x
            y
          }
          collaborators
        }
        user_tags {
          username
          x
          y
        }
        collaborators
        location {
          id
          name
          city
          country
          latitude
          longitude
          state
          street
          zip
        }
      }
      linkedin_data {
        ad_context {
          dsc_ad_account
          dsc_ad_type
          dsc_name
          dsc_status
          is_dsc
        }
        commentary
        container
        content_type
        image_content {
          alt_text
          url
        }
        video_content {
          title
          captions_url
          thumbnail_url
          url
        }
        document_content {
          title
          url
        }
        content_landing_page
        content_call_to_action_label
        distribution {
          feed_distribution
          target_entities
          third_party_distribution_channels
        }
        is_reshare_disabled
        reshare_context {
          parent
        }
        visibility
      }
    }
  }
`;

const UPDATE_GROUP_POST_TITLE = gql`
  mutation UpdateGroupPostTitle($input: UpdateGroupPostTitleInput!) {
    updateGroupPostTitle(input: $input) {
      title
      slug
    }
  }
`;
const DELETE_POST = gql`
  mutation deletePostMutation($input: DeletePostInput!) {
    deletePost(input: $input) {
      message
    }
  }
`;

const DELETE_GROUP_POST = gql`
  mutation DeleteGroupPosts($input: DeleteGroupPostsInput!) {
    deleteGroupPosts(input: $input) {
      message
    }
  }
`;

export const GET_SINGLE_POST = gql`
  query Post($groupId: ID!, $postType: SocialMediaType!, $postId: String!) {
    post(groupId: $groupId, postType: $postType, postId: $postId) {
      PK
      SK
      title
      media_id
      permalink
      workspace_id
      user_id
      created_at
      updated_at
      scheduled_at
      status
      social_media_type
      slug
      column_id
      account_data {
        account_id
        image_url
        username
      }
      boilerplate_data {
        content
      }
      instagram_data {
        media_type
        caption
        container_id
        image_data {
          url
        }
        reels_data {
          url
          thumb_offset
          cover_url
          share_to_feed
        }
        stories_data {
          stories_type
          url
        }
        carousel_data {
          carousel_type
          url
          user_tags {
            username
            x
            y
          }
          collaborators
        }
        user_tags {
          username
          x
          y
        }
        collaborators
        location {
          id
          name
          city
          country
          latitude
          longitude
          state
          street
          zip
        }
      }
      linkedin_data {
        ad_context {
          dsc_ad_account
          dsc_ad_type
          dsc_name
          dsc_status
          is_dsc
        }
        commentary
        container
        content_type
        image_content {
          alt_text
          url
        }
        video_content {
          title
          captions_url
          thumbnail_url
          url
        }
        document_content {
          title
          url
        }
        content_landing_page
        content_call_to_action_label
        distribution {
          feed_distribution
          target_entities
          third_party_distribution_channels
        }
        is_reshare_disabled
        reshare_context {
          parent
        }
        visibility
      }
    }
  }
`;

export const GET_GROUP_POSTS = gql`
  query GetGroupPosts($workspaceId: ID!, $groupId: String!) {
    workspace(workspaceId: $workspaceId) {
      groupPosts(groupId: $groupId) {
        account_id
        posts {
          PK
          SK
          title
          media_id
          permalink
          workspace_id
          user_id
          created_at
          updated_at
          scheduled_at
          status
          social_media_type
          slug
          column_id
          account_data {
            account_id
            image_url
            username
          }
          boilerplate_data {
            content
          }
          instagram_data {
            media_type
            caption
            container_id
            image_data {
              url
            }
            reels_data {
              url
              thumb_offset
              cover_url
              share_to_feed
            }
            stories_data {
              stories_type
              url
            }
            carousel_data {
              carousel_type
              url
              user_tags {
                username
                x
                y
              }
              collaborators
            }
            user_tags {
              username
              x
              y
            }
            collaborators
            location {
              id
              name
              city
              country
              latitude
              longitude
              state
              street
              zip
            }
          }
          linkedin_data {
            ad_context {
              dsc_ad_account
              dsc_ad_type
              dsc_name
              dsc_status
              is_dsc
            }
            commentary
            container
            content_type
            image_content {
              alt_text
              url
            }
            video_content {
              title
              captions_url
              thumbnail_url
              url
            }
            document_content {
              title
              url
            }
            content_landing_page
            content_call_to_action_label
            distribution {
              feed_distribution
              target_entities
              third_party_distribution_channels
            }
            is_reshare_disabled
            reshare_context {
              parent
            }
            visibility
          }
        }
      }
    }
  }
`;

// export const useCreateBlankPost = () => {
//   return useMutation<>(CREATE_BLANK_POST);
// };
export const useCreatePost = (
  options?: MutationHookOptions<SavePostMutation, SavePostMutationVariables>
) => {
  return useMutation<SavePostMutation, SavePostMutationVariables>(
    CREATE_BLANK_POST,
    options
  );
};

export const useUpdateGroupPostTitle = () => {
  return useMutation<
    UpdateGroupPostTitleMutation,
    UpdateGroupPostTitleMutationVariables
  >(UPDATE_GROUP_POST_TITLE);
};

export const useDeleteGroupPost = (
  options?: MutationHookOptions<
    DeleteGroupPostsMutation,
    DeleteGroupPostsMutationVariables
  >
) => {
  return useMutation<
    DeleteGroupPostsMutation,
    DeleteGroupPostsMutationVariables
  >(DELETE_GROUP_POST, options);
};

export const useDeletePost = (
  options?: MutationHookOptions<
    DeletePostMutationMutation,
    DeletePostMutationMutationVariables
  >
) => {
  return useMutation<
    DeletePostMutationMutation,
    DeletePostMutationMutationVariables
  >(DELETE_POST, options);
};

export const useUploadMedia = () => {
  return useMutation<UploadPostMediaMutation, UploadPostMediaMutationVariables>(
    UPLOAD_MEDIA_MUTATION
  );
};
