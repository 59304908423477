import { ApolloClient, InMemoryCache, ApolloLink } from "@apollo/client";
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";
import { onError } from "@apollo/client/link/error";
import { toast } from "sonner";

export const createApolloClient = () => {
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      for (const err of graphQLErrors) {
        if (err.extensions?.code === "UNAUTHENTICATED") {
          toast.warning("Session expired.");
          return;
        }
      }
    }

    if (networkError) {
      toast.error("Uh oh! Something went wrong on server", {
        description: "There was a problem with your request",
      });
    }
  });

  const uploadLink = createUploadLink({
    uri: process.env.REACT_APP_BACKEND_URI,
    headers: { "Apollo-Require-Preflight": "true" },
    credentials: "include",
  });

  const link = ApolloLink.from([errorLink, uploadLink]);

  return new ApolloClient({
    cache: new InMemoryCache(),
    link: link,
    connectToDevTools: process.env.REACT_APP_ENV === "development",
  });
};

export const client = createApolloClient();
