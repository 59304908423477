import {
  CircleFadingPlus,
  FileText,
  Film,
  GalleryHorizontalEnd,
  Image,
  LetterText,
} from "lucide-react";

export function MediaTypeIcon({ mediaType }: any) {
  switch (mediaType) {
    // LinkedInContentType
    case "TEXT":
      return <LetterText className="size-3.5" />;
    case "CAROUSEL":
      return <GalleryHorizontalEnd className="size-3.5" />;
    case "DOCUMENT":
      return <FileText className="size-3.5" />;
    case "IMAGE":
      return <Image className="size-3.5" />;
    case "MULTI_IMAGE":
      return <GalleryHorizontalEnd className="size-3.5" />;
    case "VIDEO":
      return <Film className="size-3.5" />;

    // InstagramMediaType
    case "REELS":
      return <Film className="size-3.5" />;
    case "STORIES":
      return <CircleFadingPlus className="size-3.5" />;
    default:
      return <span />;
  }
}
