import { debounce } from "lodash";
import {
  ArrowDownNarrowWide,
  ArrowDownUp,
  ArrowUpNarrowWide,
  CircleX,
  ListFilter,
  Search,
} from "lucide-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button } from "../../components/ui/Button";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuPortal,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "../../components/ui/DropdownMenu";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
} from "../../components/ui/Form";
import { Input } from "../../components/ui/Input";
import { ScrollArea, ScrollBar } from "../../components/ui/ScrollArea";
import { Separator } from "../../components/ui/Separator";
import { Typography } from "../../components/ui/Typography";
import { cn } from "../../utils/cn";
import { TabTitle } from "../../utils/tabTitle";
import Layout from "../layout/Layout";
import { CalendarHeader } from "./CalendarHeader";
import { useCalendarContext } from "./CalendarProvider";
import { MonthlyCalendar } from "./MonthlyCalendar";
import { WeeklyCalendar } from "./WeeklyCalendar";

interface Data {
  date: string;
  title: string;
  social: string;
  name: string;
  profilePicture: string;
  postPictures: any;
}
const testData: Data[] = [
  {
    date: "2024-07-10T12:17:17.291Z",
    title:
      "Create an Abstract Syntax Tree using and TreeSitter, TreeButcher, Treeswitcher",
    social: "instagram",
    name: "Abdulani Abdulah Abdulaski",
    profilePicture:
      "https://images.ctfassets.net/h6goo9gw1hh6/2sNZtFAWOdP1lmQ33VwRN3/24e953b920a9cd0ff2e1d587742a2472/1-intro-photo-final.jpg?w=1200&h=992&fl=progressive&q=70&fm=jpg",
    postPictures: [
      "https://images.pexels.com/photos/35537/child-children-girl-happy.jpg?auto=compress&cs=tinysrgb&dpr=1&w=500",
      "https://i0.wp.com/picjumbo.com/wp-content/uploads/camping-on-top-of-the-mountain-during-sunset-free-photo.jpg?w=600&quality=80",
    ],
  },
  {
    date: "2024-07-09T11:17:17.291Z",
    title: "Mali naslov",
    social: "linkedin",
    name: "Abdulani Abdulah Abdulaski",
    profilePicture:
      "https://images.ctfassets.net/h6goo9gw1hh6/2sNZtFAWOdP1lmQ33VwRN3/24e953b920a9cd0ff2e1d587742a2472/1-intro-photo-final.jpg?w=1200&h=992&fl=progressive&q=70&fm=jpg",
    postPictures: [
      "https://images.pexels.com/photos/35537/child-children-girl-happy.jpg?auto=compress&cs=tinysrgb&dpr=1&w=500",
      "https://i0.wp.com/picjumbo.com/wp-content/uploads/camping-on-top-of-the-mountain-during-sunset-free-photo.jpg?w=600&quality=80",
      "https://images.pexels.com/photos/35537/child-children-girl-happy.jpg?auto=compress&cs=tinysrgb&dpr=1&w=500",
      "https://i0.wp.com/picjumbo.com/wp-content/uploads/camping-on-top-of-the-mountain-during-sunset-free-photo.jpg?w=600&quality=80",
      "https://images.pexels.com/photos/35537/child-children-girl-happy.jpg?auto=compress&cs=tinysrgb&dpr=1&w=500",
      "https://i0.wp.com/picjumbo.com/wp-content/uploads/camping-on-top-of-the-mountain-during-sunset-free-photo.jpg?w=600&quality=80",
      "https://images.pexels.com/photos/35537/child-children-girl-happy.jpg?auto=compress&cs=tinysrgb&dpr=1&w=500",
      "https://i0.wp.com/picjumbo.com/wp-content/uploads/camping-on-top-of-the-mountain-during-sunset-free-photo.jpg?w=600&quality=80",
    ],
  },
  {
    date: new Date().toISOString(),
    title: "Malo veci naslov",
    social: "linkedin",
    name: "Filip Krnetic",
    profilePicture:
      "https://media.licdn.com/dms/image/D4D03AQEzG2hamJnKvg/profile-displayphoto-shrink_200_200/0/1713531794333?e=2147483647&v=beta&t=dp60of_5ErpiTJ2lTolMvsAOUUvEHVIPOp9G8QmbpAY",
    postPictures: [],
  },
  {
    date: "2024-07-09T15:17:17.291Z",
    title: "BMalo veci naslov",
    social: "instagram",
    name: "Filip Krnetic",
    profilePicture:
      "https://media.licdn.com/dms/image/D4D03AQEzG2hamJnKvg/profile-displayphoto-shrink_200_200/0/1713531794333?e=2147483647&v=beta&t=dp60of_5ErpiTJ2lTolMvsAOUUvEHVIPOp9G8QmbpAY",
    postPictures: [],
  },
  {
    date: "2024-07-09T15:17:17.291Z",
    title: "BMalo veci naslov",
    social: "tiktok",
    name: "Filip Krnetic",
    profilePicture:
      "https://media.licdn.com/dms/image/D4D03AQEzG2hamJnKvg/profile-displayphoto-shrink_200_200/0/1713531794333?e=2147483647&v=beta&t=dp60of_5ErpiTJ2lTolMvsAOUUvEHVIPOp9G8QmbpAY",
    postPictures: [],
  },
  {
    date: "2024-07-03T15:17:17.291Z",
    title: "BMalo veci naslov",
    social: "instagram",
    name: "Filip Krnetic",
    profilePicture:
      "https://media.licdn.com/dms/image/D4D03AQEzG2hamJnKvg/profile-displayphoto-shrink_200_200/0/1713531794333?e=2147483647&v=beta&t=dp60of_5ErpiTJ2lTolMvsAOUUvEHVIPOp9G8QmbpAY",
    postPictures: [],
  },
  {
    date: "2024-07-03T15:17:17.291Z",
    title: "BMalo veci naslov",
    social: "instagram",
    name: "Filip Krnetic",
    profilePicture:
      "https://media.licdn.com/dms/image/D4D03AQEzG2hamJnKvg/profile-displayphoto-shrink_200_200/0/1713531794333?e=2147483647&v=beta&t=dp60of_5ErpiTJ2lTolMvsAOUUvEHVIPOp9G8QmbpAY",
    postPictures: [],
  },
  {
    date: "2024-07-03T15:17:17.291Z",
    title: "BMalo veci naslov",
    social: "instagram",
    name: "Filip Krnetic",
    profilePicture:
      "https://media.licdn.com/dms/image/D4D03AQEzG2hamJnKvg/profile-displayphoto-shrink_200_200/0/1713531794333?e=2147483647&v=beta&t=dp60of_5ErpiTJ2lTolMvsAOUUvEHVIPOp9G8QmbpAY",
    postPictures: [],
  },
  {
    date: "2024-07-02T15:17:17.291Z",
    title: "BMalo veci naslov",
    social: "instagram",
    name: "Filip Krnetic",
    profilePicture:
      "https://media.licdn.com/dms/image/D4D03AQEzG2hamJnKvg/profile-displayphoto-shrink_200_200/0/1713531794333?e=2147483647&v=beta&t=dp60of_5ErpiTJ2lTolMvsAOUUvEHVIPOp9G8QmbpAY",
    postPictures: [],
  },
  {
    date: "2024-07-09T15:17:17.291Z",
    title: "BMalo veci naslov",
    social: "instagram",
    name: "Filip Krnetic",
    profilePicture:
      "https://media.licdn.com/dms/image/D4D03AQEzG2hamJnKvg/profile-displayphoto-shrink_200_200/0/1713531794333?e=2147483647&v=beta&t=dp60of_5ErpiTJ2lTolMvsAOUUvEHVIPOp9G8QmbpAY",
    postPictures: [],
  },
  {
    date: "2024-07-09T15:17:17.291Z",
    title: "Atest veci naslov",
    social: "instagram",
    name: "Filip Krnetic",
    profilePicture:
      "https://media.licdn.com/dms/image/D4D03AQEzG2hamJnKvg/profile-displayphoto-shrink_200_200/0/1713531794333?e=2147483647&v=beta&t=dp60of_5ErpiTJ2lTolMvsAOUUvEHVIPOp9G8QmbpAY",
    postPictures: [],
  },
  {
    date: "2024-07-09T15:17:17.291Z",
    title: "Test veci naslov",
    social: "instagram",
    name: "Filip Krnetic",
    profilePicture:
      "https://media.licdn.com/dms/image/D4D03AQEzG2hamJnKvg/profile-displayphoto-shrink_200_200/0/1713531794333?e=2147483647&v=beta&t=dp60of_5ErpiTJ2lTolMvsAOUUvEHVIPOp9G8QmbpAY",
    postPictures: [],
  },
  {
    date: "2024-07-09T15:18:17.291Z",
    title: "AMalo veci naslov",
    social: "instagram",
    name: "Filip Krnetic",
    profilePicture:
      "https://media.licdn.com/dms/image/D4D03AQEzG2hamJnKvg/profile-displayphoto-shrink_200_200/0/1713531794333?e=2147483647&v=beta&t=dp60of_5ErpiTJ2lTolMvsAOUUvEHVIPOp9G8QmbpAY",
    postPictures: [
      "https://images.pexels.com/photos/35537/child-children-girl-happy.jpg?auto=compress&cs=tinysrgb&dpr=1&w=500",
      "https://i0.wp.com/picjumbo.com/wp-content/uploads/camping-on-top-of-the-mountain-during-sunset-free-photo.jpg?w=600&quality=80",
      "https://images.pexels.com/photos/35537/child-children-girl-happy.jpg?auto=compress&cs=tinysrgb&dpr=1&w=500",
      "https://i0.wp.com/picjumbo.com/wp-content/uploads/camping-on-top-of-the-mountain-during-sunset-free-photo.jpg?w=600&quality=80",
      "https://images.pexels.com/photos/35537/child-children-girl-happy.jpg?auto=compress&cs=tinysrgb&dpr=1&w=500",
      "https://i0.wp.com/picjumbo.com/wp-content/uploads/camping-on-top-of-the-mountain-during-sunset-free-photo.jpg?w=600&quality=80",
      "https://images.pexels.com/photos/35537/child-children-girl-happy.jpg?auto=compress&cs=tinysrgb&dpr=1&w=500",
      "https://i0.wp.com/picjumbo.com/wp-content/uploads/camping-on-top-of-the-mountain-during-sunset-free-photo.jpg?w=600&quality=80",
    ],
  },
];

export interface SocialPlatformsState {
  [key: string]: boolean;
}
export default function CalendarPage() {
  const title = "Calendar";
  TabTitle(title);
  const form = useForm();
  const { setFocus, register, resetField } = form;
  const {
    sort,
    setSort,
    sortingBy,
    setSortingBy,
    calendarView,
    visiblePlatforms,
    handlePlatformChange,
    setCurrentDate,
  } = useCalendarContext();
  useEffect(() => {
    setCurrentDate(new Date());
    // eslint-disable-next-line
  }, []);
  const [showSearch, setShowSearch] = useState(false);
  const onSearchClick = () => {
    setShowSearch(true);
    setFocus("search");
  };

  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearch = debounce((value) => {
    setSearchTerm(value);
  }, 500);

  return (
    <Layout tabTitle={title}>
      <nav className="flex justify-between w-full p-4 pt-2 pb-2 overflow-hidden bg-white border-b border-b-accent h-[65px] pr-0">
        <div className="flex items-center w-full gap-2 overflow-hidden pl-[3px]">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="relative h-8 p-2 w-fit">
                <ListFilter
                  className={cn("w-4 h-4", {
                    "text-contiyo-primary-purple": !Object.values(
                      visiblePlatforms
                    ).every((v) => v),
                  })}
                ></ListFilter>
                {!Object.values(visiblePlatforms).every((v) => v) && (
                  <div className="absolute w-2 h-2 bg-orange-400 border border-white rounded-full top-[6px] right-[6px]"></div>
                )}
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="start" className="min-w-[150px]">
              <span className="uppercase text-[10px] font-semibold text-contiyo-secondary-text pl-[8px]">
                Socials
              </span>
              <DropdownMenu>
                <DropdownMenuGroup>
                  <DropdownMenuCheckboxItem
                    checked={Object.values(visiblePlatforms).every((v) => v)}
                    onCheckedChange={() => handlePlatformChange("all")}
                  >
                    All
                  </DropdownMenuCheckboxItem>
                  {/* {Object.keys(socialPlatforms).map((platform) =>
                    Object.values(visiblePlatforms).every((v) => v) ? (
                      <DropdownMenuCheckboxItem
                        key={platform}
                        checked={false}
                        onCheckedChange={() => handlePlatformChange(platform)}
                        noIcon
                      >
                        <img
                          src={socialSwitcher([platform][0])}
                          alt={`${platform} logo`}
                          className="w-4 h-4 mr-2"
                        />
                        <span className="capitalize">{platform}</span>
                      </DropdownMenuCheckboxItem>
                    ) : (
                      <DropdownMenuCheckboxItem
                        key={platform}
                        checked={visiblePlatforms[platform]}
                        onCheckedChange={() => handlePlatformChange(platform)}
                      >
                        <img
                          src={socialSwitcher([platform][0])}
                          alt={`${platform} logo`}
                          className="w-4 h-4 mr-2"
                        />
                        <span className="capitalize">{platform}</span>
                      </DropdownMenuCheckboxItem>
                    )
                  )} */}
                </DropdownMenuGroup>
              </DropdownMenu>
            </DropdownMenuContent>
          </DropdownMenu>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="relative h-8 p-2 w-fit">
                <ArrowDownUp
                  className={cn("w-4 h-4", {
                    "text-contiyo-primary-purple": sortingBy !== "default",
                  })}
                />{" "}
                {sortingBy !== "default" && (
                  <div className="absolute w-2 h-2 bg-orange-400 border border-white rounded-full top-[6px] right-[6px]"></div>
                )}
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="start" className="min-w-[150px]">
              <span className="uppercase text-[10px] font-semibold text-contiyo-secondary-text pl-[8px]">
                Sort
              </span>{" "}
              <DropdownMenu>
                <DropdownMenuGroup>
                  <DropdownMenuSub>
                    {sortingBy === "default" ? (
                      <DropdownMenuSubTrigger disabled={true} noIcon>
                        {sort === "asc" && (
                          <>
                            <ArrowUpNarrowWide className="w-4 h-4 mr-2" />
                            <span>Ascending</span>
                          </>
                        )}
                        {sort === "desc" && (
                          <>
                            <ArrowDownNarrowWide className="w-4 h-4 mr-2" />
                            <span>Descending</span>
                          </>
                        )}
                      </DropdownMenuSubTrigger>
                    ) : (
                      <DropdownMenuSubTrigger>
                        {sort === "asc" && (
                          <>
                            <ArrowUpNarrowWide className="w-4 h-4 mr-2" />
                            <span>Ascending</span>
                          </>
                        )}
                        {sort === "desc" && (
                          <>
                            <ArrowDownNarrowWide className="w-4 h-4 mr-2" />
                            <span>Descending</span>
                          </>
                        )}
                      </DropdownMenuSubTrigger>
                    )}
                    <DropdownMenuPortal>
                      <DropdownMenuSubContent>
                        <DropdownMenuRadioGroup
                          value={sort}
                          onValueChange={setSort}
                        >
                          <DropdownMenuRadioItem value="asc">
                            Ascending
                          </DropdownMenuRadioItem>
                          <DropdownMenuRadioItem value="desc">
                            Descending
                          </DropdownMenuRadioItem>
                        </DropdownMenuRadioGroup>
                      </DropdownMenuSubContent>
                    </DropdownMenuPortal>
                  </DropdownMenuSub>
                </DropdownMenuGroup>
              </DropdownMenu>
              <span className="uppercase text-[10px] font-semibold text-contiyo-secondary-text pl-[8px]">
                Sort By
              </span>{" "}
              <DropdownMenuRadioGroup
                value={sortingBy}
                onValueChange={setSortingBy}
              >
                <DropdownMenuRadioItem value="default">
                  Default
                </DropdownMenuRadioItem>
                {/* <DropdownMenuRadioItem value="time">Time</DropdownMenuRadioItem> */}
                <DropdownMenuRadioItem value="name">Name</DropdownMenuRadioItem>
              </DropdownMenuRadioGroup>
            </DropdownMenuContent>
          </DropdownMenu>
          <Button
            variant="ghost"
            className={`h-8 p-2 ${showSearch && "hover:bg-transparent"}`}
            onClick={onSearchClick}
          >
            <Search className="w-4 h-4" />
            <Form {...form}>
              <form>
                <FormField
                  control={form.control}
                  name="search"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <div className="relative">
                          <Input
                            className="py-1 pl-1 pr-5 leading-tight border-none h-fit w-36 focus-visible:ring-0"
                            {...register("search")}
                            placeholder="Type to search..."
                            onChange={(e) => debouncedSearch(e.target.value)}
                          />
                          {searchTerm !== "" && (
                            <Button
                              variant="ghost"
                              asChild
                              className={
                                "top-0 absolute right-0 p-0  h-full text-contiyo-secondary-text hover:text-contiyo-primary-purple hover:bg-transparent"
                              }
                              onClick={() => {
                                setSearchTerm("");
                                resetField("search");
                              }}
                            >
                              <div>
                                <CircleX className="w-4 h-4" />
                              </div>
                            </Button>
                          )}
                        </div>
                      </FormControl>
                    </FormItem>
                  )}
                />
              </form>
            </Form>
          </Button>
          <ScrollArea className="flex-grow overflow-x-visible hideScrollbar top-[5px]">
            <div className="flex items-center gap-2 pb-[10px] last:pr-4">
              {sortingBy !== "default" ? (
                <div className="relative flex items-center gap-2">
                  <Separator
                    orientation="vertical"
                    className="sticky left-0 h-[27.5px]"
                  ></Separator>
                  <div className="flex items-center px-3 py-1 border rounded-full border-accent group text-contiyo-secondary-text">
                    {sort === "asc" ? (
                      <ArrowUpNarrowWide
                        className="w-4 h-4 mr-2 hover:stroke-contiyo-primary-purple hover:cursor-pointer"
                        onClick={() => setSort("desc")}
                      />
                    ) : (
                      <ArrowDownNarrowWide
                        className="w-4 h-4 mr-2 hover:stroke-contiyo-primary-purple hover:cursor-pointer"
                        onClick={() => setSort("asc")}
                      />
                    )}
                    <Typography className="capitalize font-regular">
                      {sortingBy}
                    </Typography>
                    <CircleX
                      className="hidden w-4 h-4 ml-2 group-hover:block hover:cursor-pointer hover:stroke-destructive hover:fill-destructive/10"
                      onClick={() => {
                        setSort("asc");
                        setSortingBy("default");
                      }}
                    ></CircleX>
                  </div>
                </div>
              ) : null}
              {/* {Object.values(visiblePlatforms).every((v) => v) ? null : (
                <div className="relative flex items-center gap-2">
                  <Separator
                    orientation="vertical"
                    className="sticky left-0 h-[27.5px]"
                  ></Separator>
                  {Object.keys(visiblePlatforms)
                    .filter((platform) => visiblePlatforms[platform])
                    .map((platform) => (
                      <div
                        className="flex items-center px-3 py-1 border rounded-full border-accent group text-contiyo-secondary-text"
                        key={platform}
                      >
                        <img
                          src={socialSwitcher([platform][0])}
                          alt={`${[platform]} logo`}
                          className="w-4 h-4 mr-2"
                        />
                        <Typography className="capitalize font-regular">
                          {platform}
                        </Typography>
                        <CircleX
                          className="hidden w-4 h-4 ml-2 group-hover:block hover:cursor-pointer hover:stroke-destructive hover:fill-destructive/10"
                          onClick={() => handlePlatformChange(platform)}
                        ></CircleX>
                      </div>
                    ))}
                </div>
              )} */}
              {/* {Array.from({ length: 10 }).map((_, index) => (
                <div
                  key={index}
                  className="flex items-center px-3 py-1 border rounded-full border-accent group text-contiyo-secondary-text"
                >
                    <ArrowDownNarrowWide
                      className="w-4 h-4 mr-2 hover:stroke-contiyo-primary-purple hover:cursor-pointer"
                    />
                  <Typography className="capitalize font-regular">
                    {sortingBy}
                  </Typography>
                </div>
              ))} */}
              <ScrollBar orientation="horizontal" className="pr-4" />
            </div>
          </ScrollArea>
        </div>
      </nav>
      <div className="grid grid-rows-[auto_1fr] overflow-hidden p-4 pt-0">
        <CalendarHeader />
        {calendarView === "weekly" && (
          <WeeklyCalendar
            posts={testData}
            sort={sort}
            sortingBy={sortingBy}
            searchTerm={searchTerm}
            visiblePlatforms={visiblePlatforms}
          />
        )}
        {calendarView === "monthly" && (
          <MonthlyCalendar
            posts={testData}
            sort={sort}
            sortingBy={sortingBy}
            searchTerm={searchTerm}
            visiblePlatforms={visiblePlatforms}
          ></MonthlyCalendar>
        )}
      </div>
    </Layout>
  );
}
