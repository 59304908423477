import { Navigate, Outlet, useNavigate, useParams } from "react-router-dom";
import { useAuth } from "./AuthProvider";
import logoRectangle from "../../assets/logo-rectangle.svg";
import { useWorkspace } from "../workspace/WorkspaceProvider";
import { useEffect } from "react";
import {
  useGetActiveWorkspace,
  useGetSingleWorkspace,
  useGetWorkspace,
  useSetActiveWorkspace,
} from "../workspace/api/workspaceService";

const PrivateRoutes = () => {
  const { isAuthenticated, isLoading } = useAuth();
  const { setActiveWorkspace, setWorkspaces, setIsInfoLoading } =
    useWorkspace();
  const [getWorkspaces] = useGetWorkspace();
  const [getActiveWorkspace] = useGetActiveWorkspace();
  const [setActiveWorkspaceMutation] = useSetActiveWorkspace();
  const [getSingleWorkspace, { loading: singleWorkspaceLoading }] =
    useGetSingleWorkspace();
  const navigate = useNavigate();
  const { workspaceSlug } = useParams();

  useEffect(() => {
    const checkActiveWorkspace = async () => {
      try {
        setIsInfoLoading(true);
        const singleWorkspace = await getSingleWorkspace({
          variables: { workspaceId: workspaceSlug },
        });
        if (singleWorkspace?.error?.graphQLErrors) {
          singleWorkspace?.error?.graphQLErrors.forEach(async (err: any) => {
            switch (err.extensions?.code) {
              case "UNAUTHORIZED":
                navigate("/");
                break;
            }
          });
        } else {
          const activeWorkspace = await getActiveWorkspace();
          if (
            activeWorkspace?.data?.user?.activeWorkspace.slug !== workspaceSlug
          ) {
            const singleWorkspace = await getSingleWorkspace({
              variables: { workspaceId: workspaceSlug },
            });
            await setActiveWorkspaceMutation({
              variables: {
                workspaceId: singleWorkspace?.data?.workspace?.PK.split("#")[1],
              },
            });
            await setActiveWorkspace(singleWorkspace?.data?.workspace);
            const workspaces = await getWorkspaces();
            setWorkspaces(workspaces?.data?.user?.workspaces);
          } else if (
            activeWorkspace?.data?.user?.activeWorkspace.slug === workspaceSlug
          ) {
            await setActiveWorkspace(
              activeWorkspace?.data?.user?.activeWorkspace
            );
            const workspaces = await getWorkspaces();
            setWorkspaces(workspaces?.data?.user?.workspaces);
          }
        }
      } catch (error: any) {
        navigate("/");
      } finally {
        setIsInfoLoading(false);
      }
    };
    checkActiveWorkspace();
    // eslint-disable-next-line
  }, []);

  if (isLoading || singleWorkspaceLoading) {
    return (
      <main>
        <div className="flex items-center justify-center w-screen h-screen">
          <img
            src={logoRectangle}
            alt="Contiyo logo"
            className="w-full animate-pulse-zoom max-w-24"
          ></img>
        </div>
      </main>
    );
  }
  return isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
};

const PublicRoutes = () => {
  const { isAuthenticated, isLoading } = useAuth();

  if (isLoading) {
    return (
      <main>
        <div className="flex items-center justify-center w-screen h-screen">
          <img
            src={logoRectangle}
            alt="Contiyo logo"
            className="w-full animate-pulse-zoom max-w-24"
          ></img>
        </div>
      </main>
    );
  }

  return !isAuthenticated ? <Outlet /> : <Navigate to="/" />;
};

export { PrivateRoutes, PublicRoutes };
