import { useState } from "react";
import { Typography } from "../../../components/ui/Typography";
import { Separator } from "../../../components/ui/Separator";
import { useAuth } from "../../auth/AuthProvider";
import { Button } from "../../../components/ui/Button";
import { ArrowLeft } from "lucide-react";
import {
  Dialog,
  DialogContent,
  DialogOverlay,
  DialogTrigger,
} from "../../../components/ui/Dialog";
import UpdateUserForm from "./UpdateUserForm";
import ChangePasswordForm from "./ChangePasswordForm";
import ChangeEmailForm from "./ChangeEmailForm";
import DeleteAccountForm from "./DeleteAccountForm";
import ConfirmEmailChangeForm from "./ConfirmEmailChangeForm";

export default function MyAccount() {
  const [emailCodeSent, setEmailCodeSent] = useState(false);
  const [settingsPreview, setSettingPreview] = useState("my-account");
  const { loggedUser } = useAuth();

  return (
    <>
      {settingsPreview === "my-account" && (
        <div className="flex flex-col w-full h-full">
          <div className="flex flex-col gap-10">
            <div className="flex items-start gap-4 w-fit">
              <UpdateUserForm />
            </div>

            <div className="flex flex-col gap-2">
              <Typography component="p" variant="p1">
                Information
              </Typography>
              <Separator></Separator>
              <div className="flex flex-col space-y-4">
                <div className="flex items-center justify-between gap-5 mt-2">
                  <div className="w-full">
                    <Typography component="p" variant="p2">
                      Email
                    </Typography>
                    <Typography
                      component="p"
                      className="text-[12px] font-normal text-contiyo-secondary-text truncate"
                    >
                      {loggedUser.email}
                    </Typography>
                  </div>
                  <Button
                    type="button"
                    className="h-8 text-[14px] rounded"
                    variant="outline"
                    onClick={() => setSettingPreview("change-email")}
                  >
                    Change Email
                  </Button>
                </div>
                <div className="flex items-center justify-between gap-5 mt-2">
                  <div>
                    <Typography component="p" variant="p2">
                      Password
                    </Typography>
                  </div>
                  <Button
                    type="button"
                    className="h-8 text-[14px] rounded"
                    variant="outline"
                    onClick={() => setSettingPreview("change-password")}
                  >
                    Change Password
                  </Button>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <Typography component="p" variant="p1">
                Account Management
              </Typography>
              <Separator></Separator>

              <div className="flex gap-2">
                <Dialog modal={true}>
                  <DialogOverlay className="bg-black/50" />
                  <DialogTrigger asChild>
                    <Button
                      className="h-8 text-[14px] rounded w-fit mt-2"
                      variant="destructive"
                    >
                      Delete my account
                    </Button>
                  </DialogTrigger>
                  <DialogContent className="sm:max-w-[425px] p-5" disableClose>
                    <DeleteAccountForm />
                  </DialogContent>
                </Dialog>
              </div>

              <div className="flex flex-col gap-1">
                <Typography className="font-normal text-contiyo-secondary-text text-[12px] flex gap-1 items-start leading-tight">
                  Permanently delete the account and remove access from all
                  workspaces.
                </Typography>
              </div>
            </div>
          </div>
        </div>
      )}
      {settingsPreview === "change-password" && (
        <div className="flex flex-col w-full h-full">
          <div className="flex flex-col gap-2">
            <div className="flex gap-2 mb-2 mobile:mt-4">
              <Button
                variant="outline"
                className="w-6 h-6 p-1"
                onClick={() => setSettingPreview("my-account")}
              >
                <ArrowLeft className="size-4" />
              </Button>
              <Typography component="p" variant="p1" className="leading-6">
                Change Password
              </Typography>
            </div>
            <ChangePasswordForm setSettingPreview={setSettingPreview} />
          </div>
        </div>
      )}
      {settingsPreview === "change-email" && (
        <div className="flex flex-col w-full h-full">
          <div className="flex flex-col gap-2">
            <div className="flex gap-2 mb-2 mobile:mt-4">
              <Button
                variant="outline"
                className="w-6 h-6 p-1"
                onClick={() => {
                  setSettingPreview("my-account");
                  setEmailCodeSent(false);
                }}
              >
                <ArrowLeft className="size-4" />
              </Button>
              <Typography component="p" variant="p1" className="leading-6">
                Change Email
              </Typography>
            </div>
            {!emailCodeSent ? (
              <ChangeEmailForm setEmailCodeSent={setEmailCodeSent} />
            ) : (
              <ConfirmEmailChangeForm
                setEmailCodeSent={setEmailCodeSent}
                setSettingPreview={setSettingPreview}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
}
