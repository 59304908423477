import { DropdownMenuContentProps } from "@radix-ui/react-dropdown-menu";
import { HelpCircle, Link, LogOut, Settings, User } from "lucide-react";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "../../../components/ui/Avatar";
import {
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
} from "../../../components/ui/DropdownMenu";
import { getInitials } from "../../../utils/getInitials";
import { useAuth } from "../../auth/AuthProvider";
import useHandleSignOut from "../../auth/useSignOut";
import AlertDialogComponent from "../../global/AlertDialogComponent";
import { useSettingsProvider } from "../../settings/SettingsProvider";

export default function ProfileDropdownMenuContent({
  ...props
}: DropdownMenuContentProps) {
  const { loggedUser } = useAuth();
  const { openDialog } = useSettingsProvider();
  const handleSignOut = useHandleSignOut();
  return (
    <DropdownMenuContent className="w-56" {...props}>
      <DropdownMenuLabel>
        <div className="flex items-center justify-between gap-4">
          <div className="flex min-w-0 gap-2">
            <Avatar className="rounded h-[30px] w-[30px]">
              <AvatarImage
                src={loggedUser && loggedUser?.image_url}
                className={`object-cover object-center h-[30px] w-[30px] rounded-radius `}
              />
              <AvatarFallback className="rounded h-[30px] w-[30px]">
                {loggedUser && getInitials(loggedUser?.name)}
              </AvatarFallback>
            </Avatar>
            <div className="flex flex-col justify-center w-full min-w-0">
              <span className="text-[14px] font-bold leading-tight truncate">
                {loggedUser?.name}
              </span>
              <span className="font-semibold text-[12px] leading-tight truncate text-contiyo-secondary-text">
                {loggedUser?.email}
              </span>
            </div>
          </div>
        </div>
      </DropdownMenuLabel>
      <DropdownMenuSeparator />
      <DropdownMenuGroup>
        <DropdownMenuItem onClick={() => openDialog("my-account")}>
          <User className="w-4 h-4 mr-2" />
          <span>My Account</span>
        </DropdownMenuItem>
        <DropdownMenuItem className="flex sm:hidden">
          <Link className="w-4 h-4 mr-2" />
          <span> Share & Earn</span>
        </DropdownMenuItem>
        <DropdownMenuItem
          onClick={() => {
            openDialog("my-settings");
          }}
        >
          <Settings className="w-4 h-4 mr-2" />
          <span>Settings</span>
        </DropdownMenuItem>
      </DropdownMenuGroup>
      <DropdownMenuSeparator />
      <DropdownMenuItem>
        <HelpCircle className="w-4 h-4 mr-2" />
        <span>Support</span>
      </DropdownMenuItem>
      <DropdownMenuSeparator />
      <AlertDialogComponent
        title="Log out of your account?
"
        icon={<LogOut></LogOut>}
        description="Any unsaved changes will be lost, and you will need to log in again to continue."
        onSubmit={handleSignOut}
        actionButtonVariant="destructive"
        actionString="Log out"
      >
        <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
          <LogOut className="w-4 h-4 mr-2 text-red-500" />
          <span className="text-red-500">Log out</span>
        </DropdownMenuItem>
      </AlertDialogComponent>
    </DropdownMenuContent>
  );
}
