import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ApolloProvider } from "@apollo/client";
import { client } from "./network/apolloClient";
import { AuthDialogProvider } from "./modules/auth/DialogProvider";
import { SidebarProvider } from "./modules/layout/SidebarContext";
import { CalendarProvider } from "./modules/calendar/CalendarProvider";
import { AuthProvider } from "./modules/auth/AuthProvider";
import { WorkspaceProvider } from "./modules/workspace/WorkspaceProvider";
import { SettingsProvider } from "./modules/settings/SettingsProvider";
import { Toaster } from "./components/ui/Sonner";
import { SocialProvider } from "./modules/social/SocialProvider";
import { CreatorProvider } from "./modules/creator/CreatorContext";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <CreatorProvider>
        <SettingsProvider>
          <AuthProvider>
            <WorkspaceProvider>
              <SidebarProvider>
                <AuthDialogProvider>
                  <CalendarProvider>
                    <SocialProvider>
                      <App />
                      <Toaster richColors closeButton />
                    </SocialProvider>
                  </CalendarProvider>
                </AuthDialogProvider>
              </SidebarProvider>
            </WorkspaceProvider>
          </AuthProvider>
        </SettingsProvider>
      </CreatorProvider>{" "}
    </ApolloProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
