import { useEffect, useState } from "react";
import { Button } from "../../components/ui/Button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogDescription,
  DialogOverlay,
  DialogTitle,
} from "../../components/ui/Dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../components/ui/Form";
import { useAuthDialog } from "./DialogProvider";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useNavigate } from "react-router-dom";
import { OtpStyledInput } from "../../components/ui/OTPInput";
import { Typography } from "../../components/ui/Typography";
import { Loader2, MoveRight } from "lucide-react";
import { PasswordInput } from "../../components/ui/PasswordInput";
import BrandLogo from "../../components/BrandLogo";
import leftArrow from "../../assets/icons/left-arrow-icon.svg";
import { confirmPassword, passwordRequirementsField } from "./formFields";
import { PasswordRequirementsTooltip } from "./PasswordRequirementsTooltip";
import {
  useConfirmForgotPassword,
  useForgotPassword,
  useSignIn,
} from "./api/authService";
import { useAuth } from "./AuthProvider";
import * as VisuallyHidden from "@radix-ui/react-visually-hidden";
import { toast } from "sonner";

type OtpFormValues = z.infer<typeof OtpFormSchema>;
type FormValues = z.infer<typeof FormSchema>;

const FormSchema = z
  .object({
    password: passwordRequirementsField,
    confirmPassword: confirmPassword,
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords do not match.",
    path: ["confirmPassword"],
  });

const OtpFormSchema = z.object({
  otp: z.string().min(6, {
    message: "Your one-time password must be 6 characters long",
  }),
});

const resetTimerCount = 90;

export default function ForgotPasswordDialog() {
  const { isOpen, email, closeDialog } = useAuthDialog();
  const [timer, setTimer] = useState(resetTimerCount);
  const [showResendLink, setShowResendLink] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [forgotPasswordStepper, setForgotPasswordStepper] = useState(1);
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);
  const [newPassword, setNewPassword] = useState<string>();
  const { setIsAuthenticated } = useAuth();

  const [forgotPassword] = useForgotPassword();
  const [confirmForgotPassword] = useConfirmForgotPassword();

  const navigate = useNavigate();
  const [signIn] = useSignIn();

  const form = useForm<FormValues>({
    resolver: zodResolver(FormSchema),
    reValidateMode: "onChange",
    mode: "onSubmit",
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
    criteriaMode: "all",
  });

  const otpForm = useForm<OtpFormValues>({
    resolver: zodResolver(OtpFormSchema),
    defaultValues: {
      otp: "",
    },
    reValidateMode: "onSubmit",
  });

  const {
    trigger,
    formState: { errors, submitCount },
  } = form;

  function onBackClick() {
    setForgotPasswordStepper(1);
  }
  const onSubmit = async (data: FormValues) => {
    setNewPassword(data.password);
    setForgotPasswordStepper(2);
  };

  const onOtpSubmit = async (data: OtpFormValues) => {
    setIsLoading(true);
    try {
      if (!email || !newPassword) {
        throw new Error("There was a problem with your request");
      }
      // await confirmForgotPassword(email, newPassword, data.otp);
      await confirmForgotPassword({
        variables: {
          input: {
            email: email,
            password: newPassword,
            confirmationCode: data.otp,
          },
        },
      });
      await signIn({
        variables: {
          input: {
            email: email,
            password: newPassword,
          },
        },
      });
      closeDialog();
      setIsAuthenticated(true);
      navigate("/");
      toast.success("Your password has been changed successfully");
    } catch (error: any) {
      if (error.graphQLErrors) {
        error.graphQLErrors.forEach(async (err: any) => {
          switch (err.extensions?.code) {
            case "NOT_FOUND":
              otpForm.setError("otp", {
                type: "manual",
                message:
                  "Invalid verification code provided, please try again.",
              });
              break;
            default:
              otpForm.setError("otp", {
                type: "manual",
                message: error.message,
              });
          }
        });
      }
      if (error.networkError) {
        toast.error("Uh oh! Something went wrong", {
          description: "There was a problem with your request",
        });
      }
    } finally {
      setIsLoading(false);
    }
  };
  const restartTimer = async () => {
    const startTimer = () => {
      setShowResendLink(false);
      setTimer(resetTimerCount);
      const intervalId = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer <= 1) {
            clearInterval(intervalId);
            setShowResendLink(true);
            return 0;
          }
          return prevTimer - 1;
        });
      }, 1000);
    };

    try {
      await forgotPassword({
        variables: {
          input: {
            email: email,
          },
        },
      });
      startTimer();
    } catch (error: any) {
      if (error.graphQLErrors) {
        error.graphQLErrors.forEach((err: any) => {
          switch (err.extensions.code) {
            case "NOT_FOUND":
              startTimer();
              break;
            default:
              toast.error("Uh oh! Something went wrong", {
                description: error.message,
              });
          }
        });
      }
    }
  };

  useEffect(() => {
    setShowResendLink(true);
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = ""; // Required for older browsers
      return "";
    };
    const handlePopstate = (event: PopStateEvent) => {
      event.preventDefault();
      closeDialog();
      return "";
    };
    if (isOpen) {
      window.addEventListener("beforeunload", handleBeforeUnload);
      window.addEventListener("popstate", handlePopstate);
    } else {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("popstate", handlePopstate);
    }
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("popstate", handlePopstate);
    };
  }, [closeDialog, isOpen]);

  return (
    <Dialog open={isOpen}>
      <DialogOverlay className="bg-white mobile:bg-contiyo-background-color" />
      <div
        className={`hidden absolute top-0 left-0 z-[51] ${
          isOpen && "mobile:flex"
        } items-center justify-center w-full h-[100px] py-3`}
      >
        <BrandLogo />
      </div>
      <DialogContent
        className="top-0 mobile:top-[100px] border-transparent mobile:border-contiyo-light-border translate-x-[-50%] mobile:left-2/4 translate-y-0 max-w-[448px] gap-10 shadow-none py-12 px-6 mobile:px-8 mobile:py-10"
        disableClose
      >
        {" "}
        <DialogTitle className="hidden">
          <VisuallyHidden.Root>Forgot password</VisuallyHidden.Root>
        </DialogTitle>
        {forgotPasswordStepper === 2 && (
          <>
            {" "}
            <button className="w-[32px] h-[32px]" onClick={onBackClick}>
              <img src={leftArrow} alt="left arrow icon" />
            </button>
          </>
        )}
        <DialogHeader className="text-left">
          <Typography
            component="h2"
            className="!leading-[36px] font-semibold text-[28px]"
          >
            {forgotPasswordStepper === 1 && "New password"}
            {forgotPasswordStepper === 2 &&
              "Enter the 6-digit code we emailed you"}
          </Typography>

          <DialogDescription className="text-base text-contiyo-text">
            {forgotPasswordStepper === 1 && (
              <>
                {" "}
                Choose a new password you would like to set for
                <span className="font-bold"> {email}</span>.
              </>
            )}
            {forgotPasswordStepper === 2 && (
              <>
                {" "}
                Please check your email
                <span className="font-bold"> {email}</span>. We've sent you a
                code to reset your password. Use the code to complete the
                process and verify your identity.
              </>
            )}
          </DialogDescription>
        </DialogHeader>
        {forgotPasswordStepper === 1 && (
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="space-y-[24px]"
            >
              <FormField
                control={form.control}
                name="password"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>New password</FormLabel>
                    <FormControl>
                      <PasswordInput
                        placeholder="∗∗∗∗∗∗∗∗"
                        {...field}
                        className={`${errors.password ? "border-red-500" : ""}`}
                        onFocus={() => setIsPasswordFocused(true)}
                        onBlur={() => setIsPasswordFocused(false)}
                        onChange={(e) => {
                          field.onChange(e);
                          //Fix for issue where changing password wouldn't trigger password dont match onChange
                          if (submitCount) {
                            trigger("confirmPassword");
                          }
                        }}
                      >
                        <PasswordRequirementsTooltip
                          isPasswordFocused={isPasswordFocused}
                          errors={errors}
                          submitCount={submitCount}
                        />
                      </PasswordInput>
                    </FormControl>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="confirmPassword"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Repeat new password</FormLabel>
                    <FormControl>
                      <PasswordInput
                        placeholder="∗∗∗∗∗∗∗∗"
                        {...field}
                        className={`${
                          errors.confirmPassword ? "border-red-500" : ""
                        }`}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <div className="flex justify-center">
                <Button
                  type="submit"
                  variant="primary"
                  className="inline-flex items-center w-full"
                >
                  Continue
                  <MoveRight className="w-4 h-4 ml-1" />
                </Button>
              </div>
            </form>
          </Form>
        )}
        {forgotPasswordStepper === 2 && (
          <Form {...otpForm}>
            <form
              onSubmit={otpForm.handleSubmit(onOtpSubmit)}
              className="space-y-[24px]"
            >
              <FormField
                control={otpForm.control}
                name="otp"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <OtpStyledInput
                        numInputs={6}
                        inputType="number"
                        value={field.value}
                        onChange={field.onChange}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className="flex justify-center">
                <Button
                  type="submit"
                  variant="primary"
                  className="inline-flex items-center w-full"
                  disabled={isLoading}
                >
                  {!isLoading ? (
                    "Confirm"
                  ) : (
                    <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                  )}
                </Button>
              </div>

              <div className="flex flex-wrap items-center justify-center gap-1 !mt-3 text-contiyo-text">
                {showResendLink ? (
                  <>
                    <span>Didn't receive code?</span>
                    <Button
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        restartTimer();
                      }}
                      className="font-medium text-contiyo-primary-purple"
                      variant="link"
                    >
                      Resend
                    </Button>
                  </>
                ) : (
                  <span>Resend available in {timer}s</span>
                )}
              </div>
            </form>
          </Form>
        )}
      </DialogContent>
    </Dialog>
  );
}
