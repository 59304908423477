import { MotionProps, motion } from "framer-motion";
import { ReactNode } from "react";

interface OpacityWrapperProps extends MotionProps {
  children: ReactNode;
  className?: string;
}

const OpacityWrapper = ({
  children,
  className,
  ...rest
}: OpacityWrapperProps) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1, ease: "easeInOut" }}
      className={className}
      {...rest}
    >
      {children}
    </motion.div>
  );
};

export default OpacityWrapper;
