import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import {
  Calendar,
  Camera,
  Clipboard,
  NotepadTextDashed,
  Plus,
  Send,
  Smile,
  TriangleAlert,
  X,
} from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { toast } from "sonner";
import { Button } from "../../components/ui/Button";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "../../components/ui/DropdownMenu";
import { Input } from "../../components/ui/Input";
import { ScrollArea, ScrollBar } from "../../components/ui/ScrollArea";
import { Separator } from "../../components/ui/Separator";
import { Textarea } from "../../components/ui/TextArea";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../components/ui/Tooltip";
import { Typography } from "../../components/ui/Typography";
import { WorkspaceSocialMedia } from "../../gql/graphql";
import { cn } from "../../utils/cn";
import { TabTitle } from "../../utils/tabTitle";
import AlertDialogComponent from "../global/AlertDialogComponent";
import Layout, { LayoutBody } from "../layout/Layout";
import { useSidebar } from "../layout/SidebarContext";
import AvatarWithPicture from "../social/AvatarWithPicture";
import { useSocial } from "../social/SocialProvider";
import CreatorSidemenu from "./CreatorSidemenu";
import { aiActions } from "./aiActions";

export default function CreatorPage2() {
  TabTitle("Creator");
  const { setSideBarOpen, setHideSidebarToggle, setIsResponsive } =
    useSidebar();
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [activeAI, setActiveAI] = useState(aiActions[0]);

  useEffect(() => {
    setIsResponsive(false);
    setSideBarOpen(false);
    setHideSidebarToggle(true);
    return () => {
      setIsResponsive(true);
    };
    //eslint-disable-next-line
  }, []);

  const [inputValue, setInputValue] = useState("");
  const [selectionStart, setSelectionStart] = useState(0);
  const [selectionEnd, setSelectionEnd] = useState(0);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const onEmojiSelect = (emojiData: any) => {
    const emoji = emojiData.native;
    if (!emoji) return;

    const beforeSelection = inputValue.slice(0, selectionStart);
    const afterSelection = inputValue.slice(selectionEnd);
    const newValue = beforeSelection + emoji + afterSelection;

    const newCursorPosition = beforeSelection.length + emoji.length;

    setInputValue(newValue);
    setTimeout(() => {
      textareaRef.current?.focus();
      textareaRef.current?.setSelectionRange(
        newCursorPosition,
        newCursorPosition
      );
      setSelectionStart(newCursorPosition);
      setSelectionEnd(newCursorPosition);
    }, 0);
  };

  const updateSelection = (e: React.SyntheticEvent<HTMLTextAreaElement>) => {
    setSelectionStart(e.currentTarget.selectionStart || 0);
    setSelectionEnd(e.currentTarget.selectionEnd || 0);
  };

  let tooltipTimer: NodeJS.Timeout;

  const handleCopy = () => {
    let textToCopy = "";

    if (selectionStart !== selectionEnd) {
      textToCopy = inputValue.substring(selectionStart, selectionEnd);
    } else {
      textToCopy = inputValue;
    }

    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setTooltipOpen(true);
        if (tooltipTimer) clearTimeout(tooltipTimer);

        tooltipTimer = setTimeout(() => {
          setTooltipOpen(false);
        }, 2000);
      })
      .catch((error: any) => {
        toast.error("Error copying to clipboard");
      });
  };
  useEffect(() => {
    return () => {
      if (tooltipTimer) clearTimeout(tooltipTimer);
    };
    //eslint-disable-next-line
  }, []);

  const { socialData } = useSocial();

  const [searchQuery, setSearchQuery] = useState<string>("");
  const searchInputRef = useRef<HTMLInputElement>(null);

  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchQuery(event.target.value);
  };
  const filteredProfiles = socialData?.workspace?.socialMedia?.filter(
    (profile: WorkspaceSocialMedia) =>
      profile.username.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const handleProfileFilter = (profile: WorkspaceSocialMedia) => {
    setSelectedProfiles((prev) => {
      // Only add the profile if it is not already selected
      if (!prev.some((p) => p.SK === profile.SK)) {
        setActiveTab(profile.SK);
        return [...prev, profile];
      }
      // If the profile is already selected, return the current state without changes
      setActiveTab(profile.SK);
      return prev;
    });
  };
  const [selectedProfiles, setSelectedProfiles] = useState<
    WorkspaceSocialMedia[]
  >([]);

  const [activeTab, setActiveTab] = useState<string>("boilerplate");

  return (
    <Layout
      tabTitle={"Creator"}
      className="grid grid-cols-[1fr_300px] grid-rows-1"
    >
      <div className="grid grid-rows-[1fr_69px]">
        <LayoutBody className="overflow-auto sm:p-4">
          <Input
            className="bg-white focus-visible:ring-0 border-accent placeholder:text-[18px] text-[18px] font-semibold leading-tight placeholder:text-contiyo-secondary-text  h-auto p-2.5 mb-4 focus-visible:border-accent"
            placeholder="Untitled"
          />

          <div className="relative flex items-center gap-2 mb-2">
            <Button
              className="h-8"
              onClick={() => setActiveTab("boilerplate")}
              variant={
                activeTab === "boilerplate"
                  ? "creatorActiveTab"
                  : "creatorNotActiveTab"
              }
            >
              <NotepadTextDashed className="size-4" />
              <Typography>Boilerplate</Typography>
            </Button>
            <Separator orientation="vertical" className="h-7"></Separator>
            <DropdownMenu modal={false}>
              <DropdownMenuTrigger asChild>
                <Button
                  className={`${
                    selectedProfiles.length > 0 && "px-2 w-8"
                  } relative flex items-center justify-center h-8  border rounded-radius`}
                  variant={"outline"}
                >
                  <Plus className="size-4" />
                  {selectedProfiles.length === 0 && (
                    <Typography>Add Account</Typography>
                  )}
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent
                align="start"
                avoidCollisions
                className="h-auto"
              >
                <span className="uppercase text-[10px] font-semibold text-contiyo-secondary-text pl-[8px]">
                  Profiles
                </span>
                <div onKeyDown={(e) => e.stopPropagation()}>
                  <Input
                    className="py-1.5 px-2 placeholder:text-[12px] placeholder:leading-tight text-[12px] border-accent mb-1 h-7"
                    placeholder="Type to search..."
                    value={searchQuery}
                    onChange={handleSearchInputChange}
                    ref={searchInputRef}
                  />
                </div>
                {filteredProfiles?.length > 0 ? (
                  <ScrollArea className={`${socialData?.length > 2 && "h-24"}`}>
                    {filteredProfiles?.map(
                      (item: WorkspaceSocialMedia, index: any) => {
                        return (
                          <DropdownMenuCheckboxItem
                            key={index}
                            className={`flex items-center w-full gap-1.5 text-sm data-[disabled]:cursor-not-allowed data-[disabled]:pointer-events-auto`}
                            checked={selectedProfiles?.some(
                              (profile) => profile.SK === item.SK
                            )}
                            onCheckedChange={() => handleProfileFilter(item)}
                            // onSelect={(e) => e.preventDefault()}
                          >
                            <AvatarWithPicture
                              profile={item}
                              className="size-5"
                              socialClassName="size-3.5"
                            ></AvatarWithPicture>
                            <Typography className="leading-tight">
                              {item.username}
                            </Typography>
                          </DropdownMenuCheckboxItem>
                        );
                      }
                    )}
                  </ScrollArea>
                ) : (
                  <Typography className="py-1.5 text-[12px] text-center text-contiyo-secondary-text">
                    No results found
                  </Typography>
                )}
              </DropdownMenuContent>
            </DropdownMenu>
            <ScrollArea className="flex">
              <div className="flex gap-2 w-max">
                {selectedProfiles?.map(
                  (profile: WorkspaceSocialMedia, index: any) => (
                    <div key={index} className="flex">
                      <Button
                        className={cn(
                          `flex items-center h-8 gap-1 p-1 pr-2 rounded-radius  w-fit group`,
                          activeTab === profile.SK && "rounded-r-none pr-1"
                        )}
                        onClick={() => setActiveTab(profile.SK)}
                        variant={
                          activeTab === profile.SK
                            ? "creatorActiveTab"
                            : "creatorNotActiveTab"
                        }
                      >
                        <AvatarWithPicture
                          profile={profile}
                          className="border border-transparent size-6"
                          socialClassName={`size-[14px] border-contiyo-background-accent-purple  ${
                            activeTab !== profile.SK &&
                            "border-white group-hover:border-accent"
                          }`}
                        ></AvatarWithPicture>
                        <Typography>{profile.username}</Typography>
                      </Button>
                      {activeTab === profile.SK && (
                        <AlertDialogComponent
                          title="Delete progress?
                      "
                          icon={<TriangleAlert></TriangleAlert>}
                          description="Any unsaved changes will be lost, and you will need to start over."
                          onSubmit={() => {
                            setSelectedProfiles((prev) =>
                              prev.filter((p) => p.SK !== profile.SK)
                            );
                            setActiveTab("boilerplate");
                          }}
                          actionButtonVariant="destructive"
                          actionString="Delete"
                        >
                          <Button
                            className="relative w-8 h-full p-0 rounded-l-none group"
                            variant={"creatorActiveTab"}
                          >
                            <div className="absolute h-1/2 w-[1px] bg-contiyo-primary-purple left-0"></div>
                            <X className="size-4"></X>
                          </Button>
                        </AlertDialogComponent>
                      )}
                    </div>
                  )
                )}
              </div>
              <ScrollBar orientation="horizontal" />
            </ScrollArea>
          </div>

          <div className="flex flex-grow gap-3 overflow-hidden min-h-[300px]">
            <div className="flex flex-col flex-grow">
              <Textarea
                ref={textareaRef}
                id="input"
                placeholder="Start writing..."
                className="flex-1 p-3 text-sm rounded-none shadow-none resize-none rounded-t-radius bg-white/80 placeholder:text-contiyo-secondary-text focus-visible:ring-0 focus-visible:border-accent"
                value={inputValue}
                onChange={(e) => {
                  setInputValue(e.target.value);
                  updateSelection(e);
                }}
                onSelect={updateSelection}
                onKeyUp={updateSelection}
                onMouseUp={updateSelection}
              ></Textarea>{" "}
              <div className="flex items-center justify-between w-full p-2 px-3 bg-white border border-accent rounded-b-radius">
                <div className="flex gap-1">
                  <DropdownMenu modal={false}>
                    <DropdownMenuTrigger asChild>
                      <Button variant="ghost" className="w-8 h-8 p-2">
                        <Smile className="size-4"></Smile>
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent
                      className="z-0 p-0 h-72"
                      avoidCollisions
                      align="start"
                    >
                      <Picker
                        data={data}
                        theme={"light"}
                        perLine={7}
                        previewPosition={"none"}
                        color="#6314F4"
                        maxFrequentRows={1}
                        onEmojiSelect={onEmojiSelect}
                      ></Picker>
                    </DropdownMenuContent>
                  </DropdownMenu>

                  <Button variant="ghost" className="h-8 p-2">
                    <Camera className="size-4"></Camera>
                    <Typography>Upload media</Typography>
                  </Button>
                </div>
                <TooltipProvider delayDuration={0}>
                  <Tooltip open={tooltipOpen}>
                    <TooltipTrigger asChild>
                      <Button
                        variant="ghost"
                        className="w-8 h-8 p-2"
                        onClick={handleCopy}
                        disabled={textareaRef.current?.value === ""}
                      >
                        <Clipboard className="size-4"></Clipboard>
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent side="top" sideOffset={10}>
                      <Typography>Copied</Typography>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </div>
            </div>
            <div className="flex flex-col border border-accent rounded-radius w-[350px] bg-white">
              <div className="flex items-center gap-1 p-4">
                <span className="size-4 text-contiyo-primary-purple">
                  {activeAI.icon}
                </span>
                <Typography className="font-normal">{activeAI.name}</Typography>
              </div>
              <Separator></Separator>
              <div className="flex flex-col flex-grow overflow-hidden">
                <div className="flex flex-col flex-grow gap-2 p-4 overflow-auto">
                  <div className="p-2 border border-accent h-fit w-fit max-w-[70%] rounded-3xl px-5 py-2.5">
                    <Typography className="font-normal">
                      What do you want to write about?
                    </Typography>
                  </div>
                  <div className="self-end h-fit w-fit bg-accent max-w-[70%] rounded-3xl px-5 py-2.5">
                    <Typography className="w-full font-normal">
                      Write me top 10 marketing miskates about you adnad sk
                      aijjjjfa sfafafsa fann
                    </Typography>
                  </div>
                  <div className="p-2 border border-accent h-fit w-fit max-w-[70%] rounded-3xl px-5 py-2.5">
                    <Typography className="font-normal">
                      #HumanAI #Automation #DataAnalysis #AIMachineLearning
                      #DataScience #TechInnovation #SmartData #Collaboration
                      #FutureOfWork #AIandHumans #DataDriven
                      #DigitalTransformation #TechTrends #AIRevolution
                      #Innovation
                    </Typography>
                  </div>
                  <div className="p-2 border border-accent h-fit w-fit max-w-[70%] rounded-3xl px-5 py-2.5">
                    <Typography className="font-normal">
                      What do you want to write about?
                    </Typography>
                  </div>
                  <div className="p-2 border border-accent h-fit w-fit max-w-[70%] rounded-3xl px-5 py-2.5">
                    <Typography className="font-normal">
                      What do you want to write about?
                    </Typography>
                  </div>
                  <div className="p-2 border border-accent h-fit w-fit max-w-[70%] rounded-3xl px-5 py-2.5">
                    <Typography className="font-normal">
                      What do you want to write about?
                    </Typography>
                  </div>
                  <div className="p-2 border border-accent h-fit w-fit max-w-[70%] rounded-3xl px-5 py-2.5">
                    <Typography className="font-normal">
                      What do you want to write about?
                    </Typography>
                  </div>
                </div>
                <div className="relative p-4 pt-0">
                  {/* <div className="absolute top-0 left-0 w-full h-8 -translate-y-full bg-gradient-to-t from-white to-white/0"></div> */}
                  <div className="flex items-center gap-2 p-3 pl-4 rounded-[26px] bg-accent">
                    {/* <ScrollArea className="w-full"> */}
                    <Textarea
                      id="input"
                      placeholder={activeAI.placeholder}
                      className="box-border w-full p-0 pb-5 break-words whitespace-pre-wrap border-none shadow-none resize-none chatTextArea h-fit placeholder:text-contiyo-secondary-text focus-visible:ring-0 focus-visible:border-none min-h-fit focus:ring-0 focus:ring-offset-0"
                    ></Textarea>
                    {/* </ScrollArea> */}
                    <div className="flex items-center self-end justify-center p-2 bg-white rounded-full shrink-0">
                      <Send className="size-4 text-contiyo-text"></Send>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </LayoutBody>
        <div className="bg-white border-t-[1px] border-accent  flex items-center p-5 justify-between z-10">
          <div className="flex items-center gap-1 text-[14px]">
            <div className="rounded-full size-2 bg-success" />
            <Typography> Last saved at Aug 20, 2024, 10:43AM</Typography>
          </div>
          <div className="flex gap-2">
            <Button variant="outline">
              Publish Now
              <Send className="size-4" />
            </Button>
            <Button variant="primary">
              Schedule
              <Calendar className="size-4" />
            </Button>
          </div>
        </div>
      </div>
      <aside className="flex flex-col bg-white border-l border-accent">
        <CreatorSidemenu
          activeAI={activeAI}
          setActiveAI={setActiveAI}
        ></CreatorSidemenu>
      </aside>
    </Layout>
  );
}
