import { Search, Share2, Users, X } from "lucide-react";
import { Button } from "../../components/ui/Button";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuTrigger,
} from "../../components/ui/DropdownMenu";
import { Input } from "../../components/ui/Input";
import { ScrollArea, ScrollBar } from "../../components/ui/ScrollArea";
import { SearchInput } from "../../components/ui/SearchInput";
import { Separator } from "../../components/ui/Separator";
import { Typography } from "../../components/ui/Typography";
import { SocialMediaType, WorkspaceSocialMedia } from "../../gql/graphql";
import {
  getPlatformColorClasses,
  getSocialMediaImageUrl,
} from "../../utils/socialSwitcher";
import AvatarWithPicture from "../social/AvatarWithPicture";
import { useEffect, useMemo, useRef, useState } from "react";

interface FilterNavProps {
  data: any;
  setFilteredData: React.Dispatch<React.SetStateAction<WorkspaceSocialMedia[]>>;
  disableProfilesFilter?: boolean;
}

export default function FilterNav({
  data,
  setFilteredData,
  disableProfilesFilter,
}: FilterNavProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState<string>("");
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const [selectedPlatforms, setSelectedPlatforms] = useState<SocialMediaType[]>(
    () => {
      const savedPlatforms = localStorage.getItem("socialFilters");
      return savedPlatforms ? JSON.parse(savedPlatforms) : [];
    }
  );

  const handleSocialMediaFilter = (socialType: SocialMediaType) => {
    setSelectedPlatforms((prev) =>
      prev.includes(socialType)
        ? prev.filter((type) => type !== socialType)
        : [...prev, socialType]
    );
  };
  const removeSocialMediaFilter = (socialType: SocialMediaType) => {
    setSelectedPlatforms((prev) => prev.filter((type) => type !== socialType));
  };

  useEffect(() => {
    localStorage.setItem("socialFilters", JSON.stringify(selectedPlatforms));
  }, [selectedPlatforms]);

  const [selectedProfiles, setSelectedProfiles] = useState<
    WorkspaceSocialMedia[]
  >(() => {
    const savedProfiles = localStorage.getItem("selectedProfiles");
    return savedProfiles ? JSON.parse(savedProfiles) : [];
  });

  const handleProfileFilter = (profile: WorkspaceSocialMedia) => {
    setSelectedProfiles((prev) => {
      if (prev.some((p) => p.SK === profile.SK)) {
        // If the profile is already selected, remove it
        return prev.filter((p) => p.SK !== profile.SK);
      } else {
        // If the profile is not selected, add it
        return [...prev, profile];
      }
    });
  };

  const removeProfileFilter = (profile: WorkspaceSocialMedia) => {
    setSelectedProfiles((prev: WorkspaceSocialMedia[]) =>
      prev.filter((p) => p.SK !== profile.SK)
    );
  };
  useEffect(() => {
    localStorage.setItem("selectedProfiles", JSON.stringify(selectedProfiles));
  }, [selectedProfiles]);

  const [searchQuery, setSearchQuery] = useState<string>("");

  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchQuery(event.target.value);
  };

  const filteredProfiles = data?.workspace?.socialMedia?.filter(
    (profile: WorkspaceSocialMedia) =>
      profile.username.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const searchInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (selectedPlatforms.length > 0) {
      setSelectedProfiles((prevSelectedProfiles) =>
        prevSelectedProfiles.filter((profile) =>
          selectedPlatforms.includes(profile.social_media_type)
        )
      );
    }
  }, [selectedPlatforms]);

  const filteredByPlatform = useMemo(() => {
    return data?.workspace?.socialMedia?.filter(
      (profile: any) =>
        selectedPlatforms.length === 0 ||
        selectedPlatforms.includes(profile.social_media_type)
    );
  }, [data, selectedPlatforms]);

  const filteredByProfiles = useMemo(() => {
    return filteredByPlatform?.filter(
      (profile: any) =>
        selectedProfiles.length === 0 ||
        selectedProfiles.some((p) => p.SK === profile.SK)
    );
  }, [filteredByPlatform, selectedProfiles]);

  const finalFilteredProfiles = useMemo(() => {
    return (
      filteredByProfiles?.filter((profile: any) =>
        profile.username.toLowerCase().includes(inputValue.toLowerCase())
      ) || []
    );
  }, [filteredByProfiles, inputValue]);

  useEffect(() => {
    setFilteredData(finalFilteredProfiles);
  }, [finalFilteredProfiles, setFilteredData]);

  return (
    <nav className="flex w-full px-3 py-2 pr-0 bg-white border-b sm:px-4 border-b-accent">
      <div className="flex items-center gap-1">
        <DropdownMenu modal={false}>
          <DropdownMenuTrigger asChild>
            <Button
              variant="ghost"
              className={`relative p-1.5 w-auto h-auto ${
                selectedPlatforms.length > 0 &&
                "bg-contiyo-background-accent-purple text-contiyo-primary-purple hover:bg-contiyo-background-accent-purple-hover hover:text-contiyo-primary-purple"
              }`}
            >
              <Share2 className="w-4 h-4"></Share2>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="start" avoidCollisions>
            <span className="uppercase text-[10px] font-semibold text-contiyo-secondary-text pl-[8px]">
              Socials
            </span>

            <DropdownMenuGroup>
              {Object.values(SocialMediaType)
                .filter(
                  (socialType) => socialType !== SocialMediaType.Boilerplate
                )
                .map((socialType) => (
                  <DropdownMenuCheckboxItem
                    key={socialType}
                    checked={selectedPlatforms.includes(socialType)}
                    onCheckedChange={() => handleSocialMediaFilter(socialType)}
                    className="gap-1.5 capitalize text-sm"
                    onSelect={(e) => e.preventDefault()}
                  >
                    <img
                      src={getSocialMediaImageUrl(socialType)}
                      alt={socialType + " logo"}
                      className="size-4"
                    />
                    <Typography>{socialType.toLowerCase()}</Typography>
                  </DropdownMenuCheckboxItem>
                ))}
            </DropdownMenuGroup>
          </DropdownMenuContent>
        </DropdownMenu>
        {!disableProfilesFilter && (
          <DropdownMenu modal={false}>
            <DropdownMenuTrigger asChild>
              <Button
                variant="ghost"
                className={`relative p-1.5 w-auto h-auto ${
                  selectedProfiles.length > 0 &&
                  "bg-contiyo-background-accent-purple text-contiyo-primary-purple hover:bg-contiyo-background-accent-purple-hover hover:text-contiyo-primary-purple"
                }`}
              >
                <Users className="w-4 h-4"></Users>
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent
              align="start"
              avoidCollisions
              className="h-auto"
            >
              <span className="uppercase text-[10px] font-semibold text-contiyo-secondary-text pl-[8px]">
                Profiles
              </span>
              <div onKeyDown={(e) => e.stopPropagation()}>
                <Input
                  className="py-1.5 px-2 placeholder:text-[12px] placeholder:leading-tight text-[12px] border-accent mb-1 h-7"
                  placeholder="Type to search..."
                  value={searchQuery}
                  onChange={handleSearchInputChange}
                  ref={searchInputRef}
                />
              </div>
              {filteredProfiles?.length > 0 ? (
                <ScrollArea
                  className={`${filteredProfiles?.length > 2 && "h-24"}`}
                >
                  {filteredProfiles.map(
                    (item: WorkspaceSocialMedia, index: any) => {
                      const isDisabled =
                        selectedPlatforms.length > 0 &&
                        !selectedPlatforms.includes(item.social_media_type);

                      return (
                        <DropdownMenuCheckboxItem
                          key={index}
                          className={`flex items-center w-full gap-1.5 text-sm data-[disabled]:cursor-not-allowed data-[disabled]:pointer-events-auto`}
                          checked={selectedProfiles.some(
                            (profile) => profile.SK === item.SK
                          )}
                          onCheckedChange={() =>
                            !isDisabled && handleProfileFilter(item)
                          }
                          onSelect={(e) => e.preventDefault()}
                          disabled={isDisabled}
                        >
                          <AvatarWithPicture
                            profile={item}
                            className="size-5"
                            socialClassName="size-3.5"
                          ></AvatarWithPicture>
                          <Typography className="leading-tight">
                            {item.username}
                          </Typography>
                        </DropdownMenuCheckboxItem>
                      );
                    }
                  )}
                </ScrollArea>
              ) : (
                <Typography className="py-1.5 text-[12px] text-center text-contiyo-secondary-text">
                  No results found
                </Typography>
              )}
            </DropdownMenuContent>
          </DropdownMenu>
        )}

        <SearchInput
          placeholder="Type to search..."
          startIcon={Search}
          endIcon={X}
          onChange={handleInputChange}
          value={inputValue}
          clearValue={() => {
            setInputValue("");
            inputRef.current?.focus();
          }}
          ref={inputRef}
        ></SearchInput>
      </div>
      <ScrollArea>
        <div className="flex translate-y-0.5">
          {selectedPlatforms.length > 0 && (
            <>
              <div className="flex items-center h-full mx-2">
                <Separator orientation="vertical" className="h-7"></Separator>
              </div>
              <div className="flex flex-shrink-0 items-center gap-1.5">
                {selectedPlatforms.map((platform) => {
                  const {
                    lightSocialBG,
                    darkSocialBG,
                    darkBorder,
                    lightStroke,
                  } = getPlatformColorClasses(platform);

                  return (
                    <div
                      className={`flex items-center gap-1 py-[3px] px-[5px] rounded-full w-fit border ${lightSocialBG} ${darkBorder}`}
                      key={platform}
                    >
                      <img
                        src={getSocialMediaImageUrl(platform)}
                        alt={platform + " logo"}
                        className="rounded-full size-4"
                      />

                      <Typography className="text-[12px] capitalize font-medium leading-tight">
                        {platform.toLowerCase()}
                      </Typography>
                      <Button
                        className={`size-3.5 ml-0.5 mr-0.5 rounded-full flex items-center justify-center p-0 ${darkSocialBG} hover:cursor-pointer hover:${darkSocialBG} shrink-0`}
                        onClick={() => removeSocialMediaFilter(platform)}
                        tabIndex={0}
                        variant={"ghost"}
                      >
                        <X
                          className={`${lightStroke} size-2`}
                          strokeWidth={3}
                        />
                      </Button>
                    </div>
                  );
                })}
              </div>
            </>
          )}
          {selectedProfiles.length > 0 && (
            <>
              <div className="flex items-center h-full mx-2">
                <Separator orientation="vertical" className="h-7"></Separator>
              </div>
              <div className="flex flex-shrink-0 items-center gap-1.5">
                {selectedProfiles.map((profile) => {
                  const { lightSocialBG, darkBorder, lightBorder, darkStroke } =
                    getPlatformColorClasses(profile.social_media_type);
                  return (
                    <div
                      className={`flex items-center gap-1 py-[3px] px-[5px] rounded-full w-fit border ${darkBorder} ${lightSocialBG}`}
                      key={profile.SK}
                    >
                      <AvatarWithPicture
                        profile={profile}
                        className="size-4"
                        socialClassName={`${lightBorder} size-3`}
                      ></AvatarWithPicture>

                      <Typography className="text-[12px] font-medium leading-tight">
                        {profile.username}
                      </Typography>
                      <X
                        className={`${darkStroke} size-3.5 hover:cursor-pointer`}
                        strokeWidth={3}
                        onClick={() => removeProfileFilter(profile)}
                      />
                      {/* <div
                      className={`size-3.5 ml-0.5 mr-0.5 rounded-full flex items-center justify-center p-0  hover:cursor-pointer ${darkSocialBG}`}
                    >
                     
                    </div> */}
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </div>
        <ScrollBar orientation="horizontal"></ScrollBar>
      </ScrollArea>
    </nav>
  );
}
