import { zodResolver } from "@hookform/resolvers/zod";
import { Loader2, SaveOff, X } from "lucide-react";
import { useCallback, useState } from "react";
import { FileWithPath, useDropzone } from "react-dropzone";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { z } from "zod";
import { ImageCropper } from "../../components/ui/img-cropper/ImageCropper";
import {
  accept,
  FileWithPreview,
} from "../../components/ui/img-cropper/ImageCropperTypes";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "../../components/ui/Avatar";
import { Button } from "../../components/ui/Button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../components/ui/Form";
import { Input } from "../../components/ui/Input";
import { Typography } from "../../components/ui/Typography";
import AlertDialogComponent from "../global/AlertDialogComponent";
import { useCreateWorkspace } from "./api/workspaceService";
import { useWorkspace } from "./WorkspaceProvider";

const formSchema = z.object({
  workspace: z.string().min(5, {
    message: "Workspace name must be at least 5 characters.",
  }),
});

export default function Onboarding() {
  const [selectedFile, setSelectedFile] = useState<FileWithPreview | null>(
    null
  );
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [workspaceName, setWorkspaceName] = useState("");
  const [createWorkspace] = useCreateWorkspace();
  const { setIsOnboarding, setActiveWorkspace } = useWorkspace();
  const [isLoading, setIsLoading] = useState(false);

  const onDrop = useCallback((acceptedFiles: FileWithPath[]) => {
    const file = acceptedFiles[0];
    if (!file) {
      toast.error("Selected image is too large!");
      return;
    }

    const fileWithPreview = Object.assign(file, {
      preview: URL.createObjectURL(file),
    });

    setSelectedFile(fileWithPreview);
    setDialogOpen(true);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept,
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      workspace: "",
    },
  });
  const navigate = useNavigate();
  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    try {
      setIsLoading(true);
      const response = await createWorkspace({
        variables: {
          input: {
            name: values.workspace,
            image: selectedFile?.file,
          },
        },
      });
      await setActiveWorkspace(response.data?.createWorkspace);
      navigate("/");
      setIsOnboarding(false);
    } catch (error: any) {
      toast.error("Uh oh! Something went wrong", {
        description: "There was a problem with your request",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="absolute top-0 left-0 z-50 flex items-center justify-center w-screen h-screen min-h-screen p-5 overflow-x-hidden bg-white min-w-screen">
      <div className="flex flex-col items-center gap-10 w-fit">
        <AlertDialogComponent
          title="Are you sure you want to discard?"
          description="Any unsaved changes will be lost, and you will need to start over."
          onSubmit={() => setIsOnboarding(false)}
          actionButtonVariant="destructive"
          actionString="Discard"
          icon={<SaveOff />}
        >
          <Button
            className="absolute p-1 top-5 right-5 h-fit"
            variant="outline"
          >
            <X />
          </Button>
        </AlertDialogComponent>
        <div className="flex flex-col items-center">
          <Typography component="h3" className="font-semibold text-center">
            Give your workspace a name
          </Typography>
          <Typography
            component="h3"
            className="font-semibold text-center text-contiyo-secondary-text"
          >
            Details help any collaborators that join
          </Typography>
        </div>
        <div className="flex flex-col items-center gap-2">
          {selectedFile ? (
            <ImageCropper
              dialogOpen={isDialogOpen}
              setDialogOpen={setDialogOpen}
              selectedFile={selectedFile}
              setSelectedFile={setSelectedFile}
              initials={workspaceName ? workspaceName.split(" ")[0][0] : "A"}
            />
          ) : (
            <Avatar
              {...getRootProps()}
              className="rounded cursor-pointer size-24 ring-offset-2 ring-2 ring-slate-200"
            >
              <input {...getInputProps()} />
              <AvatarImage src="" alt="Contiyo" className="rounded" />
              <AvatarFallback className="text-[36px] rounded">
                {workspaceName ? workspaceName.split(" ")[0][0] : "A"}
              </AvatarFallback>
            </Avatar>
          )}
          <Typography className="text-center">
            Choose your workspace image
          </Typography>
        </div>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="w-full space-y-8"
          >
            <FormField
              control={form.control}
              name="workspace"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Workspace name</FormLabel>
                  <FormControl>
                    <Input
                      placeholder="A workspace"
                      {...field}
                      onChangeCapture={(e) =>
                        setWorkspaceName(e.currentTarget.value)
                      }
                    />
                  </FormControl>
                  <FormDescription className="!mt-1">
                    The name of your company or organization.
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button
              type="submit"
              className="w-full"
              variant="primary"
              disabled={isLoading}
            >
              {!isLoading ? (
                "Confirm"
              ) : (
                <Loader2 className="w-4 h-4 mr-2 animate-spin" />
              )}
            </Button>
          </form>
        </Form>
      </div>
    </div>
  );
}
