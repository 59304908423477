import {
  addDays,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  format,
  isSameDay,
  parseISO,
  isBefore,
  isSameMonth,
} from "date-fns";
import { cn } from "../../utils/cn";
import { Typography } from "../../components/ui/Typography";
import { Button } from "../../components/ui/Button";
import { Plus } from "lucide-react";
import { ScrollArea } from "../../components/ui/ScrollArea";
import { getSocialMediaImageUrl } from "../../utils/socialSwitcher";
import { SocialPlatformsState } from "./CalendarPage";
import { useCalendarContext } from "./CalendarProvider";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../components/ui/Popover";

const daysOfWeek = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

export const MonthlyCalendar = ({
  posts,
  sort,
  sortingBy,
  searchTerm,
  visiblePlatforms,
}: {
  posts: any;
  sort: string;
  sortingBy: string;
  searchTerm: string;
  visiblePlatforms: SocialPlatformsState;
}) => {
  const { currentDate, todaysDate } = useCalendarContext();
  const startOfCurrentMonth = startOfMonth(currentDate);
  const endOfCurrentMonth = endOfMonth(currentDate);
  const startOfCalendar = startOfWeek(startOfCurrentMonth, { weekStartsOn: 1 });
  const endOfCalendar = endOfWeek(endOfCurrentMonth, { weekStartsOn: 1 });

  const renderDaysHeader = () => {
    return daysOfWeek.map((day, index) => (
      <div
        key={index}
        className="flex-1 p-2 bg-white border-b-2 border-r tablet:p-4 "
      >
        <div className="flex items-center justify-center">
          <div className="flex flex-col items-center justify-center gap-2 tablet:flex-row">
            <Typography className="font-normal">{day.slice(0, 3)}</Typography>
          </div>
        </div>
      </div>
    ));
  };

  const sortPosts = (posts: any, sortingBy: string, sort: string) => {
    return posts.sort((a: any, b: any) => {
      if (sortingBy === "name") {
        return sort === "asc"
          ? a.title.localeCompare(b.title)
          : b.title.localeCompare(a.title);
      }
      return 0;
    });
  };

  const filterPosts = (
    posts: any,
    searchTerm: string,
    visiblePlatforms: SocialPlatformsState
  ) => {
    return posts.filter(
      (post: any) =>
        (post.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
          post.name.toLowerCase().includes(searchTerm.toLowerCase())) &&
        visiblePlatforms[post.social]
    );
  };

  const renderColumns = () => {
    const filteredPosts = filterPosts(posts, searchTerm, visiblePlatforms);
    const days = [];
    let day = startOfCalendar;

    while (day <= endOfCalendar) {
      days.push(day);
      day = addDays(day, 1);
    }

    return days.map((currentDay, dayIndex) => {
      const isPast =
        isBefore(currentDay, todaysDate) && !isSameDay(currentDay, todaysDate);
      const isCurrentMonth = isSameMonth(currentDay, currentDate);
      const postsFiltered = sortPosts(
        filteredPosts.filter((post: any) =>
          isSameDay(parseISO(post.date), currentDay)
        ),
        sortingBy,
        sort
      );
      const today = isSameDay(currentDay, todaysDate);

      return (
        <div
          key={dayIndex}
          className={cn(
            "flex flex-col flex-1 gap-2 p-2 bg-white border-r border-accent group border-b min-h-[205px] group relative ",
            { "past-date-column": isPast }
          )}
        >
          <div
            className={cn(
              "flex justify-end mb-2 group-hover:justify-between items-center",
              {
                "justify-end group-hover:justify-end": isPast,
              }
            )}
          >
            {!isPast && (
              <Button
                variant="primary"
                className="hidden w-5 h-5 p-0 rounded-sm group-hover:flex"
              >
                <Plus className="w-[14px] h-[14px]"></Plus>
              </Button>
            )}
            <div
              className={cn("flex items-center justify-center w-6 h-6", {
                "bg-contiyo-primary-purple rounded-full": today,
              })}
            >
              <Typography
                className={cn(
                  "text-sm font-semibold",
                  {
                    "text-contiyo-secondary-text/50 font-semibold":
                      !isCurrentMonth,
                  },
                  { "text-white font-normal": today }
                )}
              >
                {format(currentDay, "d")}
              </Typography>
            </div>
          </div>
          <div className="relative z-10 flex flex-col gap-1">
            {postsFiltered.slice(0, 3).map((post: any, postIndex: any) => (
              <div
                key={`${post.date}-${post.title}-${postIndex}`}
                className={cn(
                  "w-full border p-1 rounded-[8px] break-all flex flex-col gap-2 z-[1]",
                  {
                    "bg-instagram border-instagram-border":
                      post.social === "instagram",
                  },
                  {
                    "bg-linkedin border-linkedin-border":
                      post.social === "linkedin",
                  }
                )}
              >
                <div className="flex items-center gap-2">
                  <div className="relative">
                    <img
                      src={post.profilePicture}
                      alt={post.social}
                      className="relative object-cover object-center w-6 h-6 rounded-full min-w-6 min-h-6"
                    />
                    <div
                      className={cn(
                        "absolute bottom-0 right-0 w-3 h-3 border rounded-full",
                        {
                          "border-instagram": post.social === "instagram",
                        },
                        {
                          "border-linkedin": post.social === "linkedin",
                        }
                      )}
                    >
                      <img
                        src={getSocialMediaImageUrl(post.social)}
                        alt={post.name}
                        className="rounded-full"
                      />
                    </div>
                  </div>
                  {post.title && (
                    <Typography className="line-clamp-1">
                      {post.title}
                    </Typography>
                  )}
                </div>
              </div>
            ))}
            {postsFiltered.length > 3 && (
              <Popover>
                <Button variant="ghost" asChild>
                  <>
                    <PopoverTrigger className="flex items-center justify-center p-1 bg-accent rounded-[8px] h-[34px] w-full z-10">
                      <Typography className="text-sm">
                        +{postsFiltered.length - 3} more
                      </Typography>
                    </PopoverTrigger>
                  </>
                </Button>
                <PopoverContent className="flex flex-col gap-1 p-2 w-52">
                  {postsFiltered.slice(3).map((post: any, postIndex: any) => (
                    <div
                      key={`${post.date}-${post.title}-${postIndex}`}
                      className={cn(
                        "w-full border p-1 rounded-[8px] break-all flex flex-col gap-2 z-[1]",
                        {
                          "bg-instagram border-instagram-border":
                            post.social === "instagram",
                        },
                        {
                          "bg-linkedin border-linkedin-border":
                            post.social === "linkedin",
                        }
                      )}
                    >
                      <div className="flex items-center gap-2">
                        <div className="relative">
                          <img
                            src={post.profilePicture}
                            alt={post.social}
                            className="relative object-cover object-center w-6 h-6 rounded-full min-w-6 min-h-6"
                          />
                          <div
                            className={cn(
                              "absolute bottom-0 right-0 w-3 h-3 border rounded-full",
                              {
                                "border-instagram": post.social === "instagram",
                              },
                              {
                                "border-linkedin": post.social === "linkedin",
                              }
                            )}
                          >
                            <img
                              src={getSocialMediaImageUrl(post.social)}
                              alt={post.name}
                              className="rounded-full"
                            />
                          </div>
                        </div>
                        {post.title && (
                          <Typography className="line-clamp-1">
                            {post.title}
                          </Typography>
                        )}
                      </div>
                    </div>
                  ))}
                </PopoverContent>
              </Popover>
            )}
          </div>
        </div>
      );
    });
  };

  return (
    <div className="grid grid-rows-[auto_1fr_auto] overflow-hidden border border-accent rounded-md relative bg-white">
      <div className="relative grid grid-cols-7 pr-2">
        <div className="absolute top-0 right-0 w-[9px] h-full bg-white border-b-2 border-l-contiyo-light-border"></div>
        {renderDaysHeader()}
      </div>
      <ScrollArea className="relative calendarWeekly">
        <div className="grid w-full h-full grid-cols-7 pr-2 monthlyCalendar">
          {renderColumns()}
        </div>
      </ScrollArea>
    </div>
  );
};
