import { forwardRef, useState } from "react";
import { EyeIcon, EyeOffIcon } from "lucide-react";
import { Button } from "../ui/Button";
import { Input, InputProps } from "../ui/Input";
import { cn } from "../../utils/cn";

const PasswordInput = forwardRef<HTMLInputElement, InputProps>(
  ({ className, children, ...props }, ref) => {
    const [showPassword, setShowPassword] = useState(false);
    const disabled =
      props.value === "" || props.value === undefined || props.disabled;

    return (
      <div className="relative">
        <Input
          type={showPassword ? "text" : "password"}
          className={cn("hide-password-toggle pr-10", className)}
          autoComplete="on"
          ref={ref}
          {...props}
        />
        <Button
          type="button"
          variant="ghost"
          className={
            "absolute top-0 right-0 h-full px-3 py-2 hover:bg-transparent border border-transparent focus-visible:border-contiyo-primary-purple focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-indigo-100"
          }
          onClick={() => setShowPassword((prev) => !prev)}
          disabled={disabled}
        >
          {showPassword && !disabled ? (
            <EyeIcon className="w-[16px] h-[16px]" aria-hidden="true" />
          ) : (
            <EyeOffIcon className="w-[16px] h-[16px]" aria-hidden="true" />
          )}
          <span className="sr-only">
            {showPassword ? "Hide password" : "Show password"}
          </span>
        </Button>
        {children}
        <style>
          {`
            .hide-password-toggle::-ms-reveal,
					  .hide-password-toggle::-ms-clear {
						  visibility: hidden;
						  pointer-events: none;
						  display: none;
					  }
          `}
        </style>
      </div>
    );
  }
);
PasswordInput.displayName = "PasswordInput";

export { PasswordInput };
