import { Plus, Settings, UserRoundPlus } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "../../../components/ui/Avatar";
import { Button } from "../../../components/ui/Button";
import {
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
} from "../../../components/ui/DropdownMenu";
import { ScrollArea } from "../../../components/ui/ScrollArea";
import { cn } from "../../../utils/cn";
import { getInitial } from "../../../utils/getInitials";
import { useSettingsProvider } from "../../settings/SettingsProvider";
import { useSetActiveWorkspace } from "../../workspace/api/workspaceService";
import { useWorkspace } from "../../workspace/WorkspaceProvider";
import { DropdownMenuContentProps } from "@radix-ui/react-dropdown-menu";
// import { onError } from "@apollo/client/link/error";

export default function WorkspaceDropdownMenuContent({
  ...props
}: DropdownMenuContentProps) {
  const { workspaces, activeWorkspace, setActiveWorkspace } = useWorkspace();
  const navigate = useNavigate();
  const { setIsOnboarding } = useWorkspace();
  const { openDialog } = useSettingsProvider();

  const [setActiveWorkspaceMutation] = useSetActiveWorkspace();

  const handleWorkspaceChange = async (workspaceId: any) => {
    try {
      const selectedWorkspace = workspaces.find(
        (workspace) => workspace.PK === workspaceId
      );
      await setActiveWorkspaceMutation({
        variables: {
          workspaceId: selectedWorkspace.PK.split("#")[1],
        },
      });
      setActiveWorkspace(selectedWorkspace);
      navigate(`/${selectedWorkspace.slug}`);
    } catch (error: any) {
      toast.error("Uh oh! Something went wrong", {
        description: error.message || "There was a problem with your request",
      });
    }
  };
  return (
    <DropdownMenuContent className="w-64 p-2" {...props}>
      <span className="uppercase text-[10px] font-semibold text-contiyo-secondary-text pl-[8px]">
        Workspaces
      </span>
      <ScrollArea className="flex flex-col gap-[2px] max-h-[166px] workspaceSwitcherArea">
        <DropdownMenuRadioGroup
          value={activeWorkspace?.PK}
          onValueChange={handleWorkspaceChange}
        >
          {workspaces &&
            workspaces.map((item: any) => (
              <DropdownMenuRadioItem
                key={item.PK}
                className="rounded-radius hover:bg-accent"
                value={item.PK}
              >
                <div className="flex items-center justify-between w-full gap-4">
                  <div className="flex min-w-0 gap-[10px]">
                    <Avatar className="h-[30px] w-[30px] rounded-[6px]">
                      <AvatarImage
                        src={item.image_url}
                        className={cn(
                          "object-cover object-center h-[30px] w-[30px] rounded-radius",
                          {
                            "ring-2 ring-contiyo-primary-purple ring-offset-1":
                              activeWorkspace?.PK === item.PK,
                          }
                        )}
                      />
                      <AvatarFallback
                        className={cn("h-[30px] w-[30px] rounded-[6px]", {
                          "ring-2 ring-contiyo-primary-purple ring-offset-1":
                            activeWorkspace?.PK === item.PK,
                        })}
                      >
                        {getInitial(item.name)}
                      </AvatarFallback>
                    </Avatar>

                    <div className="flex flex-col justify-center w-full min-w-0">
                      <span className="text-[14px] font-bold truncate">
                        {item.name}
                      </span>
                    </div>
                  </div>
                  {/* {activeWorkspace?.PK === item.PK && (
                    <div className="w-5 h-5">
                      <CircleCheck className="w-5 h-5 text-white fill-contiyo-primary-purple" />
                    </div>
                  )} */}
                </div>
              </DropdownMenuRadioItem>
            ))}
        </DropdownMenuRadioGroup>
      </ScrollArea>
      <div className="mt-[10px] mb-[10px]">
        <DropdownMenuItem asChild>
          <Button
            variant="outline"
            className="w-full rounded-[6px] text-[12px] px-2 py-[6px] h-[30px]"
            onClick={() => setIsOnboarding(true)}
          >
            <Plus className="w-4 h-4"></Plus>
            Add workspace
          </Button>
        </DropdownMenuItem>
      </div>
      <DropdownMenuSeparator></DropdownMenuSeparator>
      <DropdownMenuItem>
        <UserRoundPlus className="w-4 h-4 mr-2" />
        <span>Add people</span>
      </DropdownMenuItem>
      <DropdownMenuItem onClick={() => openDialog("workspaceSettings")}>
        <Settings className="w-4 h-4 mr-2" />
        <span>Workspace settings</span>
      </DropdownMenuItem>
    </DropdownMenuContent>
  );
}
