import { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "sonner";
import logoRectangle from "../../assets/logo-rectangle.svg";
import { SocialMediaType } from "../../gql/graphql";
import { useWorkspace } from "../workspace/WorkspaceProvider";
import { useAddSocialMedia } from "./api/socialService";
import { useSocial } from "./SocialProvider";

export default function InstagramVerifyCode() {
  const url = useLocation();
  const searchParams = new URLSearchParams(url.search);
  const navigate = useNavigate();
  const [addSocialMedia] = useAddSocialMedia();
  const { activeWorkspace } = useWorkspace();
  const firstRender = useRef(false);
  const { refetchSocialData } = useSocial();
  useEffect(() => {
    const addSocial = async () => {
      try {
        if (!firstRender.current && activeWorkspace) {
          firstRender.current = true;
          const errorDescription = searchParams.get("error_description");
          const code = searchParams.get("code");
          if (errorDescription) {
            toast.error("Instagram Error", {
              duration: 15000,
              description: errorDescription,
            });
          } else if (code) {
            await addSocialMedia({
              variables: {
                input: {
                  code: code,
                  socialMediaType: SocialMediaType.Instagram,
                  workspaceId: activeWorkspace.PK.split("#")[1],
                },
              },
            });
            refetchSocialData();
            toast.success("Connected Instagram account/s successfully");
          } else {
            toast.error("Uh oh! Something went wrong", {
              description: "There was a problem with your request",
            });
          }
          navigate(`/${activeWorkspace.slug}/socials`);
        }
      } catch (err: any) {
        navigate(`/${activeWorkspace.slug}/socials`);
        toast.error(err.message, { duration: 15000 });
      }
    };
    addSocial();
    // eslint-disable-next-line
  }, [activeWorkspace]);

  return (
    <main>
      <div className="flex items-center justify-center w-screen h-screen">
        <img
          src={logoRectangle}
          alt="Contiyo logo"
          className="w-full animate-pulse-zoom max-w-24"
        ></img>
      </div>
    </main>
  );
}
