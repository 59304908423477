import React, { createContext, ReactNode, useContext, useState } from "react";

interface CreatorContextType {
  isCreatorModalOpen: boolean;
  setIsCreatorModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const CreatorContext = createContext<CreatorContextType | undefined>(undefined);

export const CreatorProvider = ({ children }: { children: ReactNode }) => {
  const [isCreatorModalOpen, setIsCreatorModalOpen] = useState<boolean>(false);
  return (
    <CreatorContext.Provider
      value={{
        isCreatorModalOpen,
        setIsCreatorModalOpen,
      }}
    >
      {children}
    </CreatorContext.Provider>
  );
};

export const useCreator = () => {
  const context = useContext(CreatorContext);
  if (context === undefined) {
    throw new Error("Context error");
  }
  return context;
};
