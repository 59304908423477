import {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";
import { useGetUserLazy, useIsAuthenticatedLazy } from "./api/authService";

interface AuthContextType {
  isAuthenticated: boolean;
  setIsAuthenticated: (value: boolean) => void;
  isLoading: boolean;
  loggedUser: any;
  setLoggedUser: (user: any) => void;
  loggedUserDataLoading: boolean;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [loggedUser, setLoggedUser] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [getIsAuthenticated, { loading: isAuthenticatedLoading }] =
    useIsAuthenticatedLazy();
  const [getUser, { loading: loggedUserDataLoading }] = useGetUserLazy();

  useEffect(() => {
    const checkAuth = async () => {
      const res = await getIsAuthenticated();
      setIsAuthenticated(res.data.isAuthenticated);
      setIsLoading(false);
    };
    checkAuth();
  }, [getIsAuthenticated]);

  useEffect(() => {
    const fetchUser = async () => {
      if (isAuthenticated) {
        const res = await getUser();
        setLoggedUser(res.data?.user);
      }
    };
    fetchUser();
  }, [isAuthenticated, getUser, isAuthenticatedLoading]);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        setIsAuthenticated,
        isLoading,
        loggedUser,
        setLoggedUser,
        loggedUserDataLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within a AuthProvider");
  }
  return context;
};
