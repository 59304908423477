// import { useQuery } from "@apollo/client";
import emojiData from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import {
  Clipboard,
  // Edit,
  // FileText,
  // Image,
  Smile,
  // Trash,
  // Upload,
  // Video,
} from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { toast } from "sonner";
import { Button } from "../../components/ui/Button";
// import {
//   Dialog,
//   DialogContent,
//   DialogDescription,
//   DialogHeader,
//   DialogOverlay,
//   DialogTitle,
//   DialogTrigger,
// } from "../../components/ui/Dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "../../components/ui/DropdownMenu";
// import {
//   FileInput,
//   FileUploader,
//   FileUploaderContent,
//   FileUploaderItem,
// } from "../../components/ui/FileUploader";
import {
  FloatingInput,
  FloatingLabel,
  FloatingTextArea,
} from "../../components/ui/FloatingInput";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../components/ui/Tooltip";
import { Typography } from "../../components/ui/Typography";
import {
  InstagramMediaType,
  LinkedInContentType,
  SocialMediaType,
} from "../../gql/graphql";
import { cn } from "../../utils/cn";
// import { useUploadMedia } from "./api/creatorService";
import { mediaRules } from "./mediaUtils";

interface SocialMediaTabSinglePostProps {
  platform: SocialMediaType;
  mediaType: InstagramMediaType | LinkedInContentType | "BOILERPLATE";
  placeholder?: string;
  enableMediaUpload?: boolean;
  onChange?: (value: string) => void;
  activeMediaTab: any;
}

const SocialMediaTabSinglePost: React.FC<SocialMediaTabSinglePostProps> = ({
  platform,
  mediaType,
  enableMediaUpload = true,
  onChange,
  activeMediaTab,
}) => {
  // const [uploadMedia] = useUploadMedia();
  const [selectionStart, setSelectionStart] = useState(0);
  const [selectionEnd, setSelectionEnd] = useState(0);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  let tooltipTimer: NodeJS.Timeout;

  // const {
  //   data,
  //   loading: loadingSinglePost,
  //   refetch,
  // } = useQuery(GET_SINGLE_POST, {
  //   variables: {
  //     groupId: activeMediaTab?.PK?.split("#")[1],
  //     postType: activeMediaTab?.social_media_type,
  //     postId: activeMediaTab?.SK?.split("#")[1],
  //   },
  //   skip: !activeMediaTab,
  // });

  const onEmojiSelect = (emojiData: any) => {
    const emoji = emojiData.native;
    if (!emoji) return;

    const beforeSelection = inputValue.slice(0, selectionStart);
    const afterSelection = inputValue.slice(selectionEnd);
    const newValue = beforeSelection + emoji + afterSelection;

    const newCursorPosition = beforeSelection.length + emoji.length;

    setInputValue(newValue);
    setTimeout(() => {
      textareaRef.current?.focus();
      textareaRef.current?.setSelectionRange(
        newCursorPosition,
        newCursorPosition
      );
      setSelectionStart(newCursorPosition);
      setSelectionEnd(newCursorPosition);
    }, 0);
    onChange && onChange(newValue);
  };

  const updateSelection = (e: React.SyntheticEvent<HTMLTextAreaElement>) => {
    setSelectionStart(e.currentTarget.selectionStart || 0);
    setSelectionEnd(e.currentTarget.selectionEnd || 0);
  };

  const handleCopy = () => {
    let textToCopy = "";

    if (selectionStart !== selectionEnd) {
      textToCopy = inputValue.substring(selectionStart, selectionEnd);
    } else {
      textToCopy = inputValue;
    }

    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setTooltipOpen(true);
        if (tooltipTimer) clearTimeout(tooltipTimer);

        tooltipTimer = setTimeout(() => {
          setTooltipOpen(false);
        }, 2000);
      })
      .catch((error: any) => {
        toast.error("Error copying to clipboard");
      });
  };

  useEffect(() => {
    return () => {
      if (tooltipTimer) clearTimeout(tooltipTimer);
    };
    // eslint-disable-next-line
  }, []);

  // const [files, setFiles] = useState<any[] | any | null>(null);

  // useEffect(() => {
  //   setInputValue(JSON.stringify(data, null, 2));
  // }, [data]);

  // const uploadMediaFunction = async (files: any) => {
  //   try {
  //     await uploadMedia({
  //       variables: {
  //         input: {
  //           PK: activeMediaTab?.PK,
  //           SK: activeMediaTab?.SK,
  //           media: files,
  //           socialMediaType: activeMediaTab.social_media_type,
  //         },
  //       },
  //     });
  //     await refetch();
  //   } catch (error: any) {
  //     toast.error(error.message);
  //   }
  // };
  // useEffect(() => {
  //   if (platform === SocialMediaType.Instagram && data?.post?.instagram_data) {
  //     switch (mediaType) {
  //       case InstagramMediaType.Image:
  //         setFiles(data.post.instagram_data.image_data || null);
  //         break;
  //       case InstagramMediaType.Carousel:
  //         setFiles(data.post.instagram_data.carousel_data || null);
  //         break;
  //       case InstagramMediaType.Reels:
  //         setFiles(data.post.instagram_data.reels_data || null);
  //         break;
  //       case InstagramMediaType.Stories:
  //         setFiles(data.post.instagram_data.stories_data || null);
  //         break;
  //       default:
  //         setFiles(null);
  //         break;
  //     }
  //   } else if (
  //     platform === SocialMediaType.Linkedin &&
  //     data?.post?.linkedin_data
  //   ) {
  //     switch (mediaType) {
  //       case LinkedInContentType.Image:
  //         setFiles(data.post.linkedin_data.image_content || null);
  //         break;
  //       case LinkedInContentType.Document:
  //         setFiles(data.post.linkedin_data.document_content || null);
  //         break;
  //       case LinkedInContentType.Video:
  //         setFiles(data.post.linkedin_data.video_content || null);
  //         break;
  //       default:
  //         setFiles(null);
  //         break;
  //     }
  //   }
  // }, [platform, mediaType, data]);

  // function isInstagramMediaType(
  //   mediaType: any
  // ): mediaType is InstagramMediaType {
  //   return Object.values(InstagramMediaType).includes(mediaType);
  // }

  // function isLinkedInContentType(
  //   mediaType: any
  // ): mediaType is LinkedInContentType {
  //   return Object.values(LinkedInContentType).includes(mediaType);
  // }
  // interface DropZoneConfig {
  //   accept: { [key: string]: string[] };
  //   maxSize: number;
  //   maxFiles: number;
  //   multiple: boolean;
  //   maxCaptionLength?: number;
  // }
  // const getDropZoneConfig = (): DropZoneConfig => {
  //   if (
  //     platform === SocialMediaType.Instagram &&
  //     isInstagramMediaType(mediaType)
  //   ) {
  //     return {
  //       ...mediaRules[platform][mediaType],
  //       maxSize: maxFileSizeMB * 1024 * 1024,
  //     };
  //   } else if (
  //     platform === SocialMediaType.Linkedin &&
  //     isLinkedInContentType(mediaType)
  //   ) {
  //     return {
  //       ...mediaRules[platform][mediaType],
  //       maxSize: maxFileSizeMB * 1024 * 1024,
  //     };
  //   } else {
  //     return {
  //       accept: {},
  //       maxSize: 0,
  //       maxFiles: 0,
  //       multiple: false,
  //     };
  //   }
  // };

  // const dropZoneConfig = getDropZoneConfig();

  // const FileSvgDraw = () => {
  //   const acceptKeys = Object.keys(dropZoneConfig.accept);
  //   const formattedExtensions = formatExtensions(acceptKeys);
  //   const maxSizeMB = (dropZoneConfig.maxSize / (1024 * 1024)).toFixed(0);
  //   return (
  //     <>
  //       <svg
  //         className="w-8 h-8 mb-3 text-gray-500 dark:text-gray-400"
  //         aria-hidden="true"
  //         xmlns="http://www.w3.org/2000/svg"
  //         fill="none"
  //         viewBox="0 0 20 16"
  //       >
  //         <path
  //           stroke="currentColor"
  //           strokeLinecap="round"
  //           strokeLinejoin="round"
  //           strokeWidth="2"
  //           d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
  //         />
  //       </svg>
  //       <p className="mb-1 text-sm text-gray-500 dark:text-gray-400">
  //         <span className="font-semibold">Click to upload</span>
  //         &nbsp;or drag and drop
  //       </p>
  //       <p className="text-xs text-center text-gray-500 dark:text-gray-400">
  //         {formattedExtensions} (max {maxSizeMB} MB per file)
  //       </p>
  //     </>
  //   );
  // };
  // function formatExtensions(extensions: string[]): string {
  //   const mimeTypeMapping: Record<string, string> = {
  //     "image/jpeg": "JPEG image",
  //     "image/png": "PNG image",
  //     "video/mp4": "MP4 video",
  //     "video/quicktime": "MOV video",
  //     "application/pdf": "PDF document",
  //     "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
  //       "Word document",
  //     "application/vnd.openxmlformats-officedocument.presentationml.presentation":
  //       "PowerPoint presentation",
  //   };

  //   const mappedTypes = extensions.map(
  //     (mimeType) => mimeTypeMapping[mimeType] || `${mimeType} file`
  //   );

  //   if (mappedTypes.length === 0) {
  //     return "";
  //   } else if (mappedTypes.length === 1) {
  //     return mappedTypes[0];
  //   } else {
  //     return (
  //       mappedTypes.slice(0, -1).join(", ") + " or " + mappedTypes.slice(-1)
  //     );
  //   }
  // }
  return (
    <div className="flex flex-col flex-1 space-y-3">
      {/* LINKEDIN VIDEO SETTINGS */}
      {platform === SocialMediaType.Linkedin &&
        mediaType === LinkedInContentType.Video && (
          <div className="relative mt-3">
            <FloatingInput id="video-title" />
            <FloatingLabel htmlFor="video-title">Video title*</FloatingLabel>
          </div>
        )}
      {/* LINKEDIN DOCUMENT SETTINGS */}
      {platform === SocialMediaType.Linkedin &&
        mediaType === LinkedInContentType.Document && (
          <div className="relative mt-3">
            <FloatingInput id="document-title" />
            <FloatingLabel htmlFor="document-title">
              Document title*
            </FloatingLabel>
          </div>
        )}

      <div className={`relative flex flex-col flex-1 mt-3 `}>
        {/* {loadingSinglePost && (
          <div className="absolute z-50 w-full h-full bg-white opacity-50"></div>
        )} */}
        <div className="relative flex flex-col flex-1">
          {/* <Label className="text-[12px] pl-1 ">Content</Label>
          <Textarea
            key={activeMediaTab?.SK}
            ref={textareaRef}
            id="input"
            placeholder="Start writing..."
            className="flex-1 p-3 text-sm rounded-none shadow-none resize-none rounded-t-radius bg-white/80 placeholder:text-contiyo-secondary-text focus-visible:ring-0 focus-visible:border-accent"
            value={inputValue}
            onChange={(e) => {
              setInputValue(e.target.value);
              updateSelection(e);
            }}
            onSelect={updateSelection}
            onKeyUp={updateSelection}
            onMouseUp={updateSelection}
          /> */}
          <div className="relative flex-1">
            <FloatingTextArea
              id="textArea"
              key={activeMediaTab?.SK}
              // ref={textareaRef}
              className="h-full p-3 pb-[52px] text-sm rounded-none shadow-none resize-none rounded-t-radius bg-white/80 placeholder:text-contiyo-secondary-text"
              value={inputValue}
              onChange={(e) => {
                setInputValue(e.target.value);
                updateSelection(e);
              }}
              onSelect={updateSelection}
              onKeyUp={updateSelection}
              onMouseUp={updateSelection}
            />
            <FloatingLabel
              htmlFor="textArea"
              className="peer-placeholder-shown:top-6"
            >
              Content*
            </FloatingLabel>
          </div>
          {platform !== SocialMediaType.Boilerplate &&
            (() => {
              const maxCaptionLength =
                platform === SocialMediaType.Instagram
                  ? mediaRules[SocialMediaType.Instagram][
                      mediaType as InstagramMediaType
                    ]?.maxCaptionLength
                  : platform === SocialMediaType.Linkedin
                  ? mediaRules[SocialMediaType.Linkedin][
                      mediaType as LinkedInContentType
                    ]?.maxCaptionLength
                  : null;
              if (!maxCaptionLength || maxCaptionLength === 0) {
                return null;
              }
              return (
                <div
                  className={cn(
                    "absolute p-1 px-2 border border-accent bottom-3 right-3 rounded-radius",
                    maxCaptionLength && inputValue?.length > maxCaptionLength
                      ? "bg-red-500 text-white"
                      : "bg-white"
                  )}
                >
                  <Typography className="text-[12px]">
                    {inputValue?.length} / {maxCaptionLength}
                  </Typography>
                </div>
              );
            })()}
        </div>
        {/* {files && files?.length > 0 && (
          <div className="flex gap-2 p-3 border-l border-r border-accent">
            {files?.map((file, index) => (
              <div key={index}>
                <div>{JSON.stringify(file, null, 2)}</div>
                {file.type.startsWith("image/") ? (
                  <div className="relative group">
                    <TooltipProvider delayDuration={0} skipDelayDuration={0}>
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <Image className="absolute w-5 h-5 p-1 text-white rounded-full bg-contiyo-text hiden top-1 left-1"></Image>
                        </TooltipTrigger>
                        <TooltipContent
                          side="right"
                          className="p-1"
                          sideOffset={4}
                        >
                          <Typography className="text-[10px]">Image</Typography>
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                    <TooltipProvider delayDuration={0} skipDelayDuration={0}>
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <Trash
                            className="absolute hidden w-5 h-5 p-1 text-white bg-red-500 rounded-full top-1 right-1 group-hover:block hover:cursor-pointer"
                            // onClick={() => handleDeleteMedia(index)}
                          ></Trash>
                        </TooltipTrigger>
                        <TooltipContent
                          side="right"
                          className="p-1"
                          sideOffset={4}
                        >
                          <Typography className="text-[10px]">
                            Remove image
                          </Typography>
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                    <img
                      src={URL.createObjectURL(file)}
                      alt="Selected media"
                      className="object-cover w-20 border rounded-sm aspect-square border-accent"
                    />
                  </div>
                ) : file.type.startsWith("video/") ? (
                  <div className="relative group">
                    <TooltipProvider delayDuration={0} skipDelayDuration={0}>
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <Video className="absolute z-10 w-5 h-5 p-1 text-white rounded-full bg-contiyo-text hiden top-1 left-1"></Video>
                        </TooltipTrigger>
                        <TooltipContent
                          side="right"
                          className="p-1"
                          sideOffset={4}
                        >
                          <Typography className="text-[10px]">Video</Typography>
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                    <TooltipProvider delayDuration={0} skipDelayDuration={0}>
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <Trash
                            className="absolute z-10 hidden w-5 h-5 p-1 text-white bg-red-500 rounded-full top-1 right-1 group-hover:block hover:cursor-pointer"
                            // onClick={() => handleDeleteMedia(index)}
                          ></Trash>
                        </TooltipTrigger>
                        <TooltipContent
                          side="right"
                          className="p-1"
                          sideOffset={4}
                        >
                          <Typography className="text-[10px]">
                            Remove video
                          </Typography>
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                    <video
                      // controls
                      src={URL.createObjectURL(file)}
                      className="z-0 object-cover w-20 border rounded-sm aspect-square border-accent"
                    />
                  </div>
                ) : file.type.startsWith("application/") ? (
                  <div className="relative group">
                    <TooltipProvider delayDuration={0} skipDelayDuration={0}>
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <Trash
                            className="absolute z-10 hidden w-5 h-5 p-1 text-white bg-red-500 rounded-full top-1 right-1 group-hover:block hover:cursor-pointer"
                            // onClick={() => handleDeleteMedia(index)}
                          ></Trash>
                        </TooltipTrigger>
                        <TooltipContent
                          side="right"
                          className="p-1"
                          sideOffset={4}
                        >
                          <Typography className="text-[10px]">
                            Remove document
                          </Typography>
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                    <div className="flex flex-col items-center justify-center w-20 h-20 p-2 overflow-hidden bg-gray-100 border rounded-sm border-accent">
                      <FileText className="size-7"></FileText>
                      <Typography className="text-[10px] text-contiyo-secondary-text truncate w-full">
                        {file.name}
                      </Typography>
                    </div>
                  </div>
                ) : null}
              </div>
            ))}
          </div>
        )} */}

        {/* {files && (
          <div className="flex gap-2 p-3 border-l border-r border-accent">
            {Array.isArray(files) ? (
              files.map((file, index) => (
                <div
                  className="flex items-center flex-1 gap-2 size-20"
                  key={index}
                >
                  <img
                    src={file?.url}
                    alt={`Carousel  ${index + 1}`}
                    className="object-cover w-full h-full rounded"
                  />
                </div>
              ))
            ) : (
              <div className="flex items-center gap-2 size-20">
                {platform === SocialMediaType.Instagram &&
                  mediaType === InstagramMediaType.Image &&
                  files.url && (
                    <img
                      src={files?.url}
                      alt="Test alt"
                      className="object-cover w-full h-full rounded"
                    />
                  )}
                {platform === SocialMediaType.Instagram &&
                  mediaType === InstagramMediaType.Reels &&
                  files.url && (
                    <video
                      // controls
                      src={files.cover_url || files?.url}
                      className="object-cover w-full h-full rounded"
                    />
                  )}
              </div>
            )}
          </div>
        )} */}

        <div className="flex items-center justify-between w-full p-2 px-3 bg-white border border-accent rounded-b-radius">
          <div className="flex gap-1">
            <DropdownMenu modal={false}>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" className="w-8 h-8 p-2">
                  <Smile className="size-4"></Smile>
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent
                className="p-0 h-72"
                avoidCollisions
                align="start"
              >
                <Picker
                  data={emojiData}
                  theme={"light"}
                  perLine={7}
                  previewPosition={"none"}
                  color="#6314F4"
                  maxFrequentRows={1}
                  onEmojiSelect={onEmojiSelect}
                ></Picker>
              </DropdownMenuContent>
            </DropdownMenu>

            {/* {enableMediaUpload && (
              <Dialog>
                <DialogOverlay></DialogOverlay>
                <DialogTrigger asChild>
                  <Button variant="ghost" className="h-8 p-2">
                    {files && files.length > 0 ? (
                      <Edit className="size-4"></Edit>
                    ) : (
                      <Upload className="size-4" />
                    )}
                    <Typography>
                      {files && files.length > 0
                        ? "Edit media"
                        : "Upload media"}
                    </Typography>
                  </Button>
                </DialogTrigger>
                <DialogContent
                  className="sm:max-w-[425px] p-4 gap-4"
                  disableClose
                >
                  <DialogHeader>
                    <DialogTitle>Upload files</DialogTitle>
                    <DialogDescription>
                      Drag and drop your files here or click to browse.
                    </DialogDescription>
                  </DialogHeader>
                  <FileUploader
                    value={files}
                    onValueChange={uploadMediaFunction}
                    dropzoneOptions={dropZoneConfig}
                    className="relative rounded-lg bg-background"
                  >
                    <FileInput className="border-2 border-dashed border-accent">
                      <div className="flex flex-col items-center justify-center w-full px-3 pt-3 pb-4">
                        <FileSvgDraw />
                      </div>
                    </FileInput>
                    <FileUploaderContent>
                      {files &&
                        files.length > 0 &&
                        files.map((file: any, i: any) => (
                          <>
                            <FileUploaderItem key={i} index={i}>
                              {file.type.startsWith("image/") ? (
                                <img
                                  src={URL.createObjectURL(file)}
                                  alt={file.name}
                                  className="object-cover border rounded-sm size-8 border-accent"
                                />
                              ) : file.type.startsWith("video/") ? (
                                <video
                                  src={URL.createObjectURL(file)}
                                  className="object-cover border rounded-sm size-8 border-accent"
                                ></video>
                              ) : file.type.startsWith("application/") ? (
                                <div className="flex items-center justify-center bg-gray-100 border rounded-sm size-8 border-accent">
                                  <Typography className="text-center text-contiyo-secondary-text">
                                    <FileText></FileText>
                                  </Typography>
                                </div>
                              ) : null}
                              <div className="flex flex-col overflow-hidden">
                                <Typography className="truncate">
                                  {file.name}
                                </Typography>
                                <Typography className="text-contiyo-secondary-text">
                                  {file.size > 1024 * 1024
                                    ? `${(file.size / (1024 * 1024)).toFixed(
                                        2
                                      )} MB`
                                    : `${(file.size / 1024).toFixed(2)} KB`}
                                </Typography>
                              </div>
                            </FileUploaderItem>
                          </>
                        ))}
                    </FileUploaderContent>
                  </FileUploader>
                </DialogContent>
              </Dialog>
            )} */}
          </div>
          <TooltipProvider delayDuration={0}>
            <Tooltip open={tooltipOpen}>
              <TooltipTrigger asChild>
                <Button
                  variant="ghost"
                  className="w-8 h-8 p-2"
                  onClick={handleCopy}
                  disabled={textareaRef.current?.value === ""}
                >
                  <Clipboard className="size-4"></Clipboard>
                </Button>
              </TooltipTrigger>
              <TooltipContent side="top" sideOffset={10}>
                <Typography>Copied</Typography>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      </div>
    </div>
  );
};

export default SocialMediaTabSinglePost;
