import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  SignInMutation,
  SignInMutationVariables,
  ResendConfirmationCodeMutation,
  ResendConfirmationCodeMutationVariables,
  ForgotPasswordMutation,
  ForgotPasswordMutationVariables,
  SignUpMutation,
  SignUpMutationVariables,
  ConfirmSignUpMutation,
  ConfirmSignUpMutationVariables,
  ConfirmPasswordMutation,
  ConfirmPasswordMutationVariables,
  SignOutMutation,
  SignOutMutationVariables,
} from "../../../gql/graphql";

const GET_USER_QUERY = gql`
  query User($userId: ID) {
    user(userId: $userId) {
      email
      name
      image_url
    }
  }
`;
const GET_LOGGED_USER = gql`
  query LoggedUser($userId: ID) {
    user(userId: $userId) {
      email
      name
      image_url
    }
  }
`;

const SIGNIN_MUTATION = gql`
  mutation SignIn($input: SignInInput!) {
    signIn(input: $input) {
      message
    }
  }
`;

const SIGNUP_MUTATION = gql`
  mutation SignUp($input: SignUpInput!) {
    signUp(input: $input) {
      id
    }
  }
`;

const CONFIRM_SIGNUP_MUTATION = gql`
  mutation ConfirmSignUp($input: ConfirmSignUpInput!) {
    confirmSignUp(input: $input) {
      message
    }
  }
`;

const RESEND_CONFIRMATION_CODE_MUTATION = gql`
  mutation ResendConfirmationCode($input: ResendConfirmationCodeInput!) {
    resendConfirmationCode(input: $input) {
      message
    }
  }
`;

const FORGOT_PASSWORD_MUTATION = gql`
  mutation ForgotPassword($input: ForgotPasswordInput!) {
    forgotPassword(input: $input) {
      message
    }
  }
`;

const CONFIRM_FORGOT_PASSWORD_MUTATION = gql`
  mutation ConfirmPassword($input: ConfirmPasswordInput!) {
    confirmPassword(input: $input) {
      message
    }
  }
`;

const IS_AUTHENTICATED = gql`
  query isAuthenticated {
    isAuthenticated
  }
`;

const SIGN_OUT = gql`
  mutation SignOut {
    signOut {
      message
    }
  }
`;

export const useGetUserLazy = () => {
  return useLazyQuery(GET_USER_QUERY, {
    fetchPolicy: "no-cache",
    nextFetchPolicy: "no-cache",
  });
};

export const useGetUser = () => {
  return useQuery(GET_LOGGED_USER, {
    fetchPolicy: "no-cache",
    nextFetchPolicy: "no-cache",
  });
};

export const useSignIn = () => {
  return useMutation<SignInMutation, SignInMutationVariables>(SIGNIN_MUTATION);
};
export const useSignUp = () => {
  return useMutation<SignUpMutation, SignUpMutationVariables>(SIGNUP_MUTATION);
};
export const useConfirmSignUp = () => {
  return useMutation<ConfirmSignUpMutation, ConfirmSignUpMutationVariables>(
    CONFIRM_SIGNUP_MUTATION
  );
};
export const useResendConfirmationCode = () => {
  return useMutation<
    ResendConfirmationCodeMutation,
    ResendConfirmationCodeMutationVariables
  >(RESEND_CONFIRMATION_CODE_MUTATION);
};
export const useForgotPassword = () => {
  return useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(
    FORGOT_PASSWORD_MUTATION
  );
};

export const useConfirmForgotPassword = () => {
  return useMutation<ConfirmPasswordMutation, ConfirmPasswordMutationVariables>(
    CONFIRM_FORGOT_PASSWORD_MUTATION
  );
};

export const useIsAuthenticatedLazy = () => {
  return useLazyQuery(IS_AUTHENTICATED);
};

export const useIsAuthenticated = () => {
  return useQuery(IS_AUTHENTICATED);
};

export const useSignOut = () => {
  return useMutation<SignOutMutation, SignOutMutationVariables>(SIGN_OUT);
};
