import { format } from "date-fns";
import {
  EllipsisVertical,
  Info,
  Plus,
  RefreshCcw,
  SearchX,
  Trash,
  UserPlus,
} from "lucide-react";
import { useState } from "react";
import { toast } from "sonner";
import { Button } from "../../components/ui/Button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../components/ui/DropdownMenu";
import { Skeleton } from "../../components/ui/Skeleton";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../components/ui/Tooltip";
import { Typography } from "../../components/ui/Typography";
import { WorkspaceSocialMedia } from "../../gql/graphql";
import { TabTitle } from "../../utils/tabTitle";
import FilterNav from "../filters/FilterNav";
import Layout, { LayoutBody } from "../layout/Layout";
import { useWorkspace } from "../workspace/WorkspaceProvider";
import { useRefetchSocialMedia } from "./api/socialService";
import AvatarWithPicture from "./AvatarWithPicture";
import ConfirmDelete from "./ConfirmDelete";
import { useSocial } from "./SocialProvider";

export default function SocialsPage() {
  TabTitle("Socials");
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const { setShowAccountDialog } = useSocial();
  const [accountToDisconnect, setAccountToDisconnect] = useState();
  const { activeWorkspace } = useWorkspace();
  const [refetchSocialMedia] = useRefetchSocialMedia();
  const { socialData, refetchSocialData, loadingSocial } = useSocial();
  const [socialDataFiltered, setSocialDataFiltered] = useState<
    WorkspaceSocialMedia[]
  >([]);

  const refreshSocialMedia = async (
    socialMediaUserId: string,
    workspaceId: string
  ) => {
    try {
      await refetchSocialMedia({
        variables: {
          input: {
            socialMediaUserId: socialMediaUserId,
            workspaceId: workspaceId,
          },
        },
      });
      refetchSocialData();
      toast.success("Successfully refreshed account");
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  return (
    <>
      <Layout tabTitle="Social Accounts">
        <FilterNav
          data={socialData}
          setFilteredData={setSocialDataFiltered}
          disableProfilesFilter
        ></FilterNav>
        <LayoutBody className="overflow-auto">
          {socialData?.workspace?.socialMedia.length === 0 && (
            <div className="flex flex-col items-center p-4 py-8 mb-4 text-center bg-white border rounded-radius border-accent">
              <div className="flex items-center justify-center mb-4 rounded-full size-36 bg-contiyo-background-accent-purple/10">
                <div className="flex items-center justify-center rounded-full bg-contiyo-background-accent-purple size-28">
                  <UserPlus className="size-14 text-contiyo-primary-purple"></UserPlus>
                </div>
              </div>
              <Typography className="mb-2 uppercase">
                You haven't connected any socials accounts yet
              </Typography>
              <Typography className="font-semibold " variant="h2">
                Connect your social media account and start posting
              </Typography>
              <Button
                className="mt-4 w-fit rounded-radius gap-[6px]"
                variant="primary"
                onClick={() => setShowAccountDialog(true)}
              >
                <UserPlus className="size-4"></UserPlus>
                <Typography variant="p1" className="text-white">
                  Add Account
                </Typography>
              </Button>
            </div>
          )}
          {loadingSocial ? (
            <div className="grid grid-cols-[repeat(auto-fill,minmax(150px,1fr))] gap-4">
              {[...Array(5)].map((_, index) => (
                <div
                  key={index}
                  className="flex flex-col items-center justify-center gap-4 p-5 bg-white border border-accent rounded-radius"
                >
                  <Skeleton className="rounded-full size-16" />
                  <div className="w-full space-y-2">
                    <Skeleton className="w-full h-4" />
                  </div>
                </div>
              ))}
            </div>
          ) : socialDataFiltered.length > 0 ? (
            <div className="grid grid-cols-[repeat(auto-fill,minmax(150px,1fr))] gap-4">
              <Button
                className="relative flex flex-col items-center w-auto h-full gap-3 p-5 border border-transparent shadow-sm bg-contiyo-background-accent-purple text-contiyo-primary-purple rounded-radius"
                variant="secondary"
                onClick={() => setShowAccountDialog(true)}
              >
                <Plus className="size-8"></Plus>
                <Typography variant="p1">Add Account</Typography>
              </Button>
              {socialDataFiltered.map((item: any, index: any) => (
                <div key={index} className="w-full">
                  <div className="relative flex flex-col items-center justify-center h-full gap-3 p-5 py-6 bg-white border shadow-sm rounded-radius border-accent">
                    <DropdownMenu modal={false}>
                      <DropdownMenuTrigger asChild>
                        <Button
                          variant="ghost"
                          className="absolute p-1.5 top-2 right-2 h-auto"
                        >
                          <EllipsisVertical className="size-4"></EllipsisVertical>
                        </Button>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent
                        align="start"
                        side="bottom"
                        avoidCollisions
                      >
                        <DropdownMenuItem
                          className="group gap-[6px]"
                          onSelect={() =>
                            refreshSocialMedia(
                              item.SK.split("#")[1],
                              activeWorkspace.PK.split("#")[1]
                            )
                          }
                        >
                          <RefreshCcw className=" size-4 group-hover:text-blue-500"></RefreshCcw>
                          <Typography
                            variant="p1"
                            className="group-hover:text-blue-500"
                          >
                            Refresh account
                          </Typography>
                          <TooltipProvider>
                            <Tooltip>
                              <TooltipTrigger asChild>
                                <Info className="size-3"></Info>
                              </TooltipTrigger>
                              <TooltipContent
                                className="max-w-44"
                                avoidCollisions
                                side="bottom"
                              >
                                <Typography>
                                  Update your account information. Such as:
                                  name, username, profile picture...
                                </Typography>
                              </TooltipContent>
                            </Tooltip>
                          </TooltipProvider>
                        </DropdownMenuItem>
                        <DropdownMenuItem
                          onSelect={() => {
                            setShowDeleteDialog(true);
                            setAccountToDisconnect(item);
                          }}
                          className="group gap-[6px]"
                        >
                          <Trash className="size-4 group-hover:text-destructive"></Trash>
                          <Typography
                            variant="p1"
                            className="group-hover:text-destructive"
                          >
                            Remove account
                          </Typography>
                        </DropdownMenuItem>

                        <DropdownMenuSeparator />
                        <DropdownMenuLabel>
                          <Typography className="text-center text-[10px] leading-tight">
                            <b>Added at:</b>{" "}
                            {format(
                              new Date(item.added_at * 1000),
                              "d MMM ''yy"
                            )}
                          </Typography>
                        </DropdownMenuLabel>
                      </DropdownMenuContent>
                    </DropdownMenu>
                    <AvatarWithPicture
                      className="size-16"
                      profile={item}
                      socialClassName="size-8 border-2"
                    ></AvatarWithPicture>
                    <Typography className="text-center break-all line-clamp-2">
                      {item.username}
                    </Typography>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            socialData?.workspace?.socialMedia.length > 0 && (
              <div className="flex flex-col items-center p-4 py-8 mb-4 text-center bg-white border rounded-radius border-accent">
                <div className="flex items-center justify-center mb-4 rounded-full size-28 bg-contiyo-background-accent-purple/10">
                  <div className="flex items-center justify-center rounded-full bg-contiyo-background-accent-purple size-20">
                    <SearchX className="size-10 text-contiyo-primary-purple"></SearchX>
                  </div>
                </div>
                <Typography className="font-medium" variant="h2">
                  No results found
                </Typography>
              </div>
            )
          )}
        </LayoutBody>
      </Layout>
      {accountToDisconnect && activeWorkspace && (
        <ConfirmDelete
          account={accountToDisconnect}
          setAccount={setAccountToDisconnect}
          workspace={activeWorkspace}
          refetchData={refetchSocialData}
          open={showDeleteDialog}
          onOpenChange={setShowDeleteDialog}
        />
      )}
    </>
  );
}
