import { Link, Menu, Zap } from "lucide-react";
import { HTMLAttributes, ReactNode } from "react";
import { twMerge } from "tailwind-merge";
import { Button } from "../../components/ui/Button";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../components/ui/Tooltip";
import { Typography } from "../../components/ui/Typography";
import { cn } from "../../utils/cn";
import ProfileSwitcher from "./ProfileSwitcher/ProfileSwitcher";
import Sidebar from "./Sidebar";
import { useSidebar } from "./SidebarContext";
import WorkspaceSwitcher from "./WorkspaceSwitcher/WorkspaceSwitcher";
import { SheetDemo } from "./SidebarDrawer";

export default function Layout({
  children,
  tabTitle,
  className,
}: {
  children: ReactNode;
  tabTitle: string;
  className?: string;
}) {
  const {
    sideBarOpen,
    hideSidebarToggle,
    hideDesktopSidebar,
    showMobileProfileSwitcher,
    showMobileWorkspaceSwitcher,
  } = useSidebar();
  return (
    <div
      className={`h-screen grid relative  ${
        sideBarOpen ? "grid-cols-[280px_1fr]" : "sm:grid-cols-[auto_1fr]"
      }`}
    >
      {!hideDesktopSidebar && (
        <aside
          className={`h-screen bg-white border-r border-r-accent flex flex-col`}
        >
          <WorkspaceSwitcher />
          <Sidebar />
          <ProfileSwitcher />
        </aside>
      )}

      <div
        className={
          "grid max-h-screen h-screen grid-rows-[auto_1fr] overflow-hidden"
        }
      >
        <header
          className={`bg-white border-b border-b-accent p-3 sm:p-4 z-10 ${
            !hideSidebarToggle && "sm:pl-[30px]"
          }  flex justify-between items-center`}
        >
          {showMobileWorkspaceSwitcher && (
            <div className="flex gap-2">
              <SheetDemo>
                <Button
                  variant="outline"
                  className="flex items-center justify-center w-8 h-8 p-0 border border-accent rounded-radius"
                >
                  <Menu className="w-5 h-5 shrink-0" />
                </Button>
              </SheetDemo>
              <WorkspaceSwitcher />
            </div>
          )}
          <Typography
            component="h2"
            className="text-[18px] sm:text-[22px] leading-tight text-center"
          >
            {tabTitle}
          </Typography>
          <div className="flex gap-2 sm:gap-3">
            <TooltipProvider delayDuration={0} skipDelayDuration={0}>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    variant="ghost"
                    className="hidden w-8 h-8 px-2 sm:h-9 lg:w-auto sm:w-9 lg:px-4 sm:flex"
                  >
                    <Link className="w-4 h-4" />
                    <span className="hidden lg:block">Share & Earn</span>
                  </Button>
                </TooltipTrigger>
                <TooltipContent className="block lg:hidden" sideOffset={5}>
                  Share & Earn
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
            <TooltipProvider delayDuration={0} skipDelayDuration={0}>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    variant="primary"
                    className="hidden w-8 h-8 px-2 sm:h-9 lg:w-auto sm:w-9 lg:px-4 sm:flex"
                  >
                    <span className="hidden text-white lg:block">
                      Upgrade to pro
                    </span>
                    <Zap className="w-4 h-4" />
                  </Button>
                </TooltipTrigger>
                <TooltipContent
                  className="block text-white lg:hidden bg-contiyo-primary-purple"
                  sideOffset={5}
                  align="end"
                >
                  Upgrade to pro
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
            {showMobileProfileSwitcher && (
              <div className="flex gap-2">
                <div className="w-8 h-8"></div>
                <ProfileSwitcher />
              </div>
            )}
          </div>
        </header>
        <main
          className={cn("grid grid-rows-[auto_1fr] overflow-hidden", className)}
        >
          {children}
        </main>
      </div>
    </div>
  );
}

interface LayoutBodyProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
}

export function LayoutBody({ children, className, ...rest }: LayoutBodyProps) {
  return (
    <div
      className={twMerge("p-3 sm:p-4 flex flex-grow flex-col ", className)}
      {...rest}
    >
      {children}
    </div>
  );
}
