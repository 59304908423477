import React from "react";
import { Typography } from "../../components/ui/Typography";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogOverlay,
  DialogTitle,
} from "../../components/ui/Dialog";
import * as VisuallyHidden from "@radix-ui/react-visually-hidden";
import { CircleHelp, Settings, Settings2, User, Users } from "lucide-react";
import { useSettingsProvider } from "./SettingsProvider";
import MyAccount from "./my-account/MyAccount";
import MySettings from "./MySettings";
import { Separator } from "../../components/ui/Separator";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/Select";
import { cn } from "../../utils/cn";

interface NavItem {
  key: string;
  label: string;
  content: React.ReactNode;
  icon: React.ReactNode;
}

const SettingsDialog: React.FC<{ open: boolean; handleClose: () => void }> = ({
  open,
  handleClose,
}) => {
  const { settingsNavigation, setSettingsNavigation } = useSettingsProvider();
  // const { loggedUser } = useAuth();

  const [popOverOpen, setPopOverOpen] = React.useState(false);
  const settingsNavItems: NavItem[] = [
    {
      key: "my-account",
      label: "My account",
      content: <MyAccount />,
      icon: <User className="w-4 h-4" />,
    },
    {
      key: "my-settings",
      label: "My settings",
      content: (
        <MySettings popOverOpen={popOverOpen} setPopOverOpen={setPopOverOpen} />
      ),
      icon: <Settings2 className="w-4 h-4" />,
    },
  ];
  const workspaceNavItems: NavItem[] = [
    {
      key: "workspaceMembers",
      label: "Members",
      content: <Typography>Workspace Members</Typography>,
      icon: <Users className="w-4 h-4" />,
    },
    {
      key: "workspaceSettings",
      label: "Settings",
      content: <Typography>Workspace Settings</Typography>,
      icon: <Settings className="w-4 h-4" />,
    },
  ];

  const othersNavItems: NavItem[] = [
    {
      key: "support",
      label: "Support",
      content: <Typography>Workspace Members</Typography>,
      icon: <CircleHelp className="w-4 h-4" />,
    },
  ];
  const allNavItems = [
    ...settingsNavItems,
    ...workspaceNavItems,
    ...othersNavItems,
  ];
  const renderContent = () => {
    const selectedItem = allNavItems.find(
      (item) => item.key === settingsNavigation
    );
    return selectedItem ? (
      selectedItem.content
    ) : (
      <Typography>Select a setting</Typography>
    );
  };
  const renderTitle = () => {
    const selectedItem = allNavItems.find(
      (item) => item.key === settingsNavigation
    );
    return selectedItem ? (
      selectedItem.label
    ) : (
      <Typography>Select a setting</Typography>
    );
  };

  const renderNavigation = (title: string, navBox: any) => {
    return (
      <div>
        <span className="uppercase text-[10px] font-semibold text-contiyo-secondary-text px-3">
          {title}
        </span>
        <ul className="flex flex-col gap-1">
          {navBox.map((item: any) => (
            <li key={item.key}>
              <button
                onClick={() => setSettingsNavigation(item.key)}
                className={`py-2 px-3 text-[14px] rounded-[8px] flex gap-2 items-center leading-tight h-fit w-full text-left  ${
                  settingsNavigation === item.key &&
                  "bg-contiyo-background-accent-purple text-contiyo-primary-purple font-bold "
                } ${settingsNavigation !== item.key && "hover:bg-accent"}`}
              >
                <span className="w-4 h-4">{item.icon}</span>
                <Typography
                  className={`font-medium text-nowrap ${
                    settingsNavigation === item.key &&
                    "text-contiyo-primary-purple font-semibold"
                  }`}
                >
                  {item.label}
                </Typography>
              </button>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <Dialog
      open={open}
      onOpenChange={() => {
        if (!popOverOpen) {
          handleClose();
        }
      }}
    >
      <DialogOverlay />
      <DialogTitle className="hidden">
        <VisuallyHidden.Root>Settings</VisuallyHidden.Root>
      </DialogTitle>
      <DialogContent
        className=" h-5/6 max-h-[600px] w-[calc(100vw_-_20px)] max-w-[800px] border-none overflow-hidden flex mobile:flex-row flex-col gap-0"
        onInteractOutside={(e) => {
          const { originalEvent } = e.detail;
          if (
            originalEvent.target instanceof Element &&
            originalEvent.target.closest(".group.toast")
          ) {
            e.preventDefault();
          }
        }}
        disableClose
      >
        <VisuallyHidden.Root>
          <DialogDescription>Settings</DialogDescription>
        </VisuallyHidden.Root>
        <div className="flex-col justify-between hidden w-56 h-full overflow-y-auto mobile:flex bg-contiyo-background-color">
          <div className="flex flex-col w-full gap-4 p-2 pt-3">
            {renderNavigation("Account", settingsNavItems)}
            {renderNavigation("Workspace", workspaceNavItems)}
            {renderNavigation("Other", othersNavItems)}
          </div>
        </div>
        <div className="relative flex flex-col flex-grow overflow-auto">
          <div className="p-0">
            <div className="mobile:hidden">
              <Select
                onValueChange={(value) => setSettingsNavigation(value)}
                value={settingsNavigation}
              >
                <SelectTrigger className="flex w-full h-12 px-6 text-base font-semibold rounded-none mobile:mb-2 mobile:px-3 mobile:py-2 ">
                  <SelectValue
                    placeholder="Select a setting"
                    defaultChecked={settingsNavigation}
                    className="flex"
                  />
                </SelectTrigger>
                <SelectContent
                  side="bottom"
                  sideOffset={0}
                  className="border-0 border-b-2 rounded-none"
                >
                  {allNavItems.map((item) => (
                    <SelectItem
                      key={item.key}
                      value={item.key}
                      className={cn("pl-5 font-medium flex", {
                        "bg-contiyo-background-accent-purple text-contiyo-primary-purple font-semibold":
                          item.key === settingsNavigation,
                      })}
                      disableIcon
                    >
                      {item.icon}
                      {item.label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          </div>
          <Separator className="block mobile:hidden"></Separator>
          <div className="px-6 py-3 mobile:p-6">
            <div className="hidden mobile:block">
              <Typography
                component="p"
                variant="p1"
                className="hidden mb-2 font-semibold mobile:block"
              >
                {renderTitle()}
              </Typography>
              <Separator></Separator>
            </div>
            {renderContent()}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default SettingsDialog;
