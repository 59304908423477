import { gql } from "@apollo/client";

export const GET_ALL_POSTS = gql`
  query Posts($workspaceId: ID!, $from: Int, $to: Int) {
    workspace(workspaceId: $workspaceId) {
      posts(from: $from, to: $to) {
        PK
        SK
        title
        media_id
        permalink
        workspace_id
        user_id
        created_at
        updated_at
        scheduled_at
        status
        social_media_type
        slug
        account_data {
          account_id
          username
          image_url
        }
        boilerplate_data {
          content
        }
        instagram_data {
          media_type
          caption
          container_id
          image_data {
            url
          }
          reels_data {
            url
            thumb_offset
            cover_url
            share_to_feed
          }
          stories_data {
            stories_type
            url
          }
          carousel_data {
            carousel_type
            url
            user_tags {
              username
              x
              y
            }
            collaborators
          }
          user_tags {
            username
            x
            y
          }
          collaborators
          location {
            id
            name
            city
            country
            latitude
            longitude
            state
            street
            zip
          }
        }
        linkedin_data {
          ad_context {
            dsc_ad_account
            dsc_ad_type
            dsc_name
            dsc_status
            is_dsc
          }
          commentary
          container
          content_type
          image_content {
            alt_text
            url
          }
          video_content {
            title
            captions_url
            thumbnail_url
            url
          }
          document_content {
            title
            url
          }
          content_landing_page
          content_call_to_action_label
          distribution {
            feed_distribution
            target_entities
            third_party_distribution_channels
          }
          is_reshare_disabled
          reshare_context {
            parent
          }
          visibility
        }
      }
    }
  }
`;
