import { X } from "lucide-react";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTrigger,
} from "../../components/ui/Sheet";
import Sidebar from "./Sidebar";
import { useSidebar } from "./SidebarContext";
import { Button } from "../../components/ui/Button";
import BrandLogo from "../../components/BrandLogo";

export function SheetDemo({ children }: any) {
  const { sideBarDrawerOpen, setSidebarDrawerOpen } = useSidebar();
  return (
    <Sheet open={sideBarDrawerOpen} onOpenChange={setSidebarDrawerOpen}>
      <SheetTrigger asChild>{children}</SheetTrigger>
      <SheetContent
        side="left"
        className="w-full max-w-[280px] p-0 flex flex-col gap-0"
        hideCloseIcon
      >
        <SheetHeader className="flex flex-row justify-between px-4 py-3 space-y-0 border-b border-b-transparent">
          <BrandLogo className="w-auto h-8"></BrandLogo>
          <Button
            className="flex items-center justify-center w-8 h-8 p-0 border border-accent rounded-radius"
            variant="outline"
            onClick={() => setSidebarDrawerOpen(false)}
          >
            <X className="w-5 h-5 shrink-0" />
          </Button>
        </SheetHeader>
        <Sidebar />
      </SheetContent>
    </Sheet>
  );
}
