import { FileWithPath, useDropzone } from "react-dropzone";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../components/ui/Form";
import { z } from "zod";
import { useCallback, useEffect, useState } from "react";
import { toast } from "sonner";
import { useUpdateUser } from "../api/accountService";
import { FileWithPreview } from "../../../components/ui/img-cropper/ImageCropperTypes";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "../../../components/ui/Button";
import { Loader2 } from "lucide-react";
import { getInitials } from "../../../utils/getInitials";
import { ImageCropper } from "../../../components/ui/img-cropper/ImageCropper";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "../../../components/ui/Avatar";
import { useAuth } from "../../auth/AuthProvider";
import { useGetUserLazy } from "../../auth/api/authService";
import { Input } from "../../../components/ui/Input";
import { accept } from "../../../components/ui/img-cropper/ImageCropperTypes";

const formSchema = z.object({
  fullName: z.string().min(2, {
    message: "Name must be at least 2 characters.",
  }),
});

export default function UpdateUserForm() {
  const { loggedUser, setLoggedUser } = useAuth();
  const [getUser] = useGetUserLazy();
  const [selectedFile, setSelectedFile] = useState<FileWithPreview | null>(
    null
  );
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [updateUser, { loading: updateUserLoading }] = useUpdateUser();
  const onDrop = useCallback((acceptedFiles: FileWithPath[]) => {
    const file = acceptedFiles[0];
    if (!file) {
      toast.error("Selected image is too large!");
    }

    const fileWithPreview = Object.assign(file, {
      preview: URL.createObjectURL(file),
    });

    setSelectedFile(fileWithPreview);
    setDialogOpen(true);
  }, []);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept,
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      fullName: loggedUser.name,
    },
    reValidateMode: "onChange",
  });

  const { formState, reset } = form;
  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    try {
      await updateUser({
        variables: {
          input: { image: selectedFile?.file, name: values.fullName },
        },
      });
      setSelectedFile(null);

      const res = await getUser();
      setLoggedUser(res.data.user);
      toast.success("Settings has been successfully changed");
    } catch (error: any) {
      toast.error("Uh oh! Something went wrong", {
        description:
          error.message || "There was a problem with changing settings",
      });
      const res = await getUser();
      setLoggedUser(res.data.user);
      setSelectedFile(null);
    }
  };

  const isFormModified = formState.isDirty || selectedFile;

  useEffect(() => {
    form.reset({
      fullName: loggedUser.name,
    });
  }, [loggedUser, form]);
  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col gap-4 mt-4"
      >
        <div className="flex gap-4">
          <div className="ml-1 size-12">
            {selectedFile ? (
              <ImageCropper
                dialogOpen={isDialogOpen}
                setDialogOpen={setDialogOpen}
                selectedFile={selectedFile}
                setSelectedFile={setSelectedFile}
                initials={getInitials(loggedUser.name)}
                className="size-12"
              />
            ) : (
              <Avatar
                {...getRootProps()}
                className="rounded cursor-pointer size-12 ring-offset-2 ring-2 ring-slate-200"
              >
                <input {...getInputProps()} />
                <AvatarImage
                  src={loggedUser && loggedUser.image_url}
                  alt="Contiyo"
                  className="rounded"
                />
                <AvatarFallback className="text-[20px] rounded">
                  {getInitials(loggedUser.name)}
                </AvatarFallback>
              </Avatar>
            )}
          </div>

          <FormField
            control={form.control}
            name="fullName"
            render={({ field }) => (
              <FormItem className="flex flex-col gap-1">
                <FormLabel className="text-[12px] font-medium leading-tight">
                  Full name
                </FormLabel>
                <FormControl>
                  <Input
                    placeholder="Type your full name"
                    {...field}
                    className="h-[30px] text-[14px] placeholder:text-[14px] px-2 py-1"
                  />
                </FormControl>
                <FormMessage className="text-[12px] font-medium leading-tight" />
              </FormItem>
            )}
          />
        </div>

        <div className="flex gap-2">
          <Button
            type="submit"
            variant="primary"
            className="h-8 text-[14px] rounded w-20"
            disabled={!isFormModified}
          >
            {updateUserLoading ? (
              <Loader2 className="w-4 h-4 animate-spin" />
            ) : (
              "Submit"
            )}
          </Button>
          <Button
            type="button"
            className="h-8 text-[14px] rounded w-20"
            variant="outline"
            onClick={() => {
              reset({ fullName: loggedUser.name });
              setSelectedFile(null);
            }}
            disabled={!isFormModified}
          >
            Cancel
          </Button>
        </div>
      </form>
    </Form>
  );
}
