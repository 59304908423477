import { FileWithPath } from "react-dropzone";

export type FileWithPreview = FileWithPath & {
  preview: string;
  file?: File;
};

export const accept = {
  "image/*": [],
};
