"use client";

import React, { type SyntheticEvent } from "react";

import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  type Crop,
  type PixelCrop,
} from "react-image-crop";

import { Avatar, AvatarFallback, AvatarImage } from "../Avatar";
import { Button } from "../Button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogOverlay,
  DialogTitle,
  DialogTrigger,
} from "../Dialog";
import "react-image-crop/dist/ReactCrop.css";
import { CropIcon, Loader2, Trash2Icon, X } from "lucide-react";
import * as VisuallyHidden from "@radix-ui/react-visually-hidden";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../Tooltip";
import { Typography } from "../Typography";
import { FileWithPreview } from "./ImageCropperTypes";
import { cn } from "../../../utils/cn";
import { toast } from "sonner";
import { Separator } from "../Separator";

interface ImageCropperProps {
  dialogOpen: boolean;
  setDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedFile: FileWithPreview | null;
  setSelectedFile: React.Dispatch<React.SetStateAction<FileWithPreview | null>>;
  initials: string;
  className?: string;
}

export function ImageCropper({
  dialogOpen,
  setDialogOpen,
  selectedFile,
  setSelectedFile,
  initials,
  className,
}: ImageCropperProps) {
  function dataURLtoFile(dataurl: any, filename: any) {
    let arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  const aspect = 1;

  const imgRef = React.useRef<HTMLImageElement | null>(null);

  const [crop, setCrop] = React.useState<Crop>();
  const [croppedImageUrl, setCroppedImageUrl] = React.useState<string>("");
  const [croppedImage, setCroppedImage] = React.useState<string>("");

  function onImageLoad(e: SyntheticEvent<HTMLImageElement>) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  function onCropComplete(crop: PixelCrop) {
    if (imgRef.current && crop.width && crop.height) {
      const croppedImageUrl = getCroppedImg(imgRef.current, crop);
      setCroppedImageUrl(croppedImageUrl);
    }
  }

  function getCroppedImg(image: HTMLImageElement, crop: PixelCrop): string {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    canvas.width = crop.width * scaleX;
    canvas.height = crop.height * scaleY;

    const ctx = canvas.getContext("2d");

    if (ctx) {
      ctx.imageSmoothingEnabled = false;

      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width * scaleX,
        crop.height * scaleY
      );
    }

    return canvas.toDataURL("image/png", 1.0);
  }

  async function onCrop() {
    try {
      const croppedFile = dataURLtoFile(croppedImageUrl, selectedFile!.name);
      setSelectedFile({ ...selectedFile!, file: croppedFile });
      setCroppedImage(croppedImageUrl);
      setDialogOpen(false);
    } catch (error) {
      toast.error("Uh oh! Something went wrong", {
        description: "There was a problem with your request",
      });
    }
  }

  return (
    <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
      <DialogOverlay className="bg-black/50" />
      <DialogDescription>
        <VisuallyHidden.Root className="hidden">
          Upload image
        </VisuallyHidden.Root>
      </DialogDescription>
      <DialogTrigger>
        <Avatar
          className={cn(
            "relative rounded cursor-pointer size-24 ring-offset-2 ring-2 ring-slate-200 group",
            className
          )}
        >
          <TooltipProvider delayDuration={0} skipDelayDuration={0}>
            <Tooltip>
              <TooltipTrigger asChild>
                <X
                  className="absolute top-0 right-0 hidden w-5 h-5 p-1 text-white translate-x-1/2 -translate-y-1/2 bg-red-500 rounded-full group-hover:block"
                  onClick={() => {
                    setSelectedFile(null);
                  }}
                ></X>
              </TooltipTrigger>
              <TooltipContent side="right" className="p-1" sideOffset={4}>
                <Typography className="text-[10px]">Remove image</Typography>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>

          <AvatarImage
            src={croppedImage ? croppedImage : selectedFile?.preview}
            className="rounded"
            alt="Contiyo"
          />
          <AvatarFallback className="rounded">{initials}</AvatarFallback>
        </Avatar>
      </DialogTrigger>
      <DialogTitle className="hidden ">
        <VisuallyHidden.Root>Confirm email</VisuallyHidden.Root>
      </DialogTitle>
      <DialogContent className="gap-0 p-0" disableClose>
        <div className="p-4 size-full">
          <ReactCrop
            crop={crop}
            onChange={(_, percentCrop: any) => setCrop(percentCrop)}
            onComplete={(c: any) => onCropComplete(c)}
            className="w-full"
            aspect={1}
          >
            <Avatar className="rounded-none size-full">
              <AvatarImage
                ref={imgRef}
                className="rounded-none size-full aspect-auto"
                alt="Image Cropper Shell"
                src={selectedFile?.preview}
                onLoad={onImageLoad}
              />
              <AvatarFallback className="size-full min-h-[460px] rounded-none">
                <Loader2 className="w-4 h-4 mr-2 animate-spin" />
              </AvatarFallback>
            </Avatar>
          </ReactCrop>
        </div>
        <Separator></Separator>
        <DialogFooter className="flex-row justify-end gap-2 p-4 space-x-0 sm:justify-end">
          <DialogClose asChild>
            <Button
              type="reset"
              className="w-fit"
              variant={"destructive"}
              onClick={() => {
                setSelectedFile(null);
              }}
            >
              Delete
              <Trash2Icon className="size-4" />
            </Button>
          </DialogClose>
          <Button
            type="submit"
            className="w-fit"
            onClick={onCrop}
            variant="primary"
          >
            Crop
            <CropIcon className="size-4" />
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

// Helper function to center the crop
export function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number
): Crop {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 50,
        height: 50,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}
