import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogOverlay,
  DialogTitle,
} from "../../components/ui/Dialog";
import { Typography } from "../../components/ui/Typography";
import { SocialMediaType } from "../../gql/graphql";
import {
  getPlatformColorClasses,
  getSocialMediaImageUrl,
} from "../../utils/socialSwitcher";
import { useSocial } from "./SocialProvider";

export default function AddAccountDialog() {
  const { showAddAccountDialog, setShowAccountDialog } = useSocial();
  const instagramClasses = getPlatformColorClasses(SocialMediaType.Instagram);
  const linkedinClasses = getPlatformColorClasses(SocialMediaType.Linkedin);

  const handleFBLogin = () => {
    const clientId = "992914205601706";
    const redirectUri = `${process.env.REACT_APP_URI}/api/auth/instagram/`;
    const scope =
      "instagram_content_publish,instagram_basic,pages_show_list,public_profile,business_management,instagram_manage_insights,pages_read_engagement,instagram_manage_comments,pages_messaging,pages_manage_metadata,instagram_manage_messages,pages_manage_posts,pages_manage_engagement,pages_read_user_content";
    const responseType = "code";
    const authUrl = `https://www.facebook.com/v20.0/dialog/oauth?client_id=${clientId}&response_type=${responseType}&redirect_uri=${redirectUri}&scope=${scope}`;
    window.location.href = authUrl;
  };

  const handleLinkedinLogin = () => {
    const clientId = "77f0ep3qzv9dfb";
    const redirectUri = `${process.env.REACT_APP_URI}/api/auth/linkedin/`;
    const scope = "email+profile+w_member_social+openid";
    const responseType = "code";
    const authUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=${responseType}&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}`;
    window.location.href = authUrl;
  };

  return (
    <Dialog open={showAddAccountDialog} onOpenChange={setShowAccountDialog}>
      <DialogOverlay></DialogOverlay>
      <DialogContent className="sm:max-w-[425px] p-5 pb-7 gap-4">
        <DialogHeader>
          <DialogTitle>Add account</DialogTitle>
          <DialogDescription>
            Select the social network you want to connect your account to.
          </DialogDescription>
        </DialogHeader>
        <div className="flex flex-col gap-2">
          <button
            className={`${instagramClasses.lightSocialBG} ${instagramClasses.darkBorder} border rounded-radius flex flex-col gap-2 p-3.5 w-full h-fit hover:${instagramClasses.lightSocialBG} data-[state=on]:${instagramClasses.lightSocialBG} data-[state=off]:opacity-50`}
            onClick={handleFBLogin}
          >
            <div className="flex flex-col gap-2">
              <div className="flex items-center gap-2">
                <img
                  src={getSocialMediaImageUrl(SocialMediaType.Instagram)}
                  alt="Instagram logo"
                />
                <Typography variant="h3">Login with Instagram</Typography>
              </div>
              <Typography className="flex flex-col text-[12px] text-left">
                Facebook requires Instagram Business or Creator accounts to be
                connected to a Facebook Page. For Contiyo to work as intended,
                we need to authenticate through Facebook.
              </Typography>
            </div>
          </button>

          <button
            onClick={handleLinkedinLogin}
            className={`${linkedinClasses.lightSocialBG} ${linkedinClasses.darkBorder} border rounded-radius flex gap-2 p-3.5 w-full h-fit justify-start items-center hover:${linkedinClasses.lightSocialBG} data-[state=on]:${linkedinClasses.lightSocialBG} data-[state=off]:opacity-50`}
          >
            <img
              src={getSocialMediaImageUrl(SocialMediaType.Linkedin)}
              alt="Linkedin logo"
            />
            <Typography variant="h3">Login with LinkedIn</Typography>
          </button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
