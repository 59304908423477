import { useQuery } from "@apollo/client";
import {
  Calendar,
  NotepadTextDashed,
  Plus,
  Send,
  Trash2Icon,
  TriangleAlert,
} from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { toast } from "sonner";
import logoRectangle from "../../assets/logo-rectangle.svg";
import { Button } from "../../components/ui/Button";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "../../components/ui/DropdownMenu";
import {
  FloatingInput,
  FloatingLabel,
} from "../../components/ui/FloatingInput";
import { Input } from "../../components/ui/Input";
import { ScrollArea, ScrollBar } from "../../components/ui/ScrollArea";
import { Separator } from "../../components/ui/Separator";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../components/ui/Tooltip";
import { Typography } from "../../components/ui/Typography";
import {
  AccountPosts,
  GetGroupPostsQuery,
  Post,
  SocialMediaType,
  WorkspaceSocialMedia,
} from "../../gql/graphql";
import { cn } from "../../utils/cn";
import { TabTitle } from "../../utils/tabTitle";
import AlertDialogComponent from "../global/AlertDialogComponent";
import Layout, { LayoutBody } from "../layout/Layout";
import { useSidebar } from "../layout/SidebarContext";
import AvatarWithPicture from "../social/AvatarWithPicture";
import { useSocial } from "../social/SocialProvider";
import { useWorkspace } from "../workspace/WorkspaceProvider";
import { aiActions } from "./aiActions";
import { GET_GROUP_POSTS, useDeleteGroupPost } from "./api/creatorService";
import CreatorSidemenu from "./CreatorSidemenu";
import MediaForm from "./MediaForm";
import { sortPostsByMediaTypeOrder } from "./mediaUtils";
import SocialMediaTab from "./SocialMediaTab";
import AIChat from "./aiChat";

export default function CreatorPage() {
  TabTitle("Creator");
  const { groupId } = useParams();
  const { activeWorkspace } = useWorkspace();
  const navigate = useNavigate();
  const { socialData } = useSocial();
  const { setSideBarOpen } = useSidebar();
  const { setShowAccountDialog } = useSocial();

  const firstRun = useRef(true);

  const [activeAI, setActiveAI] = useState(aiActions[0]);
  const [dataLoading, setIsDataLoading] = useState(false);

  // URL Params
  const [searchParams, setSearchParams] = useSearchParams();
  const accountIdParam = searchParams.get("account_id");
  const postIdParam = searchParams.get("post_id");

  const { data, loading: loadingCreatorPost } = useQuery(GET_GROUP_POSTS, {
    variables: {
      groupId: groupId,
      workspaceId: activeWorkspace?.PK?.split("#")[1],
    },
    skip: !activeWorkspace,
  });

  useEffect(() => {
    setSideBarOpen(false);
  }, [setSideBarOpen]);

  useEffect(() => {
    if (data?.workspace?.groupPosts.length === 0 && !loadingCreatorPost) {
      toast.error("Post not found!");
      navigate(`/${activeWorkspace?.slug}/posts`);
    }
  }, [data, loadingCreatorPost, navigate, activeWorkspace]);

  const [searchQuery, setSearchQuery] = useState<string>("");
  const searchInputRef = useRef<HTMLInputElement>(null);

  const boilerplate: WorkspaceSocialMedia = {
    PK: "boilerplate",
    SK: "boilerplate",
    added_at: 0,
    social_media_type: SocialMediaType.Boilerplate,
    username: "boilerplate",
  };

  const [activeTab, setActiveTab] = useState<WorkspaceSocialMedia | null>(null);
  const [activeMediaTab, setActiveMediaTab] = useState<Post | null>(null);
  const [selectedProfiles, setSelectedProfiles] = useState<
    WorkspaceSocialMedia[]
  >([]);
  const [titleValue, setTitleValue] = useState("");

  const [deleteGroupPost] = useDeleteGroupPost({
    update(cache, { data: mutationData }) {
      if (!mutationData?.deleteGroupPosts) return;
      const existingData = cache.readQuery<GetGroupPostsQuery>({
        query: GET_GROUP_POSTS,
        variables: {
          groupId: groupId,
          workspaceId: activeWorkspace?.PK?.split("#")[1],
        },
      });
      if (!existingData) return;

      const selectedAccountId =
        activeMediaTab?.account_data?.account_id ||
        (activeTab?.SK === "boilerplate" ? "boilerplate" : null);
      if (!selectedAccountId) return;

      const updatedGroupPosts = existingData.workspace.groupPosts.filter(
        (group) => group.account_id !== selectedAccountId
      );

      cache.writeQuery({
        query: GET_GROUP_POSTS,
        variables: {
          groupId: groupId,
          workspaceId: activeWorkspace?.PK?.split("#")[1],
        },
        data: {
          workspace: {
            ...existingData.workspace,
            groupPosts: updatedGroupPosts,
          },
        },
      });
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  // Filtering profiles based on search
  const filteredProfiles = socialData?.workspace?.socialMedia?.filter(
    (profile: WorkspaceSocialMedia) =>
      profile.username.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Helper functions
  const getBoilerplatePost = (): Post | undefined => {
    const bp = data?.workspace?.groupPosts.find(
      (g: AccountPosts) => g.account_id === "boilerplate"
    );
    return bp?.posts[0];
  };

  const getProfilePosts = (profile: WorkspaceSocialMedia): Post[] | null => {
    if (!data?.workspace?.groupPosts) return null;
    const account_id =
      profile.SK === "boilerplate" ? "boilerplate" : profile.SK.split("#")[1];
    const gp = data.workspace.groupPosts.find(
      (g: AccountPosts) => g.account_id === account_id
    );
    return gp?.posts || null;
  };

  const getSortedPostsForTab = (
    profile: WorkspaceSocialMedia
  ): Post[] | null => {
    const posts = getProfilePosts(profile);
    if (!posts) return null;
    return sortPostsByMediaTypeOrder(posts, profile.social_media_type);
  };

  // Sync selected profiles from the posts data
  useEffect(() => {
    if (data?.workspace?.groupPosts && socialData?.workspace?.socialMedia) {
      const boilerplatePost = getBoilerplatePost();
      if (boilerplatePost?.title) {
        setTitleValue(boilerplatePost.title);
      }

      // Extract profiles that have posts (excluding boilerplate)
      const otherProfiles = data.workspace.groupPosts.filter(
        (g: AccountPosts) => g.account_id !== "boilerplate"
      );
      const matchedProfiles: WorkspaceSocialMedia[] = otherProfiles.reduce(
        (acc: WorkspaceSocialMedia[], group: AccountPosts) => {
          const id = `MEDIA#${group.account_id}`;
          const found = socialData.workspace.socialMedia.find(
            (p: WorkspaceSocialMedia) => p.SK === id
          );
          if (found) acc.push(found);
          return acc;
        },
        []
      );

      setSelectedProfiles((prev) => {
        const newOnes = matchedProfiles.filter(
          (mp: WorkspaceSocialMedia) => !prev.some((p) => p.SK === mp.SK)
        );
        return [...prev, ...newOnes];
      });
    }
    // eslint-disable-next-line
  }, [data, socialData]);

  const handleProfileFilter = (profile: WorkspaceSocialMedia) => {
    setSelectedProfiles((prev) => {
      if (!prev.some((p) => p.SK === profile.SK)) {
        setActiveTab(profile);
        // const newParams = new URLSearchParams();
        // newParams.set("account_id", profile.SK);
        // setSearchParams(newParams);
        return [...prev, profile];
      } else {
        // const newParams = new URLSearchParams();
        // newParams.set("account_id", profile.SK);
        // setSearchParams(newParams);
        setActiveTab(profile);
        return prev;
      }
    });
  };

  // useEffect(() => {
  //   // Skip if the component is still loading or data is incomplete
  //   if (loadingCreatorPost || !data?.workspace) return;

  //   // Run only when selectedProfiles has data for the first time
  //   if (firstRun.current && selectedProfiles.length > 0) {
  //     firstRun.current = false; // Mark as first run complete

  //     let targetTab = boilerplate;

  //     if (accountIdParam) {
  //       targetTab =
  //         accountIdParam === "boilerplate"
  //           ? boilerplate
  //           : selectedProfiles.find((p) => p.SK === accountIdParam) ||
  //             boilerplate;
  //     }

  //     console.log("targetTab", targetTab);
  //     if (activeTab?.SK !== targetTab?.SK) {
  //       setActiveTab(targetTab);
  //     }

  //     const sortedPosts = getSortedPostsForTab(targetTab);
  //     const targetPost =
  //       postIdParam && sortedPosts
  //         ? sortedPosts.find((p) => p.SK === postIdParam) || sortedPosts[0]
  //         : sortedPosts?.[0] || null;

  //     console.log("targetPost", targetPost);
  //     if (activeMediaTab?.SK !== targetPost?.SK) {
  //       setActiveMediaTab(targetPost);
  //     }
  //   }
  // }, [
  //   loadingCreatorPost,
  //   data,
  //   accountIdParam,
  //   postIdParam,
  //   selectedProfiles, // Dependency here, but guarded by `firstRun.current`
  // ]);
  useEffect(() => {
    if (loadingCreatorPost || !data?.workspace) return;

    if (firstRun.current && selectedProfiles) {
      firstRun.current = false;

      let targetTab = boilerplate;

      if (accountIdParam) {
        targetTab =
          accountIdParam === "boilerplate"
            ? boilerplate
            : selectedProfiles.find((p) => p.SK === accountIdParam) ||
              boilerplate;
      }
      if (activeTab?.SK !== targetTab?.SK) {
        setActiveTab(targetTab);
      }

      const sortedPosts = getSortedPostsForTab(targetTab);
      const targetPost =
        postIdParam && sortedPosts
          ? sortedPosts.find((p) => p.SK === postIdParam) || sortedPosts[0]
          : sortedPosts?.[0] || null;

      if (activeMediaTab?.SK !== targetPost?.SK) {
        setActiveMediaTab(targetPost);
      }
    } // eslint-disable-next-line
  }, [selectedProfiles]);

  useEffect(() => {
    // Skip if the component is still loading or data is incomplete
    if (loadingCreatorPost || !data?.workspace || firstRun.current) return;

    // Run only when selectedProfiles has data for the first time

    let targetTab = boilerplate;

    if (accountIdParam) {
      targetTab =
        accountIdParam === "boilerplate"
          ? boilerplate
          : selectedProfiles.find((p) => p.SK === accountIdParam) ||
            boilerplate;
    }

    if (activeTab?.SK !== targetTab?.SK) {
      setActiveTab(targetTab);
    }

    const sortedPosts = getSortedPostsForTab(targetTab);
    const targetPost =
      postIdParam && sortedPosts
        ? sortedPosts.find((p) => p.SK === postIdParam) || sortedPosts[0]
        : sortedPosts?.[0] || null;

    if (activeMediaTab?.SK !== targetPost?.SK) {
      setActiveMediaTab(targetPost);
    }
    // eslint-disable-next-line
  }, [loadingCreatorPost, data, searchParams]);

  useEffect(() => {
    if (!activeTab) return;

    const newParams = new URLSearchParams();
    newParams.set("account_id", activeTab.SK);
    if (activeMediaTab) {
      newParams.set("post_id", activeMediaTab.SK);
    }
    // const newParamsObject = Object.fromEntries(newParams.entries());
    // const searchparamsObject = Object.fromEntries(searchParams.entries());
    // console.log("newParamsObject", newParamsObject);
    // console.log("searchparamsObject", searchparamsObject);
    if (newParams.toString() !== searchParams.toString()) {
      setSearchParams(newParams);
    }
    // eslint-disable-next-line
  }, [activeTab, activeMediaTab]);

  if (loadingCreatorPost || !activeWorkspace || !selectedProfiles) {
    return (
      <main>
        <div className="flex items-center justify-center w-screen h-screen">
          <img
            src={logoRectangle}
            alt="Contiyo logo"
            className="w-full animate-pulse-zoom max-w-24"
          ></img>
        </div>
      </main>
    );
  }

  const sortedActivePostData = activeTab
    ? getSortedPostsForTab(activeTab)
    : null;

  return (
    <Layout
      tabTitle={"Creator"}
      className={`grid grid-cols-[1fr_300px] grid-rows-1 ${
        dataLoading && "pointer-events-none opacity-50"
      }`}
    >
      <div className="grid grid-rows-[1fr_69px]">
        <LayoutBody className="overflow-auto sm:p-4">
          <div className="relative">
            <FloatingInput
              id="title"
              className="bg-white"
              value={titleValue}
              onChange={(e) => setTitleValue(e.target.value)}
            />
            <FloatingLabel htmlFor="title">Title</FloatingLabel>
          </div>
          <div className="relative flex items-center gap-2 my-2">
            <TooltipProvider delayDuration={0} skipDelayDuration={0}>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    className="h-8"
                    onClick={() => {
                      // const newParams = new URLSearchParams();
                      // newParams.set("account_id", "boilerplate");
                      // setSearchParams(newParams);
                      setActiveTab(boilerplate);
                    }}
                    variant={
                      activeTab?.SK === "boilerplate"
                        ? "creatorActiveTab"
                        : "creatorNotActiveTab"
                    }
                    disabled={dataLoading}
                  >
                    <NotepadTextDashed className="size-4" />
                    <Typography>Boilerplate</Typography>
                  </Button>
                </TooltipTrigger>
                <TooltipContent
                  className="max-w-72"
                  avoidCollisions
                  align="start"
                  side="bottom"
                  sideOffset={8}
                >
                  This is your ideation space. You can draft posts here, but it
                  won't go live.
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
            <Separator orientation="vertical" className="h-7"></Separator>
            {socialData?.workspace?.socialMedia?.length === 0 ? (
              <Button
                className={`${
                  selectedProfiles.length > 0 && "px-2 w-8"
                } relative flex items-center justify-center h-8  border rounded-radius`}
                variant={"outline"}
                onClick={() => {
                  setShowAccountDialog(true);
                }}
              >
                <Plus className="size-4" />
                {selectedProfiles.length === 0 && (
                  <Typography>Add Account</Typography>
                )}
              </Button>
            ) : (
              <DropdownMenu modal={false}>
                <DropdownMenuTrigger asChild disabled={dataLoading}>
                  <Button
                    className={`${
                      selectedProfiles.length > 0 && "px-2 w-8"
                    } relative flex items-center justify-center h-8  border rounded-radius`}
                    variant={"outline"}
                  >
                    <Plus className="size-4" />
                    {selectedProfiles.length === 0 && (
                      <Typography>Add Account</Typography>
                    )}
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent
                  align="start"
                  avoidCollisions
                  className="h-auto"
                >
                  <span className="uppercase text-[10px] font-semibold text-contiyo-secondary-text pl-[8px]">
                    Profiles
                  </span>
                  <div onKeyDown={(e) => e.stopPropagation()}>
                    <Input
                      className="py-1.5 px-2 placeholder:text-[12px] placeholder:leading-tight text-[12px] border-accent mb-1 h-7"
                      placeholder="Type to search..."
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      ref={searchInputRef}
                    />
                  </div>
                  {filteredProfiles?.length > 0 ? (
                    <ScrollArea
                      className={`${filteredProfiles?.length > 2 && "h-24"}`}
                    >
                      {filteredProfiles?.map(
                        (item: WorkspaceSocialMedia, index: number) => {
                          return (
                            <DropdownMenuCheckboxItem
                              key={index}
                              className="flex items-center w-full gap-1.5 text-sm"
                              checked={selectedProfiles?.some(
                                (profile) => profile.SK === item.SK
                              )}
                              onCheckedChange={() => handleProfileFilter(item)}
                            >
                              <AvatarWithPicture
                                profile={item}
                                className="size-5"
                                socialClassName="size-3.5"
                              ></AvatarWithPicture>
                              <Typography className="leading-tight">
                                {item.username}
                              </Typography>
                            </DropdownMenuCheckboxItem>
                          );
                        }
                      )}
                    </ScrollArea>
                  ) : (
                    <Typography className="py-1.5 text-[12px] text-center text-contiyo-secondary-text">
                      No results found
                    </Typography>
                  )}
                </DropdownMenuContent>
              </DropdownMenu>
            )}

            <ScrollArea className="flex">
              <div className="flex gap-2 w-max">
                {selectedProfiles?.map(
                  (profile: WorkspaceSocialMedia, index: number) => (
                    <div key={index} className="flex">
                      <Button
                        className={cn(
                          `flex items-center h-8 gap-1 p-1 pr-2 rounded-radius  w-fit group`,
                          activeTab?.SK === profile.SK && "rounded-r-none pr-1"
                        )}
                        disabled={dataLoading}
                        onClick={() => {
                          // const newParams = new URLSearchParams();
                          // newParams.set("account_id", profile.SK);
                          // setSearchParams(newParams);
                          setActiveTab(profile);
                        }}
                        variant={
                          activeTab?.SK === profile.SK
                            ? "creatorActiveTab"
                            : "creatorNotActiveTab"
                        }
                      >
                        <AvatarWithPicture
                          profile={profile}
                          className="border border-transparent size-6"
                          socialClassName={`size-[14px] border-contiyo-background-accent-purple  ${
                            activeTab?.SK !== profile.SK &&
                            "border-white group-hover:border-accent"
                          }`}
                        ></AvatarWithPicture>
                        <Typography>{profile.username}</Typography>
                      </Button>
                      {activeTab?.SK === profile.SK && (
                        <AlertDialogComponent
                          title="Delete progress?"
                          icon={<TriangleAlert />}
                          description="Any unsaved changes will be lost, and you will need to start over."
                          onSubmit={async (event: any) => {
                            event.preventDefault();
                            try {
                              if (
                                sortedActivePostData &&
                                sortedActivePostData.length > 0
                              ) {
                                await deleteGroupPost({
                                  variables: {
                                    input: {
                                      accountId:
                                        sortedActivePostData[0].account_data
                                          ?.account_id,
                                      workspaceId:
                                        sortedActivePostData[0].workspace_id,
                                      groupId:
                                        sortedActivePostData[0].PK.split(
                                          "#"
                                        )[1],
                                    },
                                  },
                                });
                                setActiveTab(boilerplate);
                                setSelectedProfiles((prev) =>
                                  prev.filter((p) => p.SK !== profile.SK)
                                );
                              } else {
                                setSelectedProfiles((prev) =>
                                  prev.filter((p) => p.SK !== profile.SK)
                                );
                                setActiveTab(boilerplate);
                              }
                            } catch (error: any) {
                              toast.error(error.message);
                            }
                          }}
                          actionButtonVariant="destructive"
                          actionString="Delete"
                        >
                          <Button
                            className="relative w-8 h-full p-0 rounded-l-none group"
                            variant={"creatorActiveTab"}
                            disabled={dataLoading}
                          >
                            <div className="absolute h-1/2 w-[1px] bg-contiyo-primary-purple left-0"></div>
                            <Trash2Icon className="size-3.5"></Trash2Icon>
                          </Button>
                        </AlertDialogComponent>
                      )}
                    </div>
                  )
                )}
              </div>
              <ScrollBar orientation="horizontal" />
            </ScrollArea>
          </div>
          <div className="flex flex-1 gap-3 min-h-[350px]">
            <div className="flex flex-col flex-1">
              <div
                className={`flex p-4 bg-white border rounded-radius border-accent ${
                  sortedActivePostData && sortedActivePostData?.length > 0
                    ? "flex-1"
                    : ""
                }`}
              >
                {sortedActivePostData && sortedActivePostData?.length > 0 ? (
                  <SocialMediaTab
                    key={activeTab?.SK}
                    groupId={groupId}
                    activeWorkspace={activeWorkspace}
                    activePostData={sortedActivePostData}
                    activeMediaTab={activeMediaTab}
                    setActiveMediaTab={setActiveMediaTab}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    handleSelectMediaTab={(post: Post) =>
                      setActiveMediaTab(post)
                    }
                    platform={activeTab?.social_media_type}
                    dataLoading={dataLoading}
                    setIsDataLoading={setIsDataLoading}
                  ></SocialMediaTab>
                ) : (
                  <div className="flex flex-col flex-1">
                    {activeTab?.social_media_type !==
                      SocialMediaType.Boilerplate && (
                      <>
                        <Typography component="h3">Select</Typography>
                        <Typography className="mt-2 text-contiyo-secondary-text">
                          Choose the type of media you want to post on{" "}
                          <span className="capitalize">
                            {activeTab?.social_media_type.toLowerCase()}
                          </span>
                          . Select the appropriate option to tailor your content
                          for the desired{" "}
                          <span className="capitalize">
                            {activeTab?.social_media_type.toLowerCase()}
                          </span>{" "}
                          format.
                        </Typography>
                        {activeTab && (
                          <MediaForm
                            key={activeTab?.SK}
                            activeTab={activeTab}
                            activePostData={sortedActivePostData}
                            post={getBoilerplatePost()}
                            type={activeTab?.social_media_type}
                            dataLoading={dataLoading}
                            setIsDataLoading={setIsDataLoading}
                          ></MediaForm>
                        )}
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
            <AIChat activeAI={activeAI} setActiveAI={setActiveAI}></AIChat>
          </div>
        </LayoutBody>
        <div className="bg-white border-t-[1px] border-accent  flex items-center p-5 justify-between z-10 gap-2">
          <div className="flex gap-2">
            <div className="flex items-center gap-1 text-[14px]">
              <div className="rounded-full size-2 bg-success" />
              <Typography> Last saved at Aug 20, 2024, 10:43AM</Typography>
            </div>
            <AlertDialogComponent
              title="Delete progress?"
              icon={<TriangleAlert />}
              description="Any unsaved changes will be lost, and you will need to start over."
              onSubmit={async (event: any) => {
                event.preventDefault();
                try {
                  const group = data?.workspace?.groupPosts[0];
                  const firstPost = group?.posts[0];
                  if (firstPost) {
                    await deleteGroupPost({
                      variables: {
                        input: {
                          groupId: firstPost.PK.split("#")[1],
                          workspaceId: firstPost.workspace_id,
                        },
                      },
                    });
                  }
                  navigate(`/${activeWorkspace.slug}/posts`);
                } catch (error: any) {
                  toast.error(error.message);
                }
              }}
              actionButtonVariant="destructive"
              actionString="Delete"
            >
              <Button
                variant="destructive"
                disabled={dataLoading}
                className="disabled:opacity-50 disabled:bg-white"
              >
                <Trash2Icon className="size-4" />
                Delete post
              </Button>
            </AlertDialogComponent>
          </div>
          <div className="flex gap-2">
            <Button variant="outline" disabled={dataLoading}>
              Publish Now
              <Send className="size-4" />
            </Button>
            <Button variant="primary" disabled={dataLoading}>
              Schedule
              <Calendar className="size-4" />
            </Button>
          </div>
        </div>
      </div>
      <aside className="flex flex-col bg-white border-l border-accent">
        <CreatorSidemenu
          activeAI={activeAI}
          setActiveAI={setActiveAI}
        ></CreatorSidemenu>
      </aside>
    </Layout>
  );
}
