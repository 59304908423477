import React, { forwardRef } from "react";
import { NavLink } from "react-router-dom";
import { useSidebar } from "./SidebarContext";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../components/ui/Tooltip";
import { useAuth } from "../auth/AuthProvider";
import { useWorkspace } from "../workspace/WorkspaceProvider";
import { Skeleton } from "../../components/ui/Skeleton";

interface NavigationLinkProps {
  children: React.ReactNode;
  to: string;
}

const NavigationLink = forwardRef<HTMLAnchorElement, NavigationLinkProps>(
  ({ to, children }, ref) => {
    const { sideBarOpen, sideBarDrawerOpen, setSidebarDrawerOpen } =
      useSidebar();
    const isSideBarVisible = sideBarOpen || sideBarDrawerOpen;

    const className = ({ isActive }: { isActive: boolean }) =>
      `py-2 px-3 text-[14px] rounded-[8px] flex gap-3 items-center leading-tight h-9 ${
        !isSideBarVisible && "px-[10px]"
      } ${!isActive && "hover:bg-accent"} ${
        isActive &&
        "bg-contiyo-background-accent-purple text-contiyo-primary-purple font-bold  hover:bg-contiyo-background-accent-purple-hover"
      }`;

    return (
      <NavLink
        ref={ref}
        to={to}
        end
        className={className}
        onClick={() => setSidebarDrawerOpen(false)}
      >
        {children}
      </NavLink>
    );
  }
);

interface SectionBoxProps {
  title?: string;
  links: {
    to: string;
    icon: React.ReactNode;
    label: string;
    end?: boolean;
  }[];
}

export const SectionBox: React.FC<SectionBoxProps> = ({ title, links }) => {
  const { sideBarOpen, sideBarDrawerOpen } = useSidebar();
  const { loggedUserDataLoading } = useAuth();
  const {
    activeWorkspaceLoading,
    workspacesLoading,
    infoLoading,
    activeWorkspace,
  } = useWorkspace();
  const isSideBarVisible = sideBarOpen || sideBarDrawerOpen;

  return (
    <div>
      {title &&
        isSideBarVisible &&
        (loggedUserDataLoading ||
        activeWorkspaceLoading ||
        workspacesLoading ||
        infoLoading ||
        activeWorkspace === undefined ? (
          <Skeleton className="flex mb-1 h-[15px] w-1/4">
            <span className="uppercase text-[10px] font-semibold text-contiyo-secondary-text px-3"></span>
          </Skeleton>
        ) : (
          <div className="flex mb-1">
            <span className="uppercase text-[10px] font-semibold text-contiyo-secondary-text px-3">
              {title}
            </span>
          </div>
        ))}

      <div className="flex flex-col gap-[6px]">
        {links.map((link, index) => (
          <TooltipProvider
            key={`${link.to}-${index}`}
            delayDuration={0}
            skipDelayDuration={0}
          >
            <Tooltip>
              {isSideBarVisible ? (
                loggedUserDataLoading ||
                workspacesLoading ||
                activeWorkspaceLoading ||
                infoLoading ||
                activeWorkspace === undefined ? (
                  <Skeleton className="w-full h-9"></Skeleton>
                ) : (
                  <NavigationLink to={link.to}>
                    {link.icon}
                    {link.label}
                  </NavigationLink>
                )
              ) : loggedUserDataLoading ||
                workspacesLoading ||
                activeWorkspaceLoading ||
                infoLoading ||
                activeWorkspace === undefined ? (
                <Skeleton className="w-full h-9"></Skeleton>
              ) : (
                <TooltipTrigger asChild>
                  <div>
                    <NavigationLink to={link.to}>{link.icon}</NavigationLink>
                  </div>
                </TooltipTrigger>
              )}
              <TooltipContent side="right" sideOffset={19}>
                {link.label}
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        ))}
      </div>
    </div>
  );
};
