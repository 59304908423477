import React from "react";
import { ScrollArea } from "../../components/ui/ScrollArea";
import { Button } from "../../components/ui/Button";
import {
  Bell,
  BookOpen,
  CalendarDays,
  Heart,
  Home,
  Images,
  LayoutTemplate,
  LineChart,
  Share2,
  SquarePen,
  // WandSparkles,
} from "lucide-react";
import { SectionBox } from "./Navigation";
import { useSidebar } from "./SidebarContext";
import { Separator } from "../../components/ui/Separator";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../components/ui/Tooltip";
import { useWorkspace } from "../workspace/WorkspaceProvider";
import { useAuth } from "../auth/AuthProvider";
import { Skeleton } from "../../components/ui/Skeleton";
// import CreatePostModal from "../creator/CreatePostModal";
import { useCreator } from "../creator/CreatorContext";

export default function Sidebar() {
  const { sideBarOpen, sideBarDrawerOpen, setSidebarDrawerOpen } = useSidebar();
  const isSideBarVisible = sideBarOpen || sideBarDrawerOpen;
  const { activeWorkspace, activeWorkspaceLoading, workspacesLoading } =
    useWorkspace();
  const { loggedUserDataLoading } = useAuth();
  const { setIsCreatorModalOpen } = useCreator();
  const sections = [
    {
      links: [
        {
          to: `/${activeWorkspace?.slug}`,
          icon: <Home className="w-4 h-4" />,
          label: "Home",
        },
        {
          to: `/${activeWorkspace?.slug}/notifications`,
          icon: <Bell className="w-4 h-4" />,
          label: "Notifications",
        },
        {
          to: `/${activeWorkspace?.slug}/favorites`,
          icon: <Heart className="w-4 h-4" />,
          label: "Favorites",
        },
      ],
    },
    {
      title: "Content",
      links: [
        {
          to: `/${activeWorkspace?.slug}/posts`,
          icon: <Images className="w-4 h-4" />,
          label: "Posts",
        },
        {
          to: `/${activeWorkspace?.slug}/calendar`,
          icon: <CalendarDays className="w-4 h-4" />,
          label: "Calendar",
        },
        {
          to: `/${activeWorkspace?.slug}/my-templates`,
          icon: <LayoutTemplate className="w-4 h-4" />,
          label: "My Templates",
        },
        {
          to: `/${activeWorkspace?.slug}/analytics`,
          icon: <LineChart className="w-4 h-4" />,
          label: "Analytics",
        },
      ],
    },
    {
      title: "Actions",
      links: [
        {
          to: `/${activeWorkspace?.slug}/actions-library`,
          icon: <BookOpen className="w-4 h-4" />,
          label: "Actions Library",
        },
        {
          to: `/${activeWorkspace?.slug}/socials`,
          icon: <Share2 className="w-4 h-4" />,
          label: "Socials",
        },
      ],
    },
  ];

  return (
    <>
      <div className="p-4 pb-3">
        <TooltipProvider delayDuration={0} skipDelayDuration={0}>
          <Tooltip>
            {isSideBarVisible ? (
              loggedUserDataLoading ||
              workspacesLoading ||
              activeWorkspaceLoading ? (
                <Skeleton className="w-full h-9 rounded-radius"></Skeleton>
              ) : (
                <Button
                  variant="primary"
                  className="h-9 font-medium text-[14px] w-full rounded-radius"
                  onClick={() => {
                    setIsCreatorModalOpen(true);
                    setSidebarDrawerOpen(false);
                  }}
                >
                  <SquarePen className="w-4 h-4" />
                  Create post
                </Button>
              )
            ) : loggedUserDataLoading ||
              workspacesLoading ||
              activeWorkspaceLoading ? (
              <Skeleton className="px-[10px] py-[10px] h-9 w-full rounded-radius"></Skeleton>
            ) : (
              <TooltipTrigger asChild>
                <Button
                  variant="primary"
                  className="px-[10px] py-[10px] h-9 font-medium text-[14px] w-full rounded-radius"
                  onClick={() => setIsCreatorModalOpen(true)}
                >
                  <SquarePen className="w-4 h-4" />
                </Button>
              </TooltipTrigger>
            )}
            <TooltipContent side="right" sideOffset={19}>
              <span>Create post</span>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
      <ScrollArea className="flex flex-grow">
        <div className="flex flex-col flex-grow gap-4 p-4 pt-1">
          {sections.map((section, index) => (
            <React.Fragment key={`${index}-${section.title}`}>
              {index !== 0 && <Separator />}
              <SectionBox
                key={`${index}-${section.title}`}
                title={section.title}
                links={section.links}
              />
            </React.Fragment>
          ))}
        </div>
      </ScrollArea>
    </>
  );
}
