import {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";
import { useGetActiveWorkspace, useGetWorkspace } from "./api/workspaceService";
import { useAuth } from "../auth/AuthProvider";

interface WorkspaceContextType {
  workspaces: any[];
  setWorkspaces: any;
  workspacesLoading: any;
  activeWorkspace: any;
  setActiveWorkspace: any;
  activeWorkspaceLoading: any;
  isOnboarding: any;
  setIsOnboarding: any;
  infoLoading: boolean;
  setIsInfoLoading: any;
}

const WorkspaceContext = createContext<WorkspaceContextType | undefined>(
  undefined
);

export const WorkspaceProvider = ({ children }: { children: ReactNode }) => {
  const { isAuthenticated } = useAuth();
  const [workspaces, setWorkspaces] = useState<any[]>([]);
  const [getWorkspaces, { loading: workspacesLoading }] = useGetWorkspace();
  const [getActiveWorkspace, { loading: activeWorkspaceLoading }] =
    useGetActiveWorkspace();
  const [activeWorkspace, setActiveWorkspace] = useState();
  const [infoLoading, setIsInfoLoading] = useState(false);

  //ONBOARDING
  const [isOnboarding, setIsOnboarding] = useState(false);
  useEffect(() => {
    const checkWorkspaces = async () => {
      if (isAuthenticated) {
        const res = await getWorkspaces();
        setWorkspaces(res?.data?.user?.workspaces);
      }
    };
    checkWorkspaces();
  }, [isAuthenticated, getWorkspaces, activeWorkspace]);

  useEffect(() => {
    const checkActiveWorkspace = async () => {
      if (isAuthenticated) {
        const res = await getActiveWorkspace();
        setActiveWorkspace(res?.data?.user?.activeWorkspace);
      }
    };
    checkActiveWorkspace();
  }, [isAuthenticated, getActiveWorkspace]);

  return (
    <WorkspaceContext.Provider
      value={{
        workspaces,
        setWorkspaces,
        workspacesLoading,
        activeWorkspace,
        setActiveWorkspace,
        activeWorkspaceLoading,
        isOnboarding,
        setIsOnboarding,
        infoLoading,
        setIsInfoLoading,
      }}
    >
      {children}
    </WorkspaceContext.Provider>
  );
};

export const useWorkspace = () => {
  const context = useContext(WorkspaceContext);
  if (context === undefined) {
    throw new Error("useWorkspace must be used within a WorkspaceProvider");
  }
  return context;
};
