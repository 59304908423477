import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { useWorkspace } from "../workspace/WorkspaceProvider";
import { useSignOut } from "./api/authService";
import { useAuth } from "./AuthProvider";

const useHandleSignOut = () => {
  const navigate = useNavigate();
  const [signOut] = useSignOut();
  const { setIsAuthenticated, setLoggedUser } = useAuth();
  const { setWorkspaces, setActiveWorkspace } = useWorkspace();

  const handleLogOut = async () => {
    try {
      await signOut();
      setIsAuthenticated(false);
      setWorkspaces([]);
      setLoggedUser(null);
      setActiveWorkspace("null");
      localStorage.removeItem("socialFilters");
      navigate("/login");
    } catch (error: any) {
      toast.error("Uh oh! Something went wrong", {
        description: "There was a problem signing out",
      });
    }
  };

  return handleLogOut;
};

export default useHandleSignOut;
