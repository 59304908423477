import { gql, useMutation } from "@apollo/client";
import {
  AddSocialMediaToWorkspaceMutationMutation,
  AddSocialMediaToWorkspaceMutationMutationVariables,
  RefetchSocialMediaMutation,
  RefetchSocialMediaMutationVariables,
  RemoveSocialMediaFromWorkspaceMutation,
  RemoveSocialMediaFromWorkspaceMutationVariables,
} from "../../../gql/graphql";

const ADD_SOCIAL_MEDIA_MUTATION = gql`
  mutation AddSocialMediaToWorkspaceMutation(
    $input: AddWorkspaceSocialMediaInput!
  ) {
    addSocialMediaToWorkspace(input: $input) {
      PK
    }
  }
`;

const REMOVE_SOCIAL_MEDIA_MUTATION = gql`
  mutation RemoveSocialMediaFromWorkspace(
    $input: RemoveWorkspaceSocialMediaInput!
  ) {
    removeSocialMediaFromWorkspace(input: $input) {
      message
    }
  }
`;

export const GET_SOCIAL_MEDIA_QUERY = gql`
  query getSocialMedia($workspaceId: ID!) {
    workspace(workspaceId: $workspaceId) {
      socialMedia {
        SK
        social_media_type
        image_url
        username
        added_at
      }
    }
  }
`;

export const REFETCH_SOCIAL_MEDIA_MUTATION = gql`
  mutation refetchSocialMedia($input: RefetchSocialMediaInput!) {
    refetchSocialMedia(input: $input) {
      SK
    }
  }
`;

export const useAddSocialMedia = () => {
  return useMutation<
    AddSocialMediaToWorkspaceMutationMutation,
    AddSocialMediaToWorkspaceMutationMutationVariables
  >(ADD_SOCIAL_MEDIA_MUTATION);
};

export const useRemoveSocialMedia = () => {
  return useMutation<
    RemoveSocialMediaFromWorkspaceMutation,
    RemoveSocialMediaFromWorkspaceMutationVariables
  >(REMOVE_SOCIAL_MEDIA_MUTATION);
};
export const useRefetchSocialMedia = () => {
  return useMutation<
    RefetchSocialMediaMutation,
    RefetchSocialMediaMutationVariables
  >(REFETCH_SOCIAL_MEDIA_MUTATION);
};
